import './inbox.css';
import profile from '../../../assets/images/profile.jpg';
import {Nav,Tab} from "react-bootstrap";
import axios from '../../../axios';
import { useSelector } from 'react-redux';
import { useEffect,useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ImUserPlus  ,ImCross} from 'react-icons/im';
import { FiSearch  } from 'react-icons/fi';
import { HiLocationMarker } from "react-icons/hi";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {followId} from '../../../Redux/Actions/userAction';
export function Chats({handleEventKey}) {
  const [chatList, setChatList] = useState([]);
  const [allChat, setAllChat] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeUserId, setActiveUserId] = useState(null);
    useEffect(() => {
        loadChats();
        // const intervalId = setInterval(loadChats, 5000); 
        // return () => {
        //   clearInterval(intervalId); 
        // };
        const reloadLoadChatsHandler = () => {
          loadChats();
        };
        window.addEventListener('reloadLoadChats', reloadLoadChatsHandler);
        return () => {
          window.removeEventListener('reloadLoadChats', reloadLoadChatsHandler);
        };
      }, []);
      const handleMention = (mentionedUser) => {
        const word = mentionedUser;
        const match = word.match(/@\[([^\]]+)\]\((\d+)\)/);
        const name = match ? match[1] : null;
        const id = match ? match[2] : null;
        if (name && id) {  
            if(id != userId) {        
              dispatch(followId(id));
              navigate('/dashboard/viewuser', { state: { userId: id } });
            }
        } 
      };
      const rmvMenFormat=(value)=>{
        var mention = value.split('](');
        var men_name = mention[0].replace('[', '').slice(1);
        return men_name+' ';
      }
      function textWithLinks(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const mentionRegex = /@([^\s]+)/g;
        return text.split(' ').map((word, index) => {
          if (urlRegex.test(word)) {
            return (
              <a key={index} href={word} target="_blank" rel="noopener noreferrer">
                {word}
              </a>
            );
          } else if (mentionRegex.test(word)) {
            const mentions = word.match(mentionRegex);
            if (mentions) {
              return mentions.map((mention, mentionIndex) => {
                const match = mention.match(/@\[([^\]]+)\]\((\d+)\)/);
                const id = match ? match[2] : null;
                    return (
                  <span
                  key={index + mentionIndex}
                  className={id == userId ? "" : "cmt_icon fz-12 c_pointer"}
                  onClick={() => handleMention(mention)}
                    >
                      {rmvMenFormat(mention)}
                  </span>
                );
              });
            }
          }
      
          return word+' ';
        });
      }
      const userId = useSelector(state => state.user.userId);
const loadChats=()=>{
    setAllChat(false);
    axios.get(process.env.REACT_APP_BASEURL +'/user/inboxuserlist')
      .then(response => {
        const filteredChatList = response.data.recordInfo.filter(chat => chat.conversationCount === 1);

        const sortedChatList = filteredChatList.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });

        setChatList(sortedChatList);
        setShowCloseIcon(false);
        
      })
      .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
  }
  const listUser=()=>{
    axios.get(process.env.REACT_APP_BASEURL +'/user/inboxuserlist')
      .then(response => {
        setChatList(response.data.recordInfo);
        setAllChat(true);
        setShowCloseIcon(true);
      })
      .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
  }
  const authToken = useSelector(state => state.user.token);
  const Refresh = () =>{
    if(authToken){
      axios.post(process.env.REACT_APP_BASEURL +'/user/refresh' , {'authToken':authToken})
      .then((response) => {
        if(response.data){
          dispatch({
            type: 'SAVE_USER_DATA',
            payload: {
              token: response.data.data.token ,
              userId: response.data.data.userId,
              userType: response.data.data.userType,
              userName: response.data.data.name,
              imagePath: (response.data.data.imagePath != null ? response.data.data.imagePath: ''),
              newReviewCount: response.data.data.newReviewCount,
              newInboxCount: response.data.data.newInboxCount,
              newMentionCount: response.data.data.newMentionCount
            },
          }); 
        }
        
      }).catch((error) => {
        console.log("refresh Error")
      });
    }
  }
  const updateReadStatus=(chat)=>{
    const params = {
        'senderId': chat.receiverId,
        'receiverId':chat.senderId,
        'isRead':true
    }
    if(chat.isRead == false){
    axios.post(process.env.REACT_APP_BASEURL +'/chatbox/update/readmsg',params)
    .then(response => {
        console.log(response)
      if(response.data.status == "OK"){
        loadChats();
        Refresh();
      }
    })
    .catch(error => {
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
    });
    }
  };
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = () => {
   
        let formData = new FormData();
        formData.append('keyword', searchTerm);
        axios.post(process.env.REACT_APP_BASEURL +'/user/inbox/search',formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      })
        .then(response => {
          setChatList(response.data.data)
        })
        .catch(error => {
            console.log(error);
            
        });
       
}
  
    return (
      <>
        <Nav variant="pills" className="flex-column navStyle">
              <div className='user_ad'>
                    <div class="input-group cht_srch m-2">
                        <input class="form-control py-2 border-right-0 border" placeholder="Search User..." value={searchTerm}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress} id="ch_srch_input"/>
                        <span class="input-group-append">
                          <div class="input-group-text ch_in_icon bg-transparent">
                            <FiSearch className='ch_search_icon' onClick={handleSearch}/></div>
                        </span>
                      </div>
                {showCloseIcon ? ( 
            <ImCross title='Close' className='close-icon c_pointer mr_12 ur_icon' onClick={loadChats} />
          ) : (
            <ImUserPlus title='Add User' className='c_pointer fs_20 mr_12 ur_icon' onClick={listUser} />
          )}
              </div>
              <div className={allChat?'cht_list':''}>
                  {chatList
                  .map((chat, i) => (<Nav.Item className={`userchathov ${activeUserId === chat.id ? 'bl_msg_list' : ''}`}  key={i}>
                    <Nav.Link onClick={(event) => { setActiveUserId(chat.id); handleEventKey(chat.id,chat.name,chat.imagePath,chat.city,chat.state); updateReadStatus(chat)}} >
                    <div className="userchat">
                    <img src={chat.imagePath? chat.imagePath : profile}/>
                    <div className="userchatInfo">
                        <span><p className="fz-15">{chat.name}</p></span>
                        {chat.location != undefined ? <span><p className="fz-12 font-weight-normal">
                          <HiLocationMarker/>&nbsp;{chat.location}</p></span>:''}
                        {chat.message? <p>{(userId === chat.senderId)? 'You: ' : ''}{chat.message && chat.message.length > 10 ?
                        textWithLinks(chat.message.substring(0, 10)).map((word, index) => (
                          <span className="fz-12" key={index}>{word} </span>
                          )).concat('...')
                          : textWithLinks(chat.message).map((word, index) => (
                              <span className="fz-12" key={index}>{word} </span>
                              ))
                          }</p>:''}
                        {chat.isRead == false ? (
                    <span className="unread-text">Unread</span>
                  ) : null}
                    </div>
                </div>
                    </Nav.Link>
                  </Nav.Item>))}
              </div>
            </Nav>
          {chatList.length>0?<hr className="my-3" />:''}
            <ToastContainer/>
            </>
        )
    }
    
    export default Chats;