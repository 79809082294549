
import React ,{ useEffect }from 'react';
import axios from '../axios';
import { useDispatch, useSelector } from 'react-redux';
const Refresh = ({ children }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.token); 
    useEffect(() => {
      if(authToken){
        axios.post(process.env.REACT_APP_BASEURL +'/user/refresh' , {'authToken':authToken})
        .then((response) => {
          if(response.data){
            dispatch({
              type: 'SAVE_USER_DATA',
              payload: {
                token: response.data.data.token ,
                userId: response.data.data.userId,
                userType: response.data.data.userType,
                userName: response.data.data.name,
                imagePath: (response.data.data.imagePath != null ? response.data.data.imagePath: ''),
                newReviewCount: response.data.data.newReviewCount,
                newInboxCount: response.data.data.newInboxCount,
                newMentionCount: response.data.data.newMentionCount
              },
            }); 
          }
          
        }).catch((error) => {
          console.log("refresh Error")
        });
      }
    }, []);
  
    return <>{children}</>;
}
 
export default Refresh;