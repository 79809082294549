import React, { useState,useEffect,useRef} from 'react';
import StarRating from './StarRating';
import { Card,Button,Row,Col,Container} from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../../../axios';
import { useSelector } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import HeaderMenu from '../headerMenu';
import SideBar from "../SideBar";
import BreadCrumb from '../../plugins/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import profile from "../../../assets/images/profile.jpg";
import { MentionsInput, Mention } from 'react-mentions'
import loader from '../../../assets/images/loader.gif';
import mentionInputStyle from '../../../assets/css/MentionStyle';
import { IoArrowBack } from "react-icons/io5";

const Review = () => {
  const navigate = useNavigate();
  const [config, setConfig] = useState([])
  const [rating, setRating] = useState(0);
  const [selectedRole, setSelectedRole] = useState('');
  const [knownSinceDate, setKnownSinceDate] = useState(null);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [imagePath, setImagePath] = useState([]);
  const [comment, setComment] = useState('');
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [users,setUsers] = useState([]); 
  const [roleList, setRoleList] = useState('');
  const [knownSinceError, setKnownSinceError] = useState('');
  const [selectedRoleError, setSelectedRoleError] = useState('');
  const [commentError, setCommentError] = useState('');
  const [reviewQuestions, setReviewQuestions] = useState([]);
  const [ratingError, setRatingError] = useState('');
  const [roleRating, setRoleRating] = useState(0);
  const [quesRating, setQuesRating] = useState([]);
  const [userList, setUserList] = useState([]);
  const reviewedById = useSelector(state => state.user.userId);
  const location = useLocation();
  const { name, userId,img} = location.state;
  const [submit_dis, setSubmit_dis] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadRoleList();
    loaduserLists();
      fetch('/config.json')
          .then((res) => res.json())
          .then((res) => {setConfig(res);
          });
}, []);
const loaduserLists = () =>{
  axios.get(process.env.REACT_APP_BASEURL +'/lookup/users')
  .then(response => {
      const usersArr = response.data.recordInfo.filter((row)=>{
        return row.id != reviewedById;
      })
      const users = usersArr.map(function(row){
        return {display:row.name,id:row.id}
      })
       setUserList(users)
  })
  .catch(error => {
      console.log(error);
  });  
}
const loadRoleList = () =>{
        axios.get(process.env.REACT_APP_BASEURL +'/lookup/userrole')
        .then(response => {
            setRoleList(response.data.recordInfo);
        })
        .catch(error => {
            console.log(error);
        });  
}

  const handleReviewSubmit = () => {
    setKnownSinceError('');
    setSelectedRoleError('');
    setRatingError('');
    setCommentError('');

    let isValid = true;
    if (!knownSinceDate) {
      setKnownSinceError('Known since is required.');
      isValid = false;
    }
    if (!selectedRole) {
      setSelectedRoleError('Role is required.');
      isValid = false;
    }
    if (!comment.trim()) {
      setCommentError('Comment is required.');
      isValid = false;
    }
    if (roleRating == 0 && rating == 0) {
      setRatingError('Rating is required.');
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    const params = {
      "userId":userId,
      "knownSince":knownSinceDate,
      "roleId":selectedRole,
      "rating":(roleRating?roleRating:rating),
      "comment":comment,
      "fileName":"",
      "fileType":"",
      "filePath":"",
      "imageName":"",
      "imagePath": "",
      "reviewedBy":reviewedById,
      "url":"",
      "isDeleted":0,
      "reviewQuestions":quesRating,
      "mentionUserIds":mentionuserIds
      }
      setLoading(true)
      console.log("params",params);
        if(params){
            axios.post(process.env.REACT_APP_BASEURL +'/review/add',params)
            .then(response => {
              console.log("response",response)
              if(response.data.status == "OK"){
              uploadImage(response.data.id);
              uploadFile(response.data.id);
              toast.success(response.data.message, {
                theme: 'colored',
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                  setTimeout(() => {
                    navigate('/dashboard/reviews');
                  }, 0);
                },
              });
              setRating(0);
              setSelectedRole('');
              setKnownSinceDate(null);
              setFiles(null);
              setImages(null);
              setComment('');
              setReviewQuestions([]);
              setRoleRating(0);
              setQuesRating([]);
              setmentionuserIds([]);
              setLoading(false)
            }
            })
            .catch(error => {
                setLoading(false)
                toast.error('Error occurred', {
                  theme: 'colored',
                  position: 'top-right',
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
            });
        }
   
  };

  const handleReviewClose = () => {

    setRating(0);
    setSelectedRole('');
    setKnownSinceDate(null);
    setFiles(null);
    setImages(null);
    setComment('');
    setReviewQuestions([]);
    setRoleRating(0);
    navigate(-1);

  };
  const changeCmtText = (value)=>{
    var words = value.split(/\s/);
    var contents = words.map(function(word, i) {
        var separator = i < (words.length - 1) ? ' ' : '';
        return word + separator;
    });
  if(contents.length!=0){
    var filtercontents = contents.filter(name => name.startsWith('@'))
    var missing = filtercontents.map(function(o1){
      if(o1 != '@' && o1.includes('](')){
          var mention = o1.split('](');
          var men_id = mention[1].replace(')', '');
          return +men_id;
      }
    })
    var uniqueItems = [...new Set(missing)];
    var men_user_ids = uniqueItems.map(x=>{
      return {'id':x}
    })
    setmentionuserIds(men_user_ids)
    }
    let cmtText = contents.reduce((accumulator, item) => accumulator += item, '');
    if(cmtText.length>288){
      var limit_char = cmtText.substring(0,288);
      setComment(limit_char);}
    else
      setComment(cmtText);
  }
  function filteredcontent(content) {
  return content.find(value => {
    return value.startsWith('@');
  });
}
  const handleStarRatingChange = (selectedRating) => {
    setRating(selectedRating);
    if (selectedRating) {
      setRatingError('');
    }
  };
  function calculateAverageRating(data) {
    let sumOfRatings = 0;
    let countOfRatings = 0;

    for (const item of data) {
        if (item.hasOwnProperty("rating")) {
            sumOfRatings += item.rating;
            countOfRatings++;
        }
    }

    if (countOfRatings === 0) {
        return 0;
    }

    return sumOfRatings / countOfRatings;
}
const handleRoleStarRatingChange = (selectedRating, roleId, id, idx) => {
  const updatedReviewQuestions = reviewQuestions.map((obj, index) => {
    if (index === idx) {
      return { ...obj, rating: selectedRating };
    }
    return obj;
  });

  setReviewQuestions(updatedReviewQuestions);

  const result = updatedReviewQuestions.map((obj) => ({
    roleId: roleId,
    questionId: obj.id, 
    rating: obj.rating || 0, 
  }));
    setQuesRating(result);
    const averageRating = calculateAverageRating(result);
    setRoleRating(averageRating);
    if(averageRating ){
      setRatingError('');
    }
  }
  const uploadFile = (id) => {
    if(files.length>0){
    let formData = new FormData();
    for (const row of Object.keys(files)) {
      formData.append('files', files[row]);
    }
    formData.append('id', id);
    formData.append('type', 'file');
    axios.post(process.env.REACT_APP_BASEURL +'/review/upload/file',
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(response =>{
          if(response.data.status=="OK"){
            setLoading(false)
          }
          else if(response.data.status=="EXPECTATION_FAILED"){
            setLoading(false)
          }
        })
        .catch(function(error) {
          console.log(error)
        });
      }
  };
  const handleFileChange = (event) => {
    let allowedfiletypes=['pdf','doc','docx','mp4']
    let fileResult = document.getElementById("file-result");
    if(event.target.files.length>0){
        const fileSize = event.target.files[0].size;
        const fileMb = fileSize / 1024;
      if (fileMb >= 5120) {
        fileResult.innerHTML = "Please select a file less than 5MB.";
        setSubmit_dis(true);
      }
      else{
      fileResult.innerHTML = '';
      let fileprev = [];
      let file = [];
			let ext = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1);
      const isFound = allowedfiletypes.some(element => {
      if (element === ext) { return true;}return false;
      });
			if(isFound){
        fileprev.push(...filePreviews,{name:event.target.files[0].name});
        file.push(...files,event.target.files[0]);
        setFiles(file);
        setFilePreviews(fileprev);
      }
      else{
				toast.error(config.FileFormatErr, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        });
				fileprev=[];
        file = [];
			}
    }
    }
  };
  const handleImageChange = (event) => {
    let allowedfiletypes=['jpg','svg','jpeg','png','bmp','gif','jfif']
    if(event.target.files.length>0){
      let imgprev = [];
      let img = [];
			let ext = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1);
      const isnotFound = allowedfiletypes.some(element => {
      if (element === ext) { return true;}return false;
      });
			if(isnotFound){
        imgprev.push(...imagePreviews,{name:event.target.files[0].name,url:URL.createObjectURL(event.target.files[0])});
        img.push(...images,event.target.files[0]);
        setImages(img);
        setImagePreviews(imgprev);
      }
      else{
				toast.error(config.FileFormatErr, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        });
				imgprev=[];
        img = [];
			}
    }
  }
  const rmvImage = (index)=>{
    const removIdx = imagePreviews.filter(function (imgPrev,idx) {
      if(idx != index){
        return imgPrev;
      }
    });
    setImagePreviews(removIdx)
    let rmvImage = [];
    for (let i = 0; i < images.length; i++) {
      if(i!=index){
        rmvImage.push(images[i])
      }
    }
    setImages(rmvImage);
  }
  const rmvFile = (index)=>{
    const removIdx = filePreviews.filter(function (filePrev,idx) {
      if(idx != index){
        return filePrev;
      }
    });
    setFilePreviews(removIdx)
    let rmvFile = [];
    for (let i = 0; i < files.length; i++) {
      if(i!=index){
        rmvFile.push(files[i])
      }
    }
    setFiles(rmvFile);
  }
 const uploadImage = (id)=>{
  if(images.length>0){
      let formData = new FormData();

      for (const row of Object.keys(images)) {
          formData.append('files', images[row]);
      }
    formData.append('id', id);
    formData.append('type', 'image');
    axios.post(process.env.REACT_APP_BASEURL +'/review/upload/file',
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then(response =>{
              if(response.data.status=="OK"){
                setLoading(false)
              }
              else if(response.data.status=="EXPECTATION_FAILED"){
                setLoading(false)
              }
            })
            .catch(function(error) {
              console.log(error)
            });
          }
      };
  const handleRoleChange = (event) => {
    setRoleRating(0);
    setRating(0);
    const selectedRole = event.target.value;
    setSelectedRole(selectedRole);
    loadQuestions(selectedRole);
    if (selectedRole) {
      setSelectedRoleError('');
    }
  };
  const handleCommentChange = (event) => {
    const Comment = event.target.value;
    setComment(Comment);
    if (Comment) {
      setCommentError('');
    }
  };

  const handleKnownSinceChange = (data) => {
    setKnownSinceDate(data);
    if (data) {
      setKnownSinceError('');
    }
  };
  const loadQuestions = (id)=>{
    if(id){
      axios.post(process.env.REACT_APP_BASEURL +'/review/questions/byroleid',{'roleId':id})
      .then(response => {
        const reviewQues = response.data.recordInfo.map(object => {
          return {...object, rating: ''};
        });
        setReviewQuestions(reviewQues)
      })
      .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
  }
  }
  const GoBack = () =>{
    navigate(-1);
  }

  return (
    <>
    <HeaderMenu/>
        <div className="">
        <SideBar/>
        <div className="main-content ml_200">
        <BreadCrumb/>
        {loading ?
              <div className="pro-loader-center"><img className="loader-image" src={loader} alt="Loading..." /></div>:''}
              <Container className="mb-4" fluid>
                <Row>
                  <Col >
                    <Card className="bg-secondary profile_card">
                      {/* <Card.Header className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="12">
                            <h3 className="mb-0 text-center fz-22 alert-link">Profile Information</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                          </Col>
                        </Row>
                      </Card.Header> */}
                      <Card.Body>
                        <Row><div className='d-flex justify-content-end'>
                        <Button className="bck_btn fs_14px" id="bck" onClick={GoBack}><IoArrowBack />Back</Button>
                        </div>
                      <Col lg="4" md="4" sm="12">
                          <Row className="justify-content-center">
                          <div className="card-profile-image">
                          <img className='imagePreview w_h_120' src={img ? img : profile}/>
                            {/* <div className="imagePreview w_h_120" style={{ backgroundImage: 'url(' + `${img ? img : profile}` + ')' }}></div> */}
                            </div>
                            </Row>
                            <Row className="justify-content-center"> 
                                <div className="text-center fz-22 alert-link">{name}</div>
                                {/* <div className="text-center fz-12 font-weight-normal">followers:&nbsp;{}</div> */}
                            </Row>
                          </Col>
                         <div className='rec_div'>
                          
                          </div>
                          <Col lg="7" md="7" sm="12">
                      <div className="container review-container">
                        <div className="row d-flex">
                          <div className="col-5 col-md-5 col-lg-5 reviewPage">Known since</div>
                          <div className="col-7 col-md-6 col-lg-7 reviewPage">
                          <DatePicker
                            selected={knownSinceDate}
                            onChange={handleKnownSinceChange} 
                            className="form-control fz-12"
                            placeholderText="Known since"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker 
                            maxDate={new Date()}
                          />
                          {knownSinceError && <div className="text-danger fz-12">{knownSinceError}</div>}
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-5 col-md-5 col-lg-5 reviewPage">Role</div>
                          <div className="col-7 col-md-6 col-lg-7 reviewPage">
                            <select
                              className="form-control roleClr fz-12"
                              value={selectedRole}
                              onChange={handleRoleChange}
                            >
                              <option value="">Choose an option..</option>
                              {roleList&& roleList.map((role) =>(<option value={role.id}>{role.name}</option>))}
                            </select>
                            {selectedRoleError && <div className="text-danger fz-12">{selectedRoleError}</div>}
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-5 col-md-5 col-lg-5 reviewPage"></div>
                          <div className="col-7 col-md-6 col-lg-7 reviewPage">
                          {reviewQuestions&& reviewQuestions.map((data,idx) =>(<div className='row' key={idx}>
                            <div className="col-6 col-md-6 col-lg-6 ques"><p>{data.question}</p></div>
                            <div className="col-6 col-md-4 col-lg-6">
                                <StarRating rating={data.rating} id ={data.id} idx={idx} roleId={data.userRoleId} isReview={true} onRatingChange={handleRoleStarRatingChange}/>
                                
                                </div>
                                </div>
                                ))}
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-5 col-md-5 col-lg-5 reviewPage">Comment</div>
                          <div className="col-7 col-md-6 col-lg-7 reviewPage">
                          <MentionsInput style={mentionInputStyle} className="Comment_sty form-control" maxLength={288} 
                            onChange={(e) => {changeCmtText(e.target.value)}} value={comment}
                            placeholder='Please add information supporting your review…'>
                              <Mention style={{backgroundColor: "#efebe3"}}
                                  trigger="@"
                                  data={userList}
                              />
                            </MentionsInput>
                            <span className='limt_cmt'>Limit : Max. 288 characters</span>
                          {commentError && <div className="text-danger fz-12">{commentError}</div>}
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-5 col-md-5 col-lg-5 reviewPage">Review</div>
                          <div className="col-7 col-md-6 col-lg-7 reviewPage">
                            <StarRating rating={(roleRating!=0 ?roleRating:rating)} onRatingChange={handleStarRatingChange}  disabled={reviewQuestions.length !=0} />
                            {ratingError && <div className="text-danger fz-12">{ratingError}</div>} 
                          </div>
                        </div>
                        <div className="row d-flex">
                        <div className="col-5 col-md-5 col-lg-5 reviewPage"></div>
                        <div className="col-7 col-md-6 col-lg-7 reviewPage">
                          <div className="row upload-container">
                            <div className="col-12 col-md-6 col-lg-6">
                                <button className="upload_click" >Click to upload video/doc</button>
                                <input type="file" accept=".rtf,.doc,.docx,.pdf,.DOCX,video/mp4,video/x-m4v,video/*" className="file_click_up" onChange={handleFileChange}/>
                            </div><div className="col-12 col-md-6 col-lg-6">
                                <button className="upload_click marLef">Click to upload image</button>
                                <input type="file"  accept="image/*" className="file_click_up" onChange={handleImageChange}/>
                                
                          </div>
                          <div className="row"><p id="file-result"></p></div>
                        </div>
                      </div>
                      </div>
                      <div className="row d-flex">
                        <div className="col-5 col-md-5 col-lg-5 reviewPage"></div>
                        <div className="col-7 col-md-6 col-lg-7 reviewPage">
                        <div className="row upload-container">
                            <div className="col-12 col-md-6 col-lg-6">
                            {filePreviews.length>0 ? (
                                <Card className='file_pad'>
                                {filePreviews.map((file, i) => {
                                  return (
                                    
                                    <div className='row m-1'>
                                    <div className='col-10 preview_ttle'>{file.name &&<a href={file.url} target='_blank' title={file.name} className="fz-10">{file.name}</a>}</div>
                                    <div className='col-2 p-1'> 
                                      <span className="pl-1 c_pointer"><AiOutlineClose className="drop_header" onClick={()=>{rmvFile(i)}}/></span>
                                    </div>
                                  </div>
                                  
                                  );
                                })}
                                </Card>):''}
                              </div>
                              <div className="col-12 col-md-6 col-lg-6">
                              {imagePreviews.length>0 ? (
                                <Card>
                                {imagePreviews.map((img, i) => {
                                  return (
                                    
                                      <Row className="m-1">
                                    <div className='col-3 p-0'><img className="rev_preview" src={img.url} alt={"image-" + i} key={i} /></div>
                                    <div className='col-9 p-0'><Row>
                                    <div className='col-9 preview_ttle'>{img.name &&<a href={img.url} target='_blank' title={img.name} className="fz-10">{img.name}</a>}</div>
                                    <div className='col-2 p-1'><span className="pl-1 c_pointer"><AiOutlineClose className="drop_header" onClick={()=>{rmvImage(i)}}/></span></div></Row>
                                  </div>
                                  </Row>
                                  );
                                })}
                            </Card>):''}
                              </div>
                              </div>
                          </div></div>
                          <div className="row d-flex">
                        <div className="col-5 col-md-5 col-lg-5 reviewPage"></div>
                        <div className="col-7 col-md-6 col-lg-7 reviewPage">
                        <div className='row review_center mt-2'>
                          <div className='col-md-6 col-lg-6  col-lg-12" reviewPage'> 
                            <Button className="rev_close w42_h150" onClick={handleReviewClose}>Cancel</Button>
                          </div>
                          <div className='col-md-6 col-lg-6  col-lg-12" reviewPage'>
                          <Button className="u_review_btn w42_h150" id="file-submit" disabled={submit_dis} onClick={handleReviewSubmit}>Submit</Button>
                          </div>
                        </div>
                        </div>
                          </div></div>
                      </Col>
                      </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
      </div>
      </div>
      <ToastContainer/> 
    </>
  );
};
export default Review;
