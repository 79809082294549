import React, { useRef, useState, useEffect, forwardRef } from "react";
import profile from '../../assets/images/profile.jpg';
import StarRating from '../Dashboard/DashboardTabs/StarRating';
import { HiLocationMarker } from "react-icons/hi";
import RatingBar from '../plugins/RatingBar';
import { Card,Col, Row} from "react-bootstrap";
import logo from '../../assets/images/tiip.png';
import usrImg from '../../assets/images/user_Img.jpg';

const ViewShare = forwardRef((props, canvasRef) => {

    const [rating, setRating] = useState('');
    const [location, setLocation] = useState('');


    const rmvMenFormat = (value) => {
        const mentionRegex = /\@\[([^)]+)\]\((\d+)\)/g;
        const transformedComment = value.replace(mentionRegex, '@$1');
        return transformedComment;
      };

      useEffect(() => {
        const userLocation = props.userData.city + ', ' + props.userData.state;
        setLocation(userLocation);
        setRating(props.userData.averageReviewCount);
      }, [props.userData.averageReviewCount]);

      const renderStars = () => {
        const stars = [];
        const decimalPart = rating - Math.floor(rating);
    
        for (let index = 1; index <= 5; index++) {
          if (index < rating) {
            stars.push(<span key={index} className="fa fa-star checkedStr"></span>);
          } else if (index === Math.ceil(rating) && decimalPart > 0) {
            stars.push(<span key={index} className="fa fa-star-half-o checkedStr"></span>);
          } else {
            stars.push(<span key={index} className="fa fa-star"></span>);
          }
        }
    
        return stars;
      };
  return (
    <> 
      <Card ref={canvasRef} className="shadow mar_shre" style={{ width:'700px' }} >
            <Card.Body className="p-0">
                <Col sm={12} md={12} lg={12} xl={12}>
                    <Row>
                        <Row className=''>
                            <Col sm={12} md={4} lg={4} xl={4} className="pad_shr text-center">
                              <div><img className='ImgLogo' src={logo}/></div>
                            {/* <img className='share_img' src={usrImg}/> */}
                            <Card.Title >{props.shareData.userName}</Card.Title>
                                    <div className='d-flex justify-content-center'>
                                       <div className='starRtng'>
                                       <div id="ratingStars">
                                       {renderStars()}
                                        </div>
                                       </div>
                                    </div>
                                   { props.shareData.reviewerLocation && <div className='d-flex justify-content-center'>
                                       <div><HiLocationMarker/></div>
                                       <div className=''>
                                           {location}
                                       </div>
                                    </div>}
                            </Col>
                            {/* <div className="sep_dv"></div> */}
                            <Col sm={12} md={7} lg={7} xl={7} className="bdr_lt pad_shr">
                            <div className='d-flex'>
                                       <div className="fol_name text-start">Reviewed by :  </div>
                                       <span className='mar_stty'>
                                           {props.shareData.reviewerName}
                                       </span>
                                    </div>
                                    <div className='d-flex'>
                                       <div className="fol_name text-start">Role :  </div>
                                       <span className='mar_stty'>
                                           {props.shareData.userRole}
                                       </span>
                                    </div>
                                  { props.shareData.comment && <div className=''>
                                       <div className="fol_name text-start">Comment :</div>
                                       <div className=''>
                                           {props.shareData.comment?rmvMenFormat(props.shareData.comment): ""}
                                       </div>
                                    </div>}
                                    <div className="cmtSec">
                                    <RatingBar disabled={true} likecount={props.shareData.likes} dislikecount={props.shareData.dislikes} labelVAlue={props.shareData.score}/>
                                    </div>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Card.Body>
            </Card> 
            </>
  );
});

export default ViewShare;
