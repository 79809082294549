import StarRating from '../Dashboard/DashboardTabs/StarRating';
import { Card, Col, Row,Modal,Button } from "react-bootstrap";
import axios from '../../axios'
import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { BsPlusLg } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import SideBar from './AdminSideBar';
import HeaderMenu from './HeaderMenu';
import BreadCrumb from '../plugins/Breadcrumb';
import { Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom'; 
import { FaTimes  } from 'react-icons/fa';
import { TiTick  } from 'react-icons/ti';
import editRole from '../../assets/images/usr_edit.png';
const ReviewQuestion = (props) => { 
    const { id, name } = props;
    const [reviewQuestions, setReviewQuestions] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editedQuestion, setEditedQuestion] = useState('');
    const [newQuestion, setNewQuestion] = useState('');
    const [questionError, setQuestionError] = useState('');
    const [weightage, setWeightage] = useState('');
    const [editedWeightage, setEditedWeightage] = useState('');
    const location = useLocation();
    const roleId = props.id;
    const roleName = props.name;
    const [deletePopupShow, setDeletePopupShow] = useState(false);
    const [questionId, setQuestionId] = useState(null);
    const handleClose = () => setDeletePopupShow(false);

    useEffect(() => {
        loadQuestions();
    }, [roleId]);

    const loadQuestions = ()=>{
        if(roleId){
          axios.post(process.env.REACT_APP_BASEURL +'/review/questions/byroleid',{'roleId':roleId})
          .then(response => {
            setReviewQuestions(response.data.recordInfo)
          })
          .catch(error => {
              console.log(error);
              toast.error({
                  title: 'Error Occured',
                  position: 'topRight'
              });
          });
      }
      }

      const addRoleQues = () =>{
        setQuestionError('');
        if (!newQuestion) {
            setQuestionError('Question is Required.');
            return;
        }
        const params = {
            question: newQuestion,
            userRoleId: roleId,
            weightage:weightage
          };
            axios.post(process.env.REACT_APP_BASEURL +'/userrole/add/question' ,params)
            .then(response => {
                if(response.data.status == "OK"){
                    toast.success(response.data.message, {
                      theme: 'colored',
                      position: 'top-right',
                      autoClose: 1500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setNewQuestion('');
                    setWeightage('');
                    loadQuestions();
                  }
                
            })
            .catch(error => {
                console.log(error);
            });
            
    }
    const updateRoleQuestion = () =>{
        const params = {
            question: editedQuestion.question,
            userRoleId: roleId,
            weightage: editedWeightage.weightage
          };
            axios.post(process.env.REACT_APP_BASEURL +'/userrole/edit/question/'+ editedQuestion.id , params)
            .then(response => {
                if(response.data.status == "OK"){
                    toast.success(response.data.message, {
                      theme: 'colored',
                      position: 'top-right',
                      autoClose: 1500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setEditMode(false);
                    setEditedQuestion('');
                    setEditedWeightage('');
                    loadQuestions();
                  }
                
            })
            .catch(error => {
                console.log(error);
            });  
    }  
    const showDeletePopup = (questionId) => {
        setQuestionId(questionId); 
        setDeletePopupShow(true);
    }
    const deleteQuestion = ()=>{
        if(questionId){
          axios.delete(process.env.REACT_APP_BASEURL +'/review/questions/delete/' + questionId)
          .then(response => {
            if(response.data.status == "OK"){
                toast.success(response.data.message, {
                  theme: 'colored',
                  position: 'top-right',
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setEditMode(false);
                setEditedQuestion('');
                setEditedWeightage('');
                loadQuestions();
                setDeletePopupShow(false);
              }
            
        })
        .catch(error => {
            console.log(error);
            setDeletePopupShow(false);
        });  
      }
      }
    return ( 
        <>
         {/* <HeaderMenu/> */}
        {/* <div className=""> */}
        {/* <SideBar/> */}
        {/* <div className="main-content ml_200"> */}
        {/* <BreadCrumb/> */}
        {/* <Card className="shade h-500"> */}
                <div className='role_container'>    
                <Row className='m_b18 title_txt'>
                    <Col sm={12} md={12} lg={12} xl={12}>
                        Selected Role: {roleName}
                    </Col>
                </Row>
                {reviewQuestions&&  ( <Row className='m_b8'>
                    <Col sm={6} md={6} lg={6} xl={6} className=""> 
                        <Input
                        className='inptRole rle_place m_b8'
                            placeholder={editMode ? "Edit Question" : "Enter a new Question"}
                            type="text"
                            value={editMode ? editedQuestion.question : newQuestion}
                            onChange={(e) => {
                                if (editMode) {
                                  setEditedQuestion({ ...editedQuestion, question: e.target.value });
                                } else {
                                  setNewQuestion(e.target.value);
                                  setQuestionError('');
                                }
                              }}
                        />
                        {questionError && <div className="text-danger">{questionError}</div>}
                    </Col>
                    <Col sm={6} md={3} lg={3} xl={3}> 
                    <Input
                        placeholder="Weightage in %"
                        className="m_b8 inptRole rle_place"
                        type="number"
                        min="0"
                        max="100"
                        value={editMode ? editedWeightage.weightage : weightage}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === "" || (inputValue >= 0 && inputValue <= 100)) {
                                if (editMode) {
                                    setEditedWeightage({ ...editedWeightage, weightage: inputValue });
                                } else {
                                    setWeightage(inputValue);
                                }
                            }
                        }}
                    />
                    </Col>
                    <Col sm={2} md={3} lg={3} xl={3} className="align-items-center p-0"> 
                    {editMode ? (
                        <>
                            <TiTick
                                className="c_pointer plus_icn m_r15"
                                onClick={updateRoleQuestion}
                            />
                            <FaTimes
                                className="c_pointer close_icn"
                                onClick={() => {
                                    setEditMode(false);
                                    setEditedQuestion('');
                                    setEditedWeightage('');
                                }}
                            />
                        </>
                    ) : (
                        <BsPlusLg onClick={addRoleQues} className="c_pointer " />
                    )}
                </Col>
            </Row>)}
                {reviewQuestions&& reviewQuestions.map((data) =>( <Row>
                    <Col sm={8} md={6} lg={6} xl={6}> 
                    <Input placeholder="Enter a new Question" className='m_b8 inptRole rle_place' type="text" value={data.question}></Input>
                    </Col>
                    <Col sm={3} md={3} lg={3} xl={3}> 
                    {data.weightage?<Input className='m_b8 inptRole rle_place' type="text" value={data.weightage + ' '+ '%'}></Input>:<Input className='m_b8 inptRole rle_place' type="number" value={data.weightage}></Input>}
                    </Col>
                    <Col sm={1} md={2} lg={2} xl={2} className="align-items-center p-0"> 
                    <div className='d-flex' >
                        <div className='m_r15'><span onClick={() => {
                            setEditMode(true);
                            setEditedQuestion(data);
                            setEditedWeightage(data);
                            setQuestionError('');
                            }} title="Edit" className="c_pointer icon_usrRole"><img src={editRole}/></span>
                        </div>
                        <div className='m_r15'><HiOutlineTrash onClick={() => showDeletePopup(data.id)} title="Delete" className="ques_trash c_pointer"/></div>                    
                    </div>
                    </Col>
                </Row>))}
                </div>                
            {/* </Card> */}
             {/* ----------- Delete Confirmation Popup ---------- */}
             <Modal size="sm" show={deletePopupShow} onHide={() => setDeletePopupShow(false)} >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                      Delete Confirmation
                    </Modal.Title>
                    <span onClick={handleClose} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body className='bordr'>
                    <span>Are you sure to delete?</span>
                </Modal.Body>
                <Modal.Footer className='bordr'>
                    <Button variant="primary" className="del_bttn"  onClick={deleteQuestion}>Yes</Button>
                    <Button variant="danger" className="del_bttn" onClick={() => setDeletePopupShow(false)}>No</Button>
                </Modal.Footer>
              </Modal>
        {/* </div> */}
            {/* </div> */}
            <ToastContainer/>
        </>
    );
}

export default ReviewQuestion;