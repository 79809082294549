import {Button,Card,Form,Col,Row,Container,InputGroup} from "react-bootstrap";
import "./register.css";
import { useForm} from "react-hook-form";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useState} from "react";
import {useNavigate,NavLink} from "react-router-dom";
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import LoaderOverlay from '../plugins/LoaderOverlay';
import {GrMail} from 'react-icons/gr'
import {BiSolidLockOpen} from 'react-icons/bi'
import { IoEye,IoEyeOff } from "react-icons/io5";
const Register = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit,getValues,formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [showbtn, setshowbtn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmpassword, setShowConfirmpassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const toggleConfirmpassword = () => {
      setShowConfirmpassword(!showConfirmpassword);
    };
    const onSubmit = (data) => {
    setIsLoading(true);
    let userEmail = data.email;
    axios.post(process.env.REACT_APP_BASEURL +'/user/exists',{'email':userEmail})
    .then(response => {
      setIsLoading(false);
      console.log("res",response)
      if(response.data.status == true){
      toast.error('User Already Exists', {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
      else{
        navigate('/profile')
        localStorage.setItem('userdetail',JSON.stringify(data))
      }
    })
    .catch(error => {
      setIsLoading(false);
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
    }
  return (
    <>
    <LoaderOverlay isLoading={isLoading} />
      <div className="">
        <Col lg="12" md="12" sm="12" xl="12">
        <Row>
          <Col lg="5" md="5" sm="12" xl="5" className='log_bg log_text'>
          <div className="mt-4 mb-3">
            <h5 className='fz_18'>See what they’re saying…</h5>
           </div>
          <div className='brand_logo'></div>
          <div className='tiipstr_logo'></div>
          </Col>
          <Col lg="7" md="7" sm="12" xl="7" style={{backgroundColor:'white'}}>
          <Header/>
          <div className="d-flex justify-content-center align-items-center copyright bg-content">
            <Col lg="7" md="7" sm="12" xl="5">
                <div className="mt-2">
                     <h4>Sign Up</h4>
                </div>
                <div  style={{paddingTop: '0px!important'}}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="email" className="log_form form-group">
                  <div className="inputContainer">
                  <label className="fz_14">Email</label>
                  <InputGroup className={` ${errors.email ? 'is-invalid' : 'inputBox'}`}>
                    <InputGroup.Text className='border-0'>
                      <GrMail />
                    </InputGroup.Text>
                    <Form.Control
                      className="formControl fz_13"
                      type="text"
                      placeholder="Email"
                      name="email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      })}
                    />
                    </InputGroup>
                    {errors.email && (
                      <Form.Text className="text-danger">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
                <Form.Group controlId="password" className="log_form form-group">
                  <div className="inputContainer">
                  <label className="fz_14">Password</label>
                  <InputGroup className={` ${errors.password ? 'is-invalid' : 'inputBox'}`}>
                    <InputGroup.Text className='border-0'>
                      <BiSolidLockOpen />
                    </InputGroup.Text>
                    <Form.Control
                      className="formControl fz_13"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      name="password" id="log_pass"
                      {...register('password', { required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters!"
                        },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/i,
                           message: "*Password must have one special character & number"
                         },
                    })}
                    />
                    <InputGroup.Text className='border-0'>
                    {!showPassword ?<IoEye className='c_pointer' onClick={togglePasswordVisibility}/>:
                    <IoEyeOff className='c_pointer' onClick={togglePasswordVisibility}/>}
                    </InputGroup.Text>
                    </InputGroup>
                    {errors.password && (
                      <Form.Text className="text-danger">
                        {errors.password.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
                <Form.Group controlId="confirmPassword" className="log_form form-group">
                  <div className="inputContainer">
                    <label className="fz_14">Confirm Password</label>
                  <InputGroup className={` ${errors.confirmPassword ? 'is-invalid' : 'inputBox'}`}>
                    <InputGroup.Text className='border-0'>
                      <BiSolidLockOpen />
                    </InputGroup.Text>
                    <Form.Control
                      style={{position: "unset",border: "none"}}
                      type={showConfirmpassword ? 'text' : 'password'}
                      className="formControl fz_13"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      {...register('confirmPassword', { required: 'Confirm Password is required',
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters!"
                        } ,
                         pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/i,
                            message: "*Password must have one special character & number"
                          },
                      validate: (match) => {
                        const password = getValues("password")
                        return match === password || "Passwords should match!"
                    } })}
                    />
                     <InputGroup.Text className='border-0'>
                      {!showConfirmpassword ?<IoEye className='c_pointer' onClick={toggleConfirmpassword}/>:
                      <IoEyeOff className='c_pointer' onClick={toggleConfirmpassword}/>}
                    </InputGroup.Text>
                    </InputGroup>
                    {errors.confirmPassword && (
                      <Form.Text className="text-danger">
                        {errors.confirmPassword.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
                 <div >
                    <input type="checkbox" style={{backgroundColor: 'white'}} onChange={() => setshowbtn((state) => !state)}></input>
                    <small className="text-light">&nbsp;By registering for tiipstr, you agree to our</small>
                    <div className="terms">
                      <NavLink to="/terms-of-service" style={{textDecoration:'none'}}>
                        Terms of Service</NavLink><small>&nbsp;and&nbsp;</small>
                      <NavLink to="/privacy-policy" style={{textDecoration:'none'}}>
                        Privacy Policy</NavLink> 
                        </div>
                </div>
                <div className="text-center">
                <div style={{textAlign:'center',paddingTop:'10px'}}>
                    {showbtn && <Button type="submit" className="btn btn-primary next-btn">Next</Button>}
                </div></div>
              </Form>
                </div>
                </Col>
              </div>
          </Col>
        </Row>
        </Col>
      </div>
      <ToastContainer/>
    </>
  );
};

export default Register;
