import { Button, Card, Col, Container, Row } from "react-bootstrap";
import profile from '../../assets/images/profile.jpg'
import {FaPlus,FaMinus} from 'react-icons/fa'
import { useState,useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import axios from '../../axios';
import "./findnew.css";
import HeaderMenu from "../Dashboard/headerMenu";
import SideBar from "../Dashboard/SideBar";
import BreadCrumb from "../plugins/Breadcrumb";
import {followId} from '../../Redux/Actions/userAction';
import { IoIosArrowDown } from "react-icons/io";
import {Overlay, Popover,OverlayTrigger } from 'react-bootstrap';
import Horizontalbar from '../../components/plugins/Horizontalbar';
import StarRating from '../Dashboard/DashboardTabs/StarRating';
import { FiUsers,FiUser } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";

const Findnew = () => {
  const [userList,setUserList] = useState([]);
  const userId = useSelector(state => state.user.userId);
  const [avgCount, setAvgCount] = useState(''); 
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    loadsUsers();
  }, []);
  const viewUser=(id)=>{
    dispatch(followId(id));
    navigate('/dashboard/finduser/view', { state: { userId: id } });

  }
  const loadsUsers =()=>{
    axios.get(process.env.REACT_APP_BASEURL +'/user/list')
      .then(response => {
        if(response.data.message == "Data loaded successfully."){
          setUserList(response.data.data)
        }
        else{
          toast.error(response.data.message, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
  }
  const followuser = (id)=>{
    if(id){
    const userInfo={'followingUserId':userId,'followedUserId':id,'isActive':1}
    axios.post(process.env.REACT_APP_BASEURL +'/followers/add',userInfo)
      .then(response => {
        if(response.data.status == "OK" && response.data.message=="Followers already exists."){
          toast.success(response.data.message, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          loadsUsers();
        }
        else if(response.data.status == "OK" && response.data.message=="Followers Added successfully."){
          toast.success('Followed successfully', {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          loadsUsers();
        }
        else{
          toast.error(response.data.message, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
  }
  }
  const unfollowuser=(id)=>{
    if(id){
    axios.patch(process.env.REACT_APP_BASEURL +'/followers/users/'+userId+'/followers/'+id)
      .then(response => {
        if(response.data.status == "OK"){
          toast.success('Unfollowed successfully', {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          loadsUsers();
        }
        else{
          toast.error(response, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
  }
  }
  const handleArrowClick = (rating,avgCount) => {
    setAvgCount(avgCount);
    const starResponse = rating;
    const starData = starResponse[0];

    const labelValueMap = {};
    for (const label in starData) {
      labelValueMap[label] = parseFloat(starData[label]);
    }
    const sortedLabels = Object.keys(labelValueMap).sort((a, b) => {
      const numA = parseInt(a.split(' ')[0]);
      const numB = parseInt(b.split(' ')[0]);
      return numB - numA;
    });
    const result = sortedLabels.map((label) => ({
      label,
      value: labelValueMap[label],
    }));
    setRevGlobalRtng(result);
    console.log("dsfsd",result)
  }
    return (  
      <>
      <HeaderMenu/>
        <div className="">
          <SideBar/>
          <div className="main-content ml_200">
          <BreadCrumb/>
            <Card className="shade h-500">
              <Container>
                  <h4 className="headr_sty">Add a new tiipstr to review</h4>
                  <Row className="pt-2">
                  {userList.map((user) => (
                  <Col sm={12} md={4} lg={3} xl={2}  className="usr_card">
                  <Card className="usr_crd">
                    <div className="user text-center d-flex justify-content-center mt-2">
                          <div className="follow_profile">
                              <img src={user.imagePath ? user.imagePath : profile} className="rounded-circle" width="60" height="60"/>
                          </div>
                      </div>
                    <div className="mt-2 text-center">
                      <h6 className="mb-0">{user.name}</h6>
                      <div className="fs_12 d-flex align-items-center justify-content-center" style={{marginBottom: '0px'}}>
                                            <StarRating rating={user.averageReviewCount} disabled={true} customClass="user-star"/>
                                                   <span>({user.totalReviewCount})</span>
                                        <OverlayTrigger
                                            trigger="click"
                                            key={user.id}
                                            placement="bottom"
                                            overlay={
                                                <Popover id={`popover-${user.id}`} className='pop_rating'>
                                                <div className="drp_rating"><StarRating rating={avgCount} disabled={true} /><span>{avgCount} out of 5</span> </div>
                                                                        {revGlobalRtng.map((item, index) => ( <Row key={index}>
                                                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0"><span className="prog_span">{item.label}</span> </Col>
                                                                            <Col sm={12} md={8} lg={8} xl={7} className="p-0"><Horizontalbar dataSet={item.value}/></Col>
                                                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0 prog_span" style={{marginLeft: '10px'}}><span>{item.value}{item.value > 1 ? <FiUsers style={{marginLeft: '6px'}}/>: <FiUser style={{marginLeft: '6px'}}/>}</span></Col>
                                                                            </Row>))}
                                                </Popover>
                                            }
                                            rootClose
                                            >
                                            <span className="d-flex align-items-center" onClick={() => handleArrowClick(user.starRating,user.averageReviewCount)} >
                                                <IoIosArrowDown className='clr_gray'/>
                                            </span>
                                        </OverlayTrigger>  </div>
                                        <div className="fs_12 text-center">{user.location?<HiLocationMarker/>:''}&nbsp;{user.location ? user.location : '-'} </div>
                      <div className="d-flex justify-content-between">
                        <div className="stats">
                          <span>{user.followersCount}</span>
                          <h6>Followers</h6>
                        </div>
                        <div className="stats">
                          <span>{user.followingCount}</span>
                          <h6>Following</h6>
                        </div>
                      </div>
                    </div>
                      <div className="d-flex justify-content-around">
                        <div className="stats">
                        <Button className="btn btn-sm u_view_btn" onClick={() => viewUser(user.id)}>View</Button>
                        </div>
                        <div className="stats">
                        {user.isFollow?
                        <Button className="btn btn-sm u_remove_btn" title="Unfollow" onClick={() => unfollowuser(user.id)}>Unfollow</Button>:
                        <Button className="btn btn-sm u_review_btn" title="Follow" onClick={() => followuser(user.id)}>Follow</Button>
                        }
                        </div>
                    </div>
                  </Card>
                  </Col>
                  ))}
                  </Row>
              </Container>
              </Card>
              </div>
        </div>     
        <ToastContainer/>
        </>
    );
}
 
export default Findnew;