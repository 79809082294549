import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BreadCrumb = ({ paths }) => {

  return (
    <Breadcrumb>
      {paths.map((path, index) => (
        <Breadcrumb.Item key={index} active={index === paths.length - 1}>
          {index !== paths.length - 1 ? (
            <NavLink to={path.link}>{path.name}</NavLink>
          ) : (
            path.name
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumb;
