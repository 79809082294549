import {Button,Card,Form,Col,Row,Container,InputGroup} from "react-bootstrap";
import "../../components/Register/register.css";
import { useForm} from "react-hook-form";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import LoaderOverlay from '../plugins/LoaderOverlay';
import {GrMail} from 'react-icons/gr'
import {BiSolidLockOpen} from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { IoEye,IoEyeOff } from "react-icons/io5";
const ResetPassword = () => {
  const { token } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const { register, handleSubmit,getValues,formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [showbtn, setshowbtn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmpassword, setShowConfirmpassword] = useState(false);
    useEffect(() => {
      Decodetoken(token);
  }, [token]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmpassword = () => {
    setShowConfirmpassword(!showConfirmpassword);
  };
  const Decodetoken = (token) =>{
      if(token){
          axios.post(process.env.REACT_APP_BASEURL +`/user/decode/key/${token}`)
          .then(response => {
             setUserId(response.data.data.id);
          })
          .catch(error => {
              console.log(error);
              toast.error({
                  title: 'Error Occured',
                  position: 'topRight'
              });
          });
      }    
  }

    const onSubmit = (data) => {
      const params ={
        password: data.password,
        id: userId
      }
    setshowbtn(true);
    setIsLoading(true);
    axios.post(process.env.REACT_APP_BASEURL +'/user/update/resetpassword',params)
    .then(response => {
      setIsLoading(false);
      console.log("res",response)
      if(response.data.status == "OK"){
      toast.success(response.data.message, {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          setTimeout(() => {
          navigate('/login')
          }, 1500);
          },
      })
    }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    })
    .catch(error => {
      setIsLoading(false);
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
    }
  return (
    <>
    <LoaderOverlay isLoading={isLoading} />
      <div className="body-content">
      <div className="">
        <Col lg="12" md="12" sm="12" xl="12">
          <Row>
            <Col lg="5" md="5" sm="12" xl="5" className='log_bg log_text'>
              <div className="mt-4 mb-3">
                <h5 className='fz_18'>See what they’re saying…</h5>
            </div>
            <div className='brand_logo'></div>
            <div className='tiipstr_logo'></div>
            </Col>
            <Col lg="5" md="7" sm="12" xl="7" style={{backgroundColor:'white'}}>
          <Header/>
          <div className="d-flex justify-content-center align-items-center pro-bg-content">
          <Col lg="7" md="7" sm="12" xl="6">
            <Card className="bg-secondary shadow border-0">
            <div className="text-muted text-center mt-4 mb-4">
                <h5>Reset Password</h5>
              </div>
              <Card.Body className="pt-0">
                <div className="profileForm">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group controlId="password" className="formGroup form-group">
                    <div className="inputContainer">
                    <InputGroup className={` ${errors.password ? 'is-invalid' : 'inputBox'}`}>
                      <InputGroup.Text className='border-0'>
                        <BiSolidLockOpen />
                      </InputGroup.Text>
                      <Form.Control
                        className="formControl fz_13"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        name="password"
                        {...register('password', { required: 'Password is required',
                        minLength: {
                          value: 6,
                          message: "Password must be at least 6 characters!",
                          } 
                        ,
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/i,
                           message: "*Password must have one special character & number"
                         } 
                        })}
                      />
                      <InputGroup.Text className='border-0'>
                        {!showPassword ?<IoEye className='c_pointer' onClick={togglePasswordVisibility}/>:
                        <IoEyeOff className='c_pointer' onClick={togglePasswordVisibility}/>}
                      </InputGroup.Text>
                      </InputGroup>
                      {errors.password && (
                        <Form.Text className="text-danger">
                          {errors.password.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group controlId="confirmPassword" className="formGroup form-group">
                    <div className="inputContainer">
                    <InputGroup className={` ${errors.confirmPassword ? 'is-invalid' : 'inputBox'}`}>
                      <InputGroup.Text className='border-0'>
                        <BiSolidLockOpen />
                      </InputGroup.Text>
                      <Form.Control className="formControl fz_13"
                        style={{position: "unset",border: "none"}}
                        type={showConfirmpassword ? 'text' : 'password'}
                        name="confirmPassword"
                        {...register('confirmPassword', { required: 'Confirm Password is required',
                        minLength: {
                          value: 6,
                          message: "Password must be at least 6 characters!"
                          } ,
                          pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/i,
                             message: "*Password must have one special character & number"
                           },
                        validate: (match) => {
                          const password = getValues("password")
                          return match === password || "Passwords should match!"
                      } })}
                      />
                      <InputGroup.Text className='border-0'>
                        {!showConfirmpassword ?<IoEye className='c_pointer' onClick={toggleConfirmpassword}/>:
                        <IoEyeOff className='c_pointer' onClick={toggleConfirmpassword}/>}
                      </InputGroup.Text>
                      </InputGroup>
                      {errors.confirmPassword && (
                        <Form.Text className="text-danger">
                          {errors.confirmPassword.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                  <div style={{textAlign:'center',paddingTop:'10px'}}>
                    <Button type="submit" className="btn btn-primary next-btn">Submit</Button>
                  </div>
                  </Form>
                  </div>
                  
              </Card.Body>
            </Card>
          </Col>
          </div>
          </Col>
          </Row>
        </Col>
      </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default ResetPassword;
