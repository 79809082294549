import HeaderMenu from '../headerMenu';
import SideBar from "../SideBar";
import { Button, Card, Col, Row,Container} from "react-bootstrap"
import {useEffect, useState} from 'react';
import loader from '../../../assets/images/loader.gif';
import { ToastContainer, toast } from 'react-toastify';
import axios from "../../../axios";
import profile from '../../../assets/images/profile.jpg';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import BreadCrumb from "../../plugins/Breadcrumb";
import { useDispatch } from 'react-redux';
import { notifyRevInfo } from '../../../Redux/Actions/userAction';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from "moment";

const Notification = (props) => {
    const [reviewList,setReviewList] = useState([]);
    const [cmtList,setCmtList] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const  loadRevNotifyList= () => {
        setLoading(true)
        axios.post(process.env.REACT_APP_BASEURL +'/review/list/mention/byuserid',{'userId':props.userId})
        .then(response => {
            let result = response.data.recordInfo;
            setReviewList(result)
            setLoading(false)
        })
        .catch(error => {
        setLoading(false)
        toast.error('Error occurred', {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
      });
    }
    const updateReadStatus=(id)=>{
        const params = {
            'id': id
        }
        axios.post(process.env.REACT_APP_BASEURL +'/review/mention/read',params)
        .then(response => {
            console.log(response)
          if(response.data.status == "OK"){
            loadRevNotifyList();
            Refresh();
          }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
        });
      };
      const updatecmtReadStatus=(id)=>{
        const params = {
            'id': id
        }
        axios.post(process.env.REACT_APP_BASEURL +'/comments/mention/read',params)
        .then(response => {
            console.log(response)
          if(response.data.status == "OK"){
            loadCmtNotifyList();
            Refresh();
          }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
        });
      }
      const  handleViewClick = (review) => {
        navigate('/dashboard/notification/view',{state:{type:'review',revId:review.id}});
        // dispatch(notifyRevInfo(review));
        updateReadStatus(review.reviewMentionId)
      }
      const  handleCmtClick = (cmt) => {
        navigate('/dashboard/notification/view',{state:{type:'comment',status:cmt.status,revId:cmt.id}});
        // dispatch(notifyRevInfo(cmt));
        updatecmtReadStatus(cmt.commentMentionId)
      }
    const  loadCmtNotifyList= () => {
        console.log(props.userId)
        axios.post(process.env.REACT_APP_BASEURL +'/comments/list/mention/byuserid',{'userId':props.userId})
        .then(response => {
            let result = response.data.recordInfo;
            setCmtList(result)
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
    }
    useEffect(() => {
        loadRevNotifyList();
        loadCmtNotifyList();
      }, [props]);

      const Refresh = () =>{
        if(props.authToken){
          axios.post(process.env.REACT_APP_BASEURL +'/user/refresh' , {'authToken':props.authToken})
          .then((response) => {
            if(response.data){
              dispatch({
                type: 'SAVE_USER_DATA',
                payload: {
                  token: response.data.data.token ,
                  userId: response.data.data.userId,
                  userType: response.data.data.userType,
                  userName: response.data.data.name,
                  imagePath: (response.data.data.imagePath != null ? response.data.data.imagePath: ''),
                  newReviewCount: response.data.data.newReviewCount,
                  newInboxCount: response.data.data.newInboxCount,
                  newMentionCount: response.data.data.newMentionCount
                },
              }); 
            }
            
          }).catch((error) => {
            console.log("refresh Error")
          });
        }
      }
  
    return ( 
        <>
        <HeaderMenu/>
        <SideBar/>
            <div className="main-content ml_200">
                <BreadCrumb/>
                <Card className="shade h-500">
                    <Container>
                    <Tabs defaultActiveKey="review" className="mb-3 notify_tab"> 
                    <Tab eventKey="review" title="Reviews">
                    {reviewList.length>0 ? reviewList.map((review, i) => (
                    <Card className={`notify_card_rev ${review.isRead == true?'not_crd_bg_rd crd_shd':'not_crd_bg shadow'}`} 
                    onClick={()=>{handleViewClick(review)}}>
                            <Card.Body className='p-0'>
                                <Row>
                                <span className="noty_bar_men p-0"></span>
                                    <Col sm={2} md={2} lg={1} xl={1}>
                                        <div className="follow_profile m-2">
                                            <img src={review.reviewerImagePath ? review.reviewerImagePath:profile} className="rounded-circle" width="50" height="50"/>
                                    </div>
                                    </Col>
                                    <Col sm={6} md={7} lg={8} xl={8} className="m-2">
                                        <p className='fz_15 font-weight-normal mb-0'>you have a mentioned review by <b style={{color:'#ac3737'}}>{review.reviewerName}</b></p>
                                        <Row>
                                        <div className="d-flex">
                                        <div style={{fontSize: '11px',color: '#959595'}}> 
                                            {moment.utc(review.createdAt).local().startOf('seconds').fromNow()}</div>
                                        </div>
                                        </Row>
                                    </Col>
                                    <Col sm={2} md={2} lg={2} xl={2}>
                                        <span className="float-right text-center mt-2 notify_btn_men fz_13">View</span>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>)):(loading ? (
                            <div className="text-center"><img className="loader-image" src={loader} alt="Loading..." /></div>
                        ) :  (
                         <div  className="row col-md-12 d-align mx-0">
                            <div className="no-data w-100 text-center font-weight-600 fz_14">Sorry, there is no data to be displayed</div>
                        </div>))}
                        </Tab>
                        <Tab eventKey="mention" title="Comments">
                        {cmtList.length>0 ? cmtList.map((cmt, i) => (
                        <Card className={`notify_card_men ${cmt.isRead == true?'not_crd_bg_rd crd_shd':'not_crd_bg shadow'}`}
                        onClick={()=>{handleCmtClick(cmt)}}>
                        {/* {cmtList.length>0 ? cmtList.map((cmt, i) => (<Card className="shadow notify_card_men"> */}
                            <Card.Body className='p-0'>
                                <Row>
                                <span className="noty_bar_rev p-0"></span>
                                    <Col sm={2} md={2} lg={1} xl={1}>
                                        <div className="follow_profile m-2">
                                            <img src={cmt.userImagePath ? cmt.userImagePath:profile} className="rounded-circle" width="50" height="50"/>
                                    </div>
                                    </Col>
                                    <Col sm={6} md={7} lg={8} xl={8} className="m-2">
                                        <p className='fz_15 font-weight-normal mb-0'>you have a mentioned comment by <b style={{color:'#a0ac37'}}>{cmt.userName}</b></p>
                                        <Row>
                                        <div className="d-flex">
                                        <div style={{fontSize: '11px',color: '#959595'}}> 
                                            {moment.utc(cmt.createdAt).local().startOf('seconds').fromNow()}</div>
                                        </div>
                                        </Row>
                                    </Col>
                                    <Col sm={2} md={2} lg={2} xl={2}>
                                        <span className="float-right text-center mt-2 notify_btn_rev fz_13">View</span>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>)):<div  className="row col-md-12 d-align mx-0">
                            <div className="no-data w-100 text-center font-weight-600 fz_14">Sorry, there is no data to be displayed</div>
                        </div>}
                        </Tab>
                        </Tabs>
                    </Container>
                </Card>  
            </div>
        </>
     );
}
const mapStateToProps = (state) => {
    return {
      userId: state.user.userId,
      authToken: state.user.token
    };
  };

export default connect(mapStateToProps)(Notification);