import React from 'react'

const Horizontalbar = (props) => {
	
	const Parentdiv = {
		height: '15px',
		width: '100%',
		backgroundColor: 'whitesmoke',
		borderRadius: 5,
    	margin: '5px 0px',
		border: '1px solid rgb(222 222 222)'
	}
	
	const Childdiv = {
		height: '100%',
		width: `${props.dataSet}%`,
		backgroundColor: 'orange',
		borderRadius:0,
		textAlign: 'right'
	}
	
	const progresstext = {
		padding: 10,
		color: 'black',
		fontWeight: 900
	}
		
	return ( 
    <>
	<div style={Parentdiv}>
	  <div style={Childdiv}>
	  </div>
	</div>
    </>
	)
}

export default Horizontalbar;
