import './inbox.css';
import profile from '../../../assets/images/profile.jpg'
export function Searchbar() {
    return (
        <div className='searchbar'>
            <div className="searchForm">
                <input type="text" placeholder='Find a User'/>
            </div>
            <div className="userchat">
            <img src={profile}/>
                <div className="userchatInfo">
                    <span>Jane</span>
                </div>
            </div>
        </div>
        )
    }
    
    export default Searchbar;