
import React, { useState, useEffect } from 'react';
import {Nav,Modal,Navbar} from 'react-bootstrap';
import profile from '../../assets/images/profile.jpg';
import { NavLink,Link, Navigate} from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { FaSearch,FaUser} from 'react-icons/fa';
import { PiBellBold } from 'react-icons/pi';
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { TbLogout } from 'react-icons/tb';
import { HiLocationMarker } from "react-icons/hi";
import './headerMenu.css';
import {DropdownMenu,DropdownItem,UncontrolledDropdown,DropdownToggle,Media} from 'reactstrap';
import { connect } from 'react-redux';
import { logoutAction } from '../../Redux/Actions/userAction';
import {  useLocation } from 'react-router-dom';
import axios from '../../axios';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch,useSelector } from 'react-redux';
import { Card, Row, Col,Button,Container } from "react-bootstrap";
import {followId} from '../../Redux/Actions/userAction';

function HeaderMenu(props) {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [filter, setfilter] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [activeIcon, setActiveIcon] = useState(null);
  const [bannerName, setBannerName] = useState('');
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Logout=()=>{
    dispatch(logoutAction());
    localStorage.removeItem('userdetail'); 
    navigate('/login')
  }
 
  useEffect(() => {
    handleDynamicName();
    console.log('pp',props)
  }, []);
  const handleDynamicName = ()=>{
    var path = location.pathname.split('/');
    if(path[2] != undefined)
    {
      if(path[2]=='finduser')
        setBannerName('Add User');
      else if(path[2]=='viewuser')
      setBannerName('View User');
      else
        setBannerName(path[2].charAt(0).toUpperCase() + path[2].slice(1));
    }
    else if(path[1]=='inbox')
      setBannerName('Inbox');
    else if(path[1]=='dashboard')
      setBannerName('Dashboard');
    else
      setBannerName(path[1].charAt(0).toUpperCase() + path[1].slice(1));
  }
  const handleSearch = (searchterm) => {
    if(searchterm){
        let formData = new FormData();
        formData.append('keyword', searchterm);
        axios.post(process.env.REACT_APP_BASEURL +'/user/search',formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      })
        .then(response => {
            setFilterData(response.data.data)
            setfilter(true)
        })
        .catch(error => {
            console.log(error);
        });
    }   
}
const handleNameClick = (userId) =>{
  dispatch(followId(userId));
  navigate('/dashboard/viewuser');
}
const unformatphone = (value)=>{
  if (value != '' && value.trim().startsWith("+")) {
    let phoneData = value.trim().split(" ");
    if(phoneData[1]!='' && phoneData[1]!=undefined){
    let phone_no = phoneData[1].replace(/[()-]/g, '');
    return phone_no;}
    else{
      return phoneData;
    }
  }else{
    if (value!='' && value!=undefined){
    let phone_no = value.replace(/[()-]/g, '');
    return phone_no;
    }
    else
    return value;
  }
  }
  const handleChange = (event) => {
    let unformatData = unformatphone(event.target.value);
    setSearchTerm(event.target.value);
    handleSearch(unformatData);
  };

  const handleKeyPress = (event) => {
    let unformatData = unformatphone(event.target.value);
    if (event.key === 'Enter') {
      handleSearch(unformatData);
    }
  };
  const followuser = (id)=>{
    if(id){
    const userInfo={'followingUserId':props.userId,'followedUserId':id,'isActive':1}
    axios.post(process.env.REACT_APP_BASEURL +'/followers/add',userInfo)
      .then(response => {
        if(response.data.status == "OK" && response.data.message=="Follower already exists."){
          toast.success(response.data.message, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleSearch(searchTerm);
        }
        else if(response.data.status == "OK" && response.data.message=="Followers Added successfully."){
          toast.success('Followed successfully', {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleSearch(searchTerm);
        }
        else{
          toast.error(response.data.message, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
  }
  }
  const unfollowuser=(id)=>{
    if(id){
    axios.patch(process.env.REACT_APP_BASEURL +'/followers/users/'+props.userId+'/followers/'+id)
      .then(response => {
        if(response.data.status == "OK"){
          toast.success('Unfollowed successfully', {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleSearch(searchTerm);
        }
        else{
          toast.error(response, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
  }
  }
  const handleEvent = (key) => {
    props.onEventKey(key);
    setActiveIcon(key === activeIcon ? null : key);
  };
  

  return (
    <>
      <div className="headerMenu">
          <Navbar
          expand="lg"
          className='menu-navbar header-menu'
        >
            <Container className='container_width bg-white'>
                <Navbar.Brand>
                <div><h1 className='tiipstr'>{bannerName}</h1></div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav" className='flex-end'>
                <UncontrolledDropdown nav>
                      <DropdownToggle nav>
                      <div class="input-group hr_search m-2">
                        <input class="form-control py-2 border-right-0 border" placeholder="Search for name, number, or email..." value={searchTerm}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress} id="search-input"/>
                        <span class="input-group-append">
                          <div class="input-group-text bg-transparent"><FiSearch className='search_icon' onClick={()=>handleSearch(searchTerm)}/></div>
                        </span>
                      </div>
                      </DropdownToggle>
                      {filterData.length>0?<DropdownMenu className="dropdown-menu-arrow">
                        <DropdownItem className="noti-title" header tag="div">
                        <div className="searchBox">
                            {filterData.length>0 ? (filterData.map((data, i) => (<Card className="box_shadow" key={i}>
                                <Col lg={12}>
                                    <Row>    
                                      <Col lg={9} className="p-2">
                                          <div className="text-left d-flex">
                                          <a href="#"  onClick={() => {navigate('/dashboard/viewuser', { state: { userId: data.id } });}}>{data.name}&nbsp;</a><p className="text-lowercase">{data.isPublic ? `(${data.email})` : `(${data.email.slice(0, 4)}${'x'.repeat(Math.max(0, data.email.length - 4))})`}</p>
                                          </div>
                                          {data.location?<div className="text-left d-flex">
                                            <HiLocationMarker className="mt_4"/>&nbsp;<p className="text-lowercase">{data.location}</p>
                                          </div>:''}
                                      </Col>
                                      <Col lg={3} className="p-2">
                                        {data.isFollow?<Button className="btn btn-sm remove_btn" title="Unfollow" onClick={() => unfollowuser(data.id)}>Unfollow</Button>:
                                        <Button className="btn btn-sm review_btn" title="Follow" onClick={() => followuser(data.id)}>Follow</Button>}
                                      </Col>
                                    </Row>
                                </Col>
                            </Card>))):(filter?
                            <div  className="row col-md-12 d-align mx-0">
                                <div className="no-s-data w-100 text-center fz-10">Sorry, there is no data to be displayed</div>
                            </div>:'')}
                        </div>
                        </DropdownItem>
                      </DropdownMenu>:''}
                    </UncontrolledDropdown>
                
              
                <Nav className="src/App.css">
                <Nav.Link as={NavLink} to="/dashboard/notification">
                        <div>
                          <PiBellBold className="bell_icon"/>
                          {props.newMentionCount != 0 ?<div className="bell_red"></div>:''}
                        </div>
                    </Nav.Link>
                    <Nav.Link className="p-0">
                    <UncontrolledDropdown nav className='top_5'>
                      <DropdownToggle nav className="p-0">
                        <Media className="align-items-center">
                          <span className="avatar avatar-sm rounded-circle">
                           <img src={props.imagePath ? props.imagePath : profile}/>
                          </span>
                          <span className="logo_name">{props.userName}<span><IoIosArrowDown/></span></span>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem className="noti-title" header tag="div">
                          <h6 className="text-overflow drop_header m-0">Welcome!</h6>
                        </DropdownItem>
                        <DropdownItem onClick={() => navigate('/dashboard/profile')}>
                          <FaUser className="ni ni-single-02" />
                          <span>My profile</span>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem href="#pablo" onClick={() => setShowConfirmPopup(true)} >
                          <TbLogout className="ni ni-user-run" />
                          <span>Logout</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    </Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </div><div className="header pt-0"></div>
            {/* ----------- Logout Confirmation Popup ---------- */}
              <Modal size="sm" show={showConfirmPopup} onHide={() => setShowConfirmPopup(false)} >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                      Logout Confirmation
                    </Modal.Title>
                    <span onClick={()=> setShowConfirmPopup(false)} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body className='bordr'>
                    <span>Are you sure you want to Logout?</span>
                </Modal.Body>
                <Modal.Footer className='bordr pad_13'>
                    <Button variant="primary" className="del_bttn"  onClick={Logout}>Yes</Button>
                    <Button variant="danger" className="del_bttn" onClick={() => setShowConfirmPopup(false)}>Cancel</Button>
                </Modal.Footer>
              </Modal>

        <ToastContainer/>
        </>
     );
}
 
const mapStateToProps = (state) => {
  return {
    userName: state.user.userName,
    imagePath: state.user.imagePath,
    userId: state.user.userId,
    newMentionCount: state.user.newMentionCount
  };
};
export default connect(mapStateToProps)(HeaderMenu);