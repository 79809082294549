
import axios from '../../axios'
import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { RiUserSettingsFill } from 'react-icons/ri';
import { FaTimes,FaAngleDown   } from 'react-icons/fa';
import { TiTick  } from 'react-icons/ti';
import SideBar from './AdminSideBar';
import HeaderMenu from './HeaderMenu';
import BreadCrumb from '../plugins/Breadcrumb';
import { Card,CardHeader, CardBody, CardTitle, Container, Row, Col, Button, Progress, Table } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const Settings = () => { 
    const navigate = useNavigate();

    return ( 
        <>
         <HeaderMenu/>
        <SideBar/>
        <div className="main-content ml_200">
        <BreadCrumb/>
        <Card className="shade h-500">
        <Container fluid>
          <div className="header-body  pt-3">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0" onClick={() => {navigate('/dashboard/settings/professional_types')}}>
                  <CardBody className='setngLst'>
                    <Row>
                           <Col className="col-auto pad_lf">
                        {/* <div className="icon icon-shape bg-danger text-white rounded-circle shadow"> */}
                          <RiUserSettingsFill style={{fontSize: '25px'}}/>
                        {/* </div> */}
                      </Col> 
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="fs_14px text-uppercase text-muted mb-0"
                        >
                          Manage Professional Types
                        </CardTitle>
                      </div>
                      {/* <Col className="col-auto pad_lf">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <RiUserSettingsFill />
                        </div>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        </Card>
        </div>
        <ToastContainer/>
        </>
    );
}

export default Settings;