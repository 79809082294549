import { Button, Card, Col, Row,Container} from "react-bootstrap"
import profile from '../../../assets/images/profile.jpg'
import React, { useState,useEffect,useRef} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../../../axios';
import { useDispatch,useSelector } from 'react-redux';
import HeaderMenu from '../headerMenu';
import SideBar from "../SideBar";
import { useNavigate } from 'react-router-dom';
import BreadCrumb from "../../plugins/Breadcrumb";
import loader from '../../../assets/images/loader.gif';
import { connect } from 'react-redux';
import {followId} from '../../../Redux/Actions/userAction';
import StarRating from './StarRating';
import { IoIosArrowDown } from "react-icons/io";
import { OverlayTrigger, Overlay, Popover } from 'react-bootstrap';
import { FiUsers,FiUser } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import { PiPhoneFill } from "react-icons/pi";
import { GrMail } from "react-icons/gr";
import Horizontalbar from '../../plugins/Horizontalbar';

const FollowingTab = (props) => {
    const [loading, setLoading] = useState(false);
    const [followingList, setFollowingList] = useState([]);
    const [showPopover, setShowPopover] = useState(false);
    const navigate = useNavigate();
    const targetRef = useRef(null);
    const targetRefs = {};
    const [activeCard, setActiveCard] = useState(null);
    const [globalRating, setGlobalRating] = useState([]); 
    useEffect(() => {
        FollowingList();
    }, [props]);

   
    const handleReviewClick = (name, userId,img) => {
        navigate('/dashboard/following/postreview', { state: { name, userId ,img} });
      };

    const FollowingList = () =>{
        if(props.userId){
            setLoading(true)
            axios.get(process.env.REACT_APP_BASEURL +'/followers/following/view/'+ props.userId)
            .then(response => {
                setFollowingList(response.data.recordInfo);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false)
                console.log(error);
                toast.error({
                    title: 'Error Occured',
                    position: 'topRight'
                });
            });
        }    
    }
    const handleArrowClick = (rating) => {
        const starResponse = rating;
        const starData = starResponse[0];
                const labelValueMap = {};
                for (const label in starData) {
                  labelValueMap[label] = parseFloat(starData[label]);
                }
                const sortedLabels = Object.keys(labelValueMap).sort((a, b) => {
                  const numA = parseInt(a.split(' ')[0]);
                  const numB = parseInt(b.split(' ')[0]);
                  return numB - numA;
                });
                const result = sortedLabels.map((label) => ({
                  label,
                  value: labelValueMap[label],
                }));
                setGlobalRating(result);
      }
    const handleButtonClick = (key) => {
        setActiveCard(key);
    }
      const handleOverlayClose = (key) => {
        setActiveCard(null);

      }
    
    return ( 
        <>
        <HeaderMenu/>
        <div className="">
        <SideBar/>
        <div className="main-content ml_200">
            <BreadCrumb/>
            <Card className="shade h-500">
                <Container>
                    <div className="postedReviews">
                        <h5>tiipstrs I am following</h5>
                    </div>
                    <Row className="pt-2 m-2">
                        {followingList.length>0 ? followingList.map((list) =>(
                        <Col sm={12} md={4} lg={3} xl={2}  className="user_card">
                            <Card className="usr_crd_bdr mb-10">
                                <div className="user text-center d-flex justify-content-center mt-2">
                                    <div className="follow_profile">
                                        <img src={list.imagePath ? list.imagePath : profile} className="rounded-circle" width="60" height="60"/>
                                    </div>
                                </div>
                                <div className="mt-2 text-center">
                                <span className="fol_name c_pointer" onClick={() => {navigate('/dashboard/following/view', { state: { userIdFollow: list.id } });}}><h6 className="mb-0">{list.name}</h6></span>
                                <div className="d-flex justify-content-center">
                                    <StarRating isReview={true} rating={list.averageReviewCount} disabled={true}/><span className="d-flex align-items-center total_fnt">({list.totalReviewCount})</span>
                                    <OverlayTrigger
                                    trigger="click"
                                    key={list.id}
                                    placement="bottom"
                                    overlay={
                                        <Popover id={`popover-${list.id}`} className='pop_rating'>
                                        <div className="drp_rating">
                                            <StarRating rating={list.averageReviewCount} disabled={true} />
                                            <span>{list.averageReviewCount} out of 5</span>
                                        </div>
                                        {globalRating.map((item, index) => ( <Row>
                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0"><span className="prog_span">{item.label}</span> </Col>
                                            <Col sm={12} md={8} lg={8} xl={7} className="p-0"><Horizontalbar dataSet={item.value}/></Col>
                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0 prog_span" style={{marginLeft: '10px'}}><span>{item.value}{item.value > 1 ? <FiUsers style={{marginLeft: '6px'}}/>: <FiUser style={{marginLeft: '6px'}}/>}</span></Col>
                                            </Row>))}
                                        </Popover>
                                    }
                                    rootClose
                                    >
                                    <span className="d-flex align-items-center" onClick={() => handleArrowClick(list.starRating)}>
                                        <IoIosArrowDown />
                                    </span>
                                    </OverlayTrigger>                                
                                </div>
                                <div style={{ overflow: 'visible' }} className="card_txt text-center fnt_11 mail_icn d-flex justify-content-center">
                                <span className="d-flex">
                                    <GrMail style={{ marginTop: '3px' }} />
                                    &nbsp;
                                    {list.email ? (
                                        list.isPublic ? (
                                        <span title={list.email}>
                                            {list.email.length > 19 ? `${list.email.slice(0, 19)}...` : list.email}
                                        </span>
                                        ) : (
                                        list.email.slice(0, 4) + 'x'.repeat(Math.max(0, list.email.length - 11))
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </span>
                                </div>
                               
                                <div className="card_txt text-center fnt_11">
                                    {list.phone ? <PiPhoneFill /> : ''}
                                    &nbsp;
                                    {list.phone ? (
                                        list.isPublic ? (
                                            list.phone
                                        ) : (
                                            'x'.repeat(Math.max(0, list.phone.length - 4)) + list.phone.slice(-4)
                                        )
                                    ) : '-'}
                                </div>
                                <div className="card_txt text-center fnt_11">{(list.location)?<HiLocationMarker/> :''}&nbsp;{list.location ? list.location : '-'} </div>
                                    <div className="m-2 btn_wd"><button className="btn btn-primary w-100 rev_btn" onClick={() => handleReviewClick(list.name, list.id,list.imagePath)}>Review</button></div>
                                </div>
                            </Card>
                        </Col>
                        )):(loading ? (
                            <div className="text-center"><img className="loader-image" src={loader} alt="Loading..." /></div>
                        ) :  (
                            <div  className="row col-md-12 d-align mx-0">
                                <div className="no-data font-weight-600 w-100 text-center fz_14">Sorry, there is no data to be displayed</div>
                            </div>))} 
                        </Row>
                    </Container>
                </Card>  
                </div>
            </div>  
            <ToastContainer/>
        </>
     );
}
const mapStateToProps = (state) => {
    return {
      userId: state.user.userId
    };
  };
export default connect(mapStateToProps)(FollowingTab);