import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import { useState,useEffect} from 'react';
import axios from '../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../layout/header';
import "../Register/register.css";
import "../ProfileUpdate/Profile.css";
import PhoneControl from "../CustomPhone/PhoneControl";
import profile from '../../assets/images/profile.jpg';
import {TiCamera} from 'react-icons/ti';
import loader from '../../assets/images/loader.gif';
export function Profile() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [phone, setPhone] = useState("");
    const [imagePath, setImagePath] = useState([]);
    const [imagePreview, setImagePreview] = useState("");
    const [phoneValidation,setPhoneValidation] = useState("");
    const [config, setConfig] = useState([]);
    
  const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      fetch('/config.json')
            .then((res) => res.json())
            .then((res) => {setConfig(res);
            });
    }, []);
    const phoneChange=(value)=> {
      const result = value.split(' ');
      const ph=result[1];
      if(value != '' && ph.length === 14){
        setPhoneValidation('');
      }
      else{
        setPhoneValidation("Phone No is required");
      }
      setPhone(value);
    }
    const phoneValidate=()=>{
      const result = phone.split(' ');
      const ph=result[1];
      if(phone != '' && ph.length === 14){
        setPhoneValidation('');
      }
    else{
      setPhoneValidation("Phone No is required");
    }
    }
    const createImage = (file)=>{
      var image = new Image();
      var reader = new FileReader();
      reader.onload = (e) => {
        setImagePath(e.target.result);
        console.log("ImagePatgaa",e.target.result)
      };
      reader.readAsDataURL(file);
    }
    const handleImageChange = (event) => {
      let allowedfiletypes=['jpg','svg','jpeg','png','bmp','gif','jfif']
      if(event.target.files.length>0){
        let ext = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1);
        const isnotFound = allowedfiletypes.some(element => {
        if (element === ext) { return true;}return false;
        });
        if(isnotFound){
          console.log("isnotfound")
          console.log("dhushgu",event.target.files[0])
          setImagePreview(URL.createObjectURL(event.target.files[0]))
          setImagePath(event.target.files[0])
        }
        else{
          console.log("isfound")
          toast.error(config.FileFormatErr, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true
          });
        }
      }
    }
    const uploadImage = (id)=>{
      console.log("id",imagePath)
        const myNewFile = new File([imagePath], imagePath.name.replace(/\s/g, ''), {type: imagePath.type});
       createImage(myNewFile);
       let formData = new FormData();
       formData.append('file', myNewFile);
       formData.append('id', id);
        axios.post(process.env.REACT_APP_BASEURL +'/user/upload/photo',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                ).then(response =>{
                  if(response.data.status=="OK"){
                    setLoading(false)
                    toast.success('Success', {
                      theme: 'colored',
                      position: 'top-right',
                      autoClose: 1500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      onClose: () => {
                    setTimeout(() => {
                    navigate('/login')
                    }, 2000);
                    },
                    });
                  }
                  else if(response.data.status=="EXPECTATION_FAILED"){
                    setLoading(false)
                  }
                })
                .catch(function(error) {
                  console.log(error)
                });
              
          };
    const onSubmit = (data) => {
       if(!phoneValidation){
       let userdet = JSON.parse(localStorage.getItem('userdetail'))
       setLoading(true);
       let userData = {'firstName':data.firstName,'lastName':data.lastName,'city':data.city,'state':data.state,'birthYear':data.year,'phone':phone,'imageName':data.imageName,'imagePath':'','email':userdet.email,'password':userdet.password,'activated':1};
       console.log("safaf",userData)
       axios.post(process.env.REACT_APP_BASEURL +'/user/add', userData)
       .then(response => {
        if(response.data.message == "User already exists."){
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
         }
        else{
        if(response.data.id){
          if(imagePath.name){
            uploadImage(response.data.id)
          }
            else{
              setLoading(false);
              toast.success('Success', {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              onClose: () => {
            setTimeout(() => {
            navigate('/login')
            }, 2000);
            },
            });
          }
         }
       }
       })
       .catch(error => {
       console.log(error);
       toast.error({
       title: 'Error Occured',
       position: 'topRight'
       });
       });
       }
      }
    return (
      <>
      <div className="">
      {loading ?
              <div className="pro-loader-center"><img className="loader-image" src={loader} alt="Loading..." /></div>:''}
      <Col lg="12" md="12" sm="12" xl="12">
        <Row>
        <Col lg="5" md="5" sm="12" xl="5" className='log_bg log_text'>
            <div className="mt-4  mb-3">
              <h5 className='fz_18'>See what they’re saying…</h5>
           </div>
           <div className='brand_logo'></div>
          <div className='tiipstr_logo'></div>
          </Col>
          <Col  lg="7" md="7" sm="12" xl="7" style={{backgroundColor:'white'}}>
            <Header/>
              <div className="d-flex justify-content-center align-items-center pro-bg-content">
              <Col lg="7" md="7" sm="12" xl="5">
                  <Form  onSubmit={handleSubmit(onSubmit,phoneValidate)} >
                    <div className="profile-img"><img style={{width: '70px',height: '70px'}} src={imagePreview?imagePreview:profile} alt=''/>
                              <label className="upload-icon upicon_sty"><TiCamera className="proUploadFont"/><input id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" style={{width: '0px',height: '0px',overflow: 'hidden'}} onChange={handleImageChange}/></label>
                    </div>
                    <label className="mb-0 fz_13">First Name</label>
                    <Form.Group className="pro_form form-group inputBox"> 
                        <Form.Control type="text"{...register("firstName", { required: true,validate: (value) => { return !!value.trim()}})} className={errors?.firstName?.type === "required" || errors?.firstName?.type === "validate"  ? "formControl fz_13 error" : "formControl fz_13"} placeholder="first name"/>
                    </Form.Group >
                    <label className="mb-0 fz_13">Last Name</label>
                    <Form.Group className="pro_form form-group inputBox">
                        <Form.Control  type="text"{...register("lastName", { required: true,validate: (value) => { return !!value.trim()}})} className={errors?.lastName?.type === "required" || errors?.lastName?.type === "validate"   ? "formControl fz_13 error" : "formControl fz_13"} placeholder="last name"/>
                    </Form.Group>
                    <label className="mb-0 fz_13">Birth Year</label>
                    <Form.Group className="pro_form form-group inputBox" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}>
                        <Form.Control type="text" maxlength="4" {...register("year", { required: true,validate: (value) => { return !!value.trim() && (value.length==4 && (value>=1900 && value < new Date().getFullYear()))}})}  className={errors?.year?.type === "required" || errors?.year?.type === "validate" ? "formControl fz_13 error" : "formControl fz_13"} placeholder="birth year"/>
                    </Form.Group>
                    <label className="mb-0 fz_13">Phone Number</label>
                    <PhoneControl phoneNo={phone} isProfile={false} mandatory={true} mobileVerify={true} onPhoneChange={phoneChange} phoneValidation={phoneValidation} />
                    <label className="mb-0 fz_13">City</label>
                    <Form.Group className="pro_form form-group inputBox">
                        <Form.Control type="text"{...register("city", { required: true,validate: (value) => { return !!value.trim()}})} className={errors?.city?.type === "required"  || errors?.city?.type === "validate" ? "formControl fz_13 error" : "formControl fz_13"} placeholder="city"/>
                    </Form.Group> 
                    <label className="mb-0 fz_13">State</label>
                    <Form.Group className="pro_form form-group inputBox">
                        <Form.Control type="text"{...register("state", { required: true,validate: (value) => { return !!value.trim()}})} className={errors?.state?.type === "required"  || errors?.state?.type === "validate" ? "formControl fz_13 error" : "formControl fz_13"} placeholder="state"/>
                    </Form.Group>
                    <a href="/terms-of-service" style={{textDecoration:'none'}}><div style={{textAlign:'center',fontSize:'13px',color:'rgb(2 5 211)',fontFamily: 'arial'}}>tiipstr terms of service</div></a>
                      <a href="/privacy-policy" style={{textDecoration:'none'}}><div style={{textAlign:'center',fontSize:'13px',color:'rgb(2 5 211)',fontFamily: 'arial'}}>tiipstr privacy policy</div></a>
                      <div style={{textAlign:'center'}}>
                          <Button type="submit" className="btn btn-primary next-btn">Sign Up</Button>
                      </div>
                  </Form>
                </Col>
              </div>
          </Col>
        </Row>
        </Col>
      </div>
      <ToastContainer />
      </>
    )
}

export default Profile;