import Inbox from '../inbox/index';
import HeaderMenu from '../headerMenu';
import SideBar from "../SideBar";
import BreadCrumb from "../../plugins/Breadcrumb";
const InboxTab = () => {
    return ( 
        <>
        <HeaderMenu/>
        <div className="">
        <SideBar/>
        <div className="ml_200">
            <Inbox/>
        </div>
        </div>
        </>
     );
}
 
export default InboxTab;