import { Card,Row } from "react-bootstrap";
import StarRating from './StarRating';
import fileImg from '../../../assets/images/file.jpg'
import moment from "moment";
import HeaderMenu from '../headerMenu';
import SideBar from "../SideBar";
import { useLocation } from 'react-router-dom';
import BreadCrumb from "../../plugins/Breadcrumb";
import {useState } from "react";
const DetailReview = (props) => {
  const location = useLocation();
  const { review } = location.state;
  const checkExt=(file)=>{
    let allowedfiletypes=['jpg','svg','jpeg','png','bmp','gif','jfif']
    if(allowedfiletypes.some(ext => ext == file.fileName.substring(file.fileName.lastIndexOf('.') + 1))){
      return true;
    }
    return false;
  }
   return ( 
        <>
        <HeaderMenu/>
        <div className="">
          <SideBar/>
          <div className="main-content ml_200">
           <BreadCrumb/> 
            <Card className="shadow">
              <div className="container review-container">
                <div className="row d-flex">
                  <div className="col-5 col-md-5 col-lg-2 reviewPage">Reviewer's Name</div>
                  <div className="col-7 col-md-6 col-lg-3 reviewPage">{review.reviewerName}</div>
                </div>
                <div className="row d-flex">
                  <div className="col-5 col-md-5 col-lg-2 reviewPage">Reviewed's Name</div>
                  <div className="col-7 col-md-6 col-lg-3 reviewPage">{review.userName}</div>
                </div>
                <div className="row d-flex">
                  <div className="col-5 col-md-5 col-lg-2 reviewPage">tiipstr since</div>
                  <div className="col-7 col-md-6 col-lg-3 reviewPage">
                  {moment(review.knownSince).format('DD-MMM-YYYY')}
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-5 col-md-5 col-lg-2 reviewPage">Role</div>
                  <div className="col-7 col-md-6 col-lg-3 reviewPage">{review.userRole}</div>
                </div>
                <div className="row d-flex">
                  <div className="col-5 col-md-5 col-lg-2 reviewPage sty_flx">Review</div>
                  <div className="col-7 col-md-6 col-lg-3 reviewPage"><StarRating rating={review.rating} disabled={true} /></div>
                </div>
                <div className="row d-flex">
                  <div className="col-5 col-md-5 col-lg-2 reviewPage">Comment</div>
                  <div className="col-7 col-md-6 col-lg-3 reviewPage">{review.comment}</div>
                </div>
                <div className="row d-flex">
               {review.reviewAttachment && <div className="col-5 col-md-5 col-lg-2 reviewPage">Attached Files</div>}
                <div className="col-7 col-md-7 col-lg-10 reviewPage">
                <Row>
                {review.reviewAttachment && review.reviewAttachment.map((file) =>(<div className="col-2 col-md-2 col-lg-2">
                  {checkExt(file)?<img width="100" height="100" src={file.filePath?file.filePath:''} className="br_5px" />:<img width="100" height="100" src={fileImg} className="br_5px" />}
                    <div className="review_imgview">
                      <a  href={file.filePath} className="color-black" style={{fontSize:'13px'}} target="_blank"> {file.fileName}</a>
                    </div>
                    </div>
                 ))}
                </Row>
                <div className='row review_center'>
                  <div className='col-7 col-md-6 col-lg-12 d-flex justify-content-center reviewPage'> 
                  </div>
                
                </div>
                </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        </>
     );
}
 
export default DetailReview;