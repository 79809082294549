
import axios from '../../axios'
import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { TiTick  } from 'react-icons/ti';
import SideBar from './SupportSideBar';
import HeaderMenu from './HeaderMenu';
import BreadCrumb from '../plugins/Breadcrumb';
import { Card,CardHeader, CardBody, CardTitle, Container, Row, Col, Button, Progress, Table } from "reactstrap";
import {FaEye} from 'react-icons/fa';
import { Modal } from "react-bootstrap";
import {AiOutlineClose} from 'react-icons/ai';
import Pagination from "react-bootstrap/Pagination";
import {BiChevronDownCircle,BiChevronUpCircle} from 'react-icons/bi';
import profile from '../../assets/images/profile.jpg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCalendar,AiOutlineArrowDown,AiOutlineArrowUp } from 'react-icons/ai';

import loader from '../../assets/images/loader.gif';
const Report = () => { 
    const [reports,setReports] = useState([]);
    const [reportId,setReportId] = useState(null);   
    const [limit,setLimit] = useState('10'); 
    const [status,setStatus] = useState(''); 
    const [repOn,setRepOn] = useState(); 
    const [repBy,setRepBy] = useState(''); 
    const [pageNo,setPageNo] = useState('1');  
    const [reportedContent,setReportedContent] = useState("");
    const [reviewComment,setReviewComment] = useState("");
    const [showContent, setShowContent] = useState(false);
    const [showBP, setShowBP] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showSearch, setShowSearch] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState('10');
    const [totalPages, setTotalPages] = useState('');
    const indexOfLastItem = currentPage * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentReports = reports.slice(indexOfFirstItem, indexOfLastItem);
    const handleClose = () => setShowContent(false);
    const handleClos = () => setShowBP(false);
    const [userList,setUserList] = useState(''); 
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortOrderbyDate, setSortOrderbyDate] = useState('asc');
    const [sortOrderbyName, setSortOrderbyName] = useState('asc');
    const [sortBy,setSortBy] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetCounter, setResetCounter] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
const [actionStatus, setActionStatus] = useState("");

    const handlePageChange = (page) => {
      setCurrentPage(page);
      setPageNo(page);
    };
    const handleLimitChange = (newLimit) => {
      setRowsPerPage(newLimit);
      setCurrentPage(1);
    };
    const handleStatusChange = (status) => {
      setStatus(status);
    };
    const handleRepOnChange = (date) => {
      setRepOn(date.target.value);
    };
    const handleRepByChange = (name) => {
      setRepBy(name);
    };
    useEffect(()=>{
      loadReports();
      userLists();
    }, [currentPage, rowsPerPage,sortOrder,sortBy,sortOrderbyDate,sortOrderbyName,resetCounter]);
    const loadReports = () =>{
      setLoading(true);
      let params={};
      if(sortBy == 'createdAt'){
         params={
          page: currentPage,
          size: rowsPerPage,
          sortBy,
          sortOrder:sortOrderbyDate,
          status,
          createdAt: repOn
        } 
      }else if(sortBy == 'reportedName'){
        params={
          page: currentPage,
          size: rowsPerPage,
          sortBy,
          sortOrder:sortOrderbyName,
          status,
          createdAt: repOn
        }
      } else{
        params={
          page: currentPage,
          size: rowsPerPage,
          sortBy,
          sortOrder:sortOrder,
          status,
          createdAt: repOn
        }
      } 
      axios.get(process.env.REACT_APP_BASEURL + '/reports/paginationlist' , {
        params: params})
          .then(response => {
            setLoading(false);
            const currentPageStartIndex = (currentPage - 1) * rowsPerPage + 1;
            setReports(
              response.data.recordInfo.content.map((report, index) => ({
                ...report,
                serialNumber: currentPageStartIndex + index,
              }))
            );
              setRowsPerPage(response.data.recordInfo.pageable.pageSize);
              setTotalPages(response.data.recordInfo.totalPages);
              console.log("totalPages",response.data.recordInfo.totalPages);
  
          })
          .catch(error => {
              console.log(error);
              setLoading(false);
              toast.error({
                  title: 'Error Occured',
                  position: 'topRight'
              });
          });
        
  }
  const viewContent = (id) =>{
  
    axios.get(process.env.REACT_APP_BASEURL +'/reports/view/' + id)
    .then(response => {
      setReportedContent(response.data.recordInfo[0].additionalInfo);
      setReviewComment(response.data.recordInfo[0].review);
    })
    .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
  
}
const showReportInfo = (reportId) => {
  setReportId(reportId); 
  viewContent(reportId)
  setShowContent(true);
}
const showBlRPb = (reportId) => {
  setReportId(reportId); 
  viewContent(reportId)
  setShowBP(true);
}
const showConfirmationDialog = (action) => {
  setShowConfirmation(true);
  setActionStatus(action);
};
const handleCancel = () => {
  setShowConfirmation(false);
};
const handleConfirmationYes = () => {
  if (actionStatus === "block") {
    blockOrPublish('blocked');
  } else if (actionStatus === "publish") {
    blockOrPublish('published');
  }
  setShowConfirmation(false);
};
const blockOrPublish = (newStatus)=>{
if(reportId && newStatus){
  axios.put(process.env.REACT_APP_BASEURL + `/reports/${reportId}/update-review-status?reportStatus=${newStatus}`)
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowContent(false);
        loadReports();
      } else {
        console.error('Status update failed');
      }
    })
    .catch(error => {
      console.error(error);
    });
}
}
const toggleSearch = () =>{
  setShowSearch(!showSearch)
}
const Reset = () => {
  setCurrentPage(1)
  setRepOn('');
  setRepBy('');
  setStatus('');
  setSortBy('');
  setResetCounter((prevCounter) => prevCounter + 1);

}
const Find = () => {
  setCurrentPage(1);
  setSortBy('');
  loadReports();
}
const userLists = () =>{
  axios.get(process.env.REACT_APP_BASEURL +'/lookup/users')
  .then(response => {
      setUserList(response.data.recordInfo);
  })
  .catch(error => {
      console.log(error);
  });  
}
const handleSortClick = (reviewedName) => {
  setCurrentPage(1);
  const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
  setSortOrder(newSortOrder);
  setSortBy(reviewedName);
  loadReports();    
};
const handleSortbyDate = (createdAt) => {
  setCurrentPage(1);
  const newSortOrder = sortOrderbyDate === 'asc' ? 'desc' : 'asc';
  setSortOrderbyDate(newSortOrder);
  setSortBy(createdAt);
  loadReports();    
};
const handleSortbyName = (reportedName) => {
  setCurrentPage(1);
  const newSortOrder = sortOrderbyName === 'asc' ? 'desc' : 'asc';
  setSortOrderbyName(newSortOrder);
  setSortBy(reportedName);
  loadReports();    
};
    return ( 
        <>
         <HeaderMenu/>
        <div className=" ">
        {/* <div className=" bg-content"> */}

        <SideBar/>
        <div className="main-content ml_200">
        <BreadCrumb/>
        {loading ?
              <div className="pro-loader-center"><img className="loader-image" src={loader} alt="Loading..." /></div>:''}
        <div className='search-section'>
        <div className='d-flex justify-content-between'>
          <div className="srch_txt">Search</div>
          <div onClick={toggleSearch}>{showSearch ? <BiChevronUpCircle /> : <BiChevronDownCircle />}</div>
        </div>
        {showSearch&&<div className="">
          <Row className='mt-3'>
            {false && <Col sm={3} lg={3}>
              <div className="search-input-group">
                <label htmlFor="reportedBy">Reported by:</label>
                <select className='statusdrp plcehldr' value={repBy} onChange={(e) => handleRepByChange(e.target.value)}>
                  <option value="">Reported by</option>
                  {userList&& userList.map((user) =>(<option value={user.id}>{user.name}</option>))}
                </select>
              </div>
            </Col>}
            <Col sm={3} lg={3}>
            <div className="search-input-group">
            <label htmlFor="reportedOn">Reported on:</label>
            <input type="date" placeholder="yyyy-mm-dd" max={new Date().toISOString().split('T')[0]}  className='plcehldr c_pointer' value={repOn} onChange={(date) => handleRepOnChange(date)}></input>
            </div>
            </Col>
            <Col sm={3} lg={3}>
              <div className="search-input-group">
                <label htmlFor="status">Status:</label>
                <select className='statusdrp plcehldr' value={status} onChange={(e) => handleStatusChange(e.target.value)}>
                  <option value="">Status</option>
                  <option value="pending">pending</option>
                  <option value="blocked">blocked</option>
                  <option value="published">published</option>
                </select>
              </div>
            </Col>
            <Col sm={3} lg={3} className='d-flex align-items-end'>
              <div className="d-flex gp_10 p-0 mt_8px">
                <Button className='rest_fnd_bttn rest_btn' onClick={Reset}>Reset</Button>
                <Button className='rest_fnd_bttn find_btn' onClick={Find}>Find</Button>
              </div>
            </Col>
          </Row>
        </div>}
        </div>
       
        {/* <Card className="shade h-198 position-relative"> */}
        <Card className="shade h-198 ">
        <Table className="align-items-center table-flush brd_solid" responsive>
                <thead className="thead-clr">
                  <tr>
                    <th scope="col">Sl.No</th>
                    <th scope="col">Reviewer Name 
                    <span className="c_pointer" onClick={() =>handleSortClick('reviewedName')}>{ (
                sortOrder  === 'asc' ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />
              )}</span>
              </th>
                    <th scope="col">Reported By 
                    <span className="c_pointer" onClick={() =>handleSortbyName('reportedName')}>{ (
                sortOrderbyName  === 'asc' ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />
              )}</span>
              </th>
                    <th scope="col">Reported on 
                   <span className="c_pointer" onClick={() =>handleSortbyDate('createdAt')}>{ (
                sortOrderbyDate  === 'asc' ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />
              )}</span>
              </th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
      {!reports || reports.length === 0 ? (
        <tr>
          <td colSpan="6" style={{ textAlign: "center" }}>
            No Data Found
          </td>
        </tr>
      ) : (
        reports.map((report, index) => (
          <tr key={report.id}>
            <td>{report.serialNumber}</td>
            {/* <td ><span title={report.review}>{report.review.length > 7 ? report.review.slice(0, 10) + "..." : report.review}</span></td> */}
            <td>
              <div className='d-inline-flex gp_10'>
              <span>      
                <img className='rev_img' src={report.reviewerImage ? report.reviewerImage : profile}/>
                </span>
                <span className='d-flex align-items-center'>{report.reviewerName}</span>
                </div>
              </td>
              <td>
              <div className='d-inline-flex gp_10'>
              <span>      
                <img className='rev_img' src={report.reporterImage ? report.reporterImage : profile}/>
                </span>
                <span className='d-flex align-items-center'>{report.reporterName}</span>
                </div>
              </td>
              <td>{new Date(report.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, '-')}</td>
            <td><span
             className={report.status == 'published' ? 'status_pub' : (report.status == 'blocked' ? 'status_blk': 'status_pen')} 
             style={{ width: '15px' }}>{report.status}</span></td>

            <td>
              {(report.status == 'blocked' || report.status == 'published') ? <FaEye
                className='c_pointer searchIcon'
                title="View Reported Info"
                onClick={() => showBlRPb(report.id)}
              />: <FaEye
              className='c_pointer searchIcon'
              title="View Reported Info"
              onClick={() => showReportInfo(report.id)}
            /> }
            </td>
          </tr>
        ))
      )}
    </tbody>
    </Table>
    {/* <div className={`d-flex justify-content-between align-items-center border_att pag_pad bck_pag ${showSearch ? 'mb_123' : 'mb_53'}`}> */}
    {/* pag_bttom class to place the pagination at the bottom */}
    {reports.length != 0 ?(<div className=' d-flex justify-content-between align-items-center border_att pag_pad bck_pag'>
          <div className='select-container'>
              <span className='select-label'>Rows </span>
              <select className='select-element' value={rowsPerPage} onChange={(e) => handleLimitChange(e.target.value)}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
          </div>
          <Pagination className='mb-0'>
              <Pagination.Prev
                onClick={() => {
                  if (currentPage > 1) {
                    handlePageChange(currentPage - 1);
                  }
                }}
              />
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => {
                  if (currentPage < totalPages) {
                    handlePageChange(currentPage + 1);
                  }
                }}
              />
          </Pagination>
      </div>):''}
              </Card>
        </div>
        </div>
        <ToastContainer/>
                     {/* ----------- block/publish Popup ---------- */}
              <Modal size="sm" show={showContent} onHide={() => setShowContent(false)} >
              <Modal.Title id="example-custom-modal-styling-title" className='mod_head'>
                    <span onClick={handleClose} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Title>
                <Modal.Body className='pad_lr'>
                  <div className='mb_17'>
                  <div className='fnt_styl'>Review</div>
                  <div className='layot_mod'>{(reviewComment?reviewComment:"No Info found")}</div>
                  </div>
                  <div>
                  <div className='fnt_styl'>Reported Info</div>
                  <div className='layot_mod'>{(reportedContent?reportedContent:"No Info found")}</div>
                  </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center gp_10'>
                    <Button variant="danger" className="block_bttn" onClick={() => showConfirmationDialog("block")}>Block</Button>
                    <Button variant="primary" className="publish_bttn" onClick={() => showConfirmationDialog("publish")}>Publish</Button>
                </Modal.Footer>
              </Modal>
                   {/* ----------- blockED/publishED Popup ---------- */}
                   <Modal size="sm" show={showBP} onHide={() => setShowBP(false)} >
                <Modal.Title className='mod_head'>
                    <span className='c_pointer' onClick={handleClos} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Title>
                <Modal.Body className='pad_lr'>
                  <div className='mb_17'>
                  <div className='fnt_styl'>Review</div>
                  <div className='layot_mod'>{(reviewComment?reviewComment:"No Info found")}</div>
                  </div>
                  <div className='mb_30'>
                  <div className='fnt_styl'>Reported Info</div>
                  <div className='layot_mod'>{(reportedContent?reportedContent:"No Info found")}</div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal show={showConfirmation} onHide={handleCancel}>
              <Modal.Body className='bdy_pad'>Are you sure you want to {actionStatus}?</Modal.Body>
              <Modal.Footer className='gp_10'>
                <Button variant="danger" className="ys_cncl_bttn cncl" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary" className="ys_cncl_bttn yes_btn" onClick={handleConfirmationYes}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
        </>
    );
}

export default Report;