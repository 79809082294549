import {Button,Card,FormGroup,Form,Container,Row,Col,} from "react-bootstrap";
import PhoneControl from "../../CustomPhone/PhoneControl";
import profile from "../../../assets/images/profile.jpg"
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import "../../ProfileUpdate/Profile.css"
import "../../../components/Register/register.css"
import { useForm} from "react-hook-form";
import { useState,useEffect,useRef} from 'react';
import {FaPlusCircle} from 'react-icons/fa';
import {FaCircleCheck} from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import {HiCheckCircle} from 'react-icons/hi';
import { AiOutlineClose } from "react-icons/ai";
import {MdPhoneAndroid,MdEmail,MdCancel} from 'react-icons/md';
import {TiCamera} from 'react-icons/ti';
import {BsExclamationCircle} from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../../../axios';
import HeaderMenu from "../HeaderMenu";
import SideBar from "../SupportSideBar";
import "../../../assets/css/ToggleSwitch.css";
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../../assets/images/loader.gif';
import {phoneNumber} from '../../../Redux/Actions/userAction';
const STATUS = {
  start: 1,
  default: 2
}
const Profile = (props) => {
  const [status, setStatus] = useState(STATUS.default);
  const intervalRef = useRef();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [year, setYear] = useState("");
  const [city, setCity] = useState("");
  const [accountType, setAccountType] = useState("");
  const [professionInfo, setprofessionInfo] = useState([]);
  const [businessInfo, setbusinessInfo] = useState([]);
  const [cmpyId, setcmpyId] = useState("");
  const [cmpyName, setcmpyName] = useState("");
  const [cmpyPhone, setcmpyPhone] = useState("");
  const [cmpyEmail, setcmpyEmail] = useState("");
  const [cmpyState, setcmpyState] = useState("");
  const [cmpyCity, setcmpyCity] = useState("");
  const [cmpyDescription, setcmpyDescription] = useState("");
  const [officePhone, setofficePhone] = useState("");
  const [officeAddress, setofficeAddress] = useState("");
  const [professionType, setprofessionType] = useState("");
  const [emptyErr, setemptyErr] = useState(false);
  const [professionId, setprofessionId] = useState("");
  const [mobileVerified, setmobileVerified] = useState(false);
  const [isEmailCodeSend, setIsEmailCodeSend] = useState(false);
  const [isMobileCodeSend, setIsMobileCodeSend] = useState(false);
  const [hideEdit,setHideEdit] = useState(true);
  const [mobilePopupShow, setMobilePopupShow] = useState(false);
  const [emailPopupShow, setEmailPopupShow] = useState(false);
  const [editBusinessInfo, setEditBusinessInfo] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [editedIdx, setEditedIdx] = useState('');
  const [verificationCode, setVerifcationCode] = useState('');
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [config, setConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPhoneChanged, setisPhoneChanged] = useState(false);
  const [emailVerified,setemailVerified] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const onToggle = () => setIsPublic(!isPublic);
  const dispatch = useDispatch();
  const [displayContent,setdisplayContent] = useState(false);
  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const [validation, setValidation] = useState({cmpyName:"",professionType:""});
  const [phoneValidation,setPhoneValidation] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  useEffect(() => {
    loadsprofileInfo();
    fetch('/config.json')
          .then((res) => res.json())
          .then((res) => {setConfig(res);
          });
  }, [props]);
  useEffect(()=>{
      if(status === STATUS.start){
        intervalRef.current = setInterval(() => {
          countDown()
        }, 1000);
      } 
      return () => {
        clearInterval(intervalRef.current)
      };
  },[minutes, seconds, status])
  const start = () => {
    setMinutes(5);
    setSeconds(0);
    setStatus(STATUS.start);}
  const userId = useSelector(state => state.user.userId);
  const authToken = useSelector((state) => state.user.token); 
  const oldPhoneNo = useSelector((state) => state.user.phNumber); 
  const loadsprofileInfo=()=>{
    setdisplayContent(false);
    axios.get(process.env.REACT_APP_BASEURL +'/user/view/'+ props.userId)
    .then(response => {
      if(response.data.status == "OK"){
        let result = response.data.data;
        setFirstName(result.firstName)
        setPhone(result.phone)
        dispatch(phoneNumber(result.phone));
        setLastName(result.lastName)
        setEmail(result.email)
        setState(result.state)
        setYear(result.birthYear)
        setCity(result.city)
        setImagePath(result.imagePath)
        setbusinessInfo(result.businessList)
        setprofessionInfo(result.professionalList)
        setemailVerified(result.emailVerified)
        setmobileVerified(result.phoneVerified)
        setIsPublic(result.isPublic)
        // setIsPublic(result.isPublic)
        dispatch({
          type: 'SAVE_USER_DATA',
          payload: {
            token: authToken ,
            userId: result.id,
            userType: result.userType,
            userName: result.name,
            imagePath: (result.imagePath != null ? result.imagePath: '')
          },
        }); 
        setdisplayContent(true);
      }
    })
    .catch(error => {
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  function countDown(){
    if (seconds === 0) {
        if (minutes !== 0) {
          setSeconds(59);
          setMinutes(min => min - 1);
        } else {
          let mins = 0;
          let sec = 0;
          setSeconds(sec);
          setMinutes(mins);
        }
      } else {
        setSeconds(sec => sec - 1);
      }
  }

  const onOptionChange = e => {
    setAccountType(e.target.value)
  }
  const phoneChange=(value)=> {
    const result = value.split(' ');
    const ph=result[1];
    if(value != '' && ph.length === 14){
      setPhoneValidation('');
    }
    else{
      setPhoneValidation("Phone No is required");
    }
    setPhone(value)
    if(value == oldPhoneNo){
      setisPhoneChanged(false);
      setmobileVerified(true);
    }
    else{
      setisPhoneChanged(true);
      setmobileVerified(false);
    }
    }
  const mobileverifyset=(value)=> {
    setMobilePopupShow(value)
  }
  const onSubmit = () => {
    if(year < new Date().getFullYear() && year >=1900){
    if(firstName && city && state && year && lastName && (phone!='' && phone!='+1 undefined' && phone!='+91 undefined') && !phoneValidation){
    setLoading(true)
    let userInfo = {'firstName':firstName,'lastName':lastName,'email':email,'phone':phone,'state':state,
    'city':city,'birthYear':year,'imagePath':imagePath,'business':businessInfo,'professional':professionInfo,'isPublic':isPublic,'isPhoneModified':isPhoneChanged};
      axios.post(process.env.REACT_APP_BASEURL +'/user/edit/'+ props.userId, userInfo)
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        loadsprofileInfo();
        setLoading(false)
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false)
      }
    })
    .catch(error => {
      console.log(error);
      setLoading(false)
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  else{
  toast.error('Please fill out all required fields.', {
    theme: 'colored',
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  }
  }
  else{
    toast.error('Invalid Birth Year', {
      theme: 'colored',
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  }
  const editCancel=()=>{
    removeFill();
    setHideEdit(true);
  }
  const updateBusinessInfo=()=>{
    console.log("id",editedIdx)
    const sfd = businessInfo.map((obj,idx) => {
      if(idx == editedIdx){
      obj.companyName = cmpyName;
      obj.phone = cmpyPhone;
      obj.email = cmpyEmail;
      obj.city = cmpyCity;
      obj.state = cmpyState;
      }
      return obj;
     })
     setbusinessInfo(sfd)
  }
  const removeFill=()=>{
    setcmpyName("");
    setcmpyPhone("");
    setcmpyEmail("");
    setcmpyState("");
    setcmpyCity("");
    setcmpyDescription("");
  }
  const editBusiness=(value,idx)=>{
    setEditedIdx(idx)
    setHideEdit(false);
    setcmpyName(value.companyName);
    setcmpyPhone(value.phone);
    setcmpyEmail(value.email);
    setcmpyState(value.state);
    setcmpyCity(value.city);
  }
  const verifyMobileCode = ()=>{
    if(verificationCode){
    axios.post(process.env.REACT_APP_BASEURL +'/user/verify/verificationcode',{'id':props.userId,'type':'phone','verificationCode':verificationCode})
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        loadsprofileInfo();
        setVerifcationCode('');
        setMobilePopupShow(false)
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch(error => {
      toast.error(error.response.data.message,{
        theme: 'colored',
        position: 'top-right',
      });
  });
  }
  else{
    setemptyErr(true);
  }
  }
  const verifyEmailCode = ()=>{
    if(verificationCode){
      axios.post(process.env.REACT_APP_BASEURL +'/user/verify/verificationcode',{'id':props.userId,'type':'email','verificationCode':verificationCode})
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        loadsprofileInfo();
        setVerifcationCode('');
        setEmailPopupShow(false)
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch(error => {
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }else{
    setemptyErr(true);
  }
  }
  const addUserBusinessInfo = () => {
    if (!cmpyName.trim()) {
      validation.cmpyName = "Company name is required";
    } else {
      validation.cmpyName = "";
    }
      if(validation.cmpyName==''){
      const isFound = businessInfo.some(element => {
        if (element.companyName == cmpyName) {
          return true;
        }
        return false;
      });
      if(!isFound){
        let businessInfo = [];
        setbusinessInfo(businessInfo => [...businessInfo, {'companyName':cmpyName,'phone':cmpyPhone,'email':cmpyEmail,'city':cmpyCity,'state':cmpyState,'description':cmpyDescription}]);
        removeFill();
        console.log("SAdsa",hideEdit)
      }
      else{
        toast.error('Company Name already exists', {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      }
  else{
    toast.error('Please fill out required fields.', {
      theme: 'colored',
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  }
  const addUserProfessionInfo = () => {
    if (!professionType.trim()) {
      validation.professionType = "profession type is required";
    } else {
      validation.professionType = "";
    }
    if(validation.professionType==''){
      var result = [{'professionType':professionType,'phone':officePhone,'address':officeAddress}, ...professionInfo]
      setprofessionInfo(result)
    }
    else{
      toast.error('Please fill out required fields.', {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const verifyMobile = () =>{
    axios.post(process.env.REACT_APP_BASEURL +'/user/send/verificationcode',{'id':props.userId,'type':'phone'})
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        start();
        setIsMobileCodeSend(true)
        setisPhoneChanged(false);
        setVerifcationCode('');
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch(error => {
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  const verifyEmail = () =>{
    // setEmailPopupShow(false)
    axios.post(process.env.REACT_APP_BASEURL +'/user/send/verificationcode',{'id':props.userId,'type':'email'})
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        start();
        setIsEmailCodeSend(true)
        setVerifcationCode('')
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch(error => {
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  const createImage = (image)=>{
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePath(e.target.result);
    };
    reader.readAsDataURL(image);
  }
  const uploadImage = (event) => {
    if(event.target.files[0]){
    if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(event.target.files[0].name)) {
        toast.error(config.FileFormatErr, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
    }  
    setLoading(true)
    console.log("event.target.files[0].name",event.target.files[0].name)
    const myNewFile = new File([event.target.files[0]], event.target.files[0].name.replace(/\s/g, ''), {type: event.target.files[0].type});
    console.log("event.target.files[0].name55",myNewFile.name)
    createImage(myNewFile);
    let formData = new FormData();
    formData.append('file', myNewFile);
    formData.append('id', userId);
    axios.post(process.env.REACT_APP_BASEURL +'/user/upload/photo',
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(response =>{
          if(response.data.status=="OK"){
            loadsprofileInfo();
            setLoading(false)
            toast.success(config.ImageUpSuc, {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
          else if(response.data.status=="EXPECTATION_FAILED"){
            setLoading(false)
            toast.error(response.data.message, {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(function(error) {
            console.log(error)
            setLoading(false)
        });
      }
  };
  const deleteImage = () =>{
    if(imagePath){
    setLoading(true)
    axios.delete(process.env.REACT_APP_BASEURL +'/user/image/delete/'+userId
        ).then(response =>{
          if(response.data.status=="OK"){
            loadsprofileInfo();
            setLoading(false)
            toast.success(response.data.message, {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            setDeletePopupShow(false);
          }
          else if(response.data.status=="EXPECTATION_FAILED"){
            setLoading(false)
            toast.error(response.data.message, {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDeletePopupShow(false);
          }
        })
        .catch(function(error) {
          setLoading(false)
          setDeletePopupShow(false);
          console.log(error)
        });
      }
      else{
        toast.warning(config.DelImageErr, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  }
  return (
    <>
    <HeaderMenu/>
        <div className="">
            <SideBar/>
            {displayContent?<div className="main-content ml_200">
            {loading ?
              <div className="pro-loader-center"><img className="loader-image" src={loader} alt="Loading..." /></div>:''}
            {/* <BreadCrumb/> */}
            <div
                className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                style={{
                  minHeight: "600px",
                  backgroundSize: "cover",
                  backgroundPosition: "center top",
                }}
              >
                <span className="mask" />
                <Container className="d-flex align-items-center" fluid>
                  <Row>
                    <Col lg="7" md="10">
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container className="mt--30 mb-4" fluid>
                <Row>
                  <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary profile_card">
                      <Card.Header className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="12">
                            <h3 className="mb-0 text-center fz-22 alert-link">Profile Information</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col lg="4" md="4" sm="12">
                          <Row className="justify-content-center">
                          <div className="card-profile-image">
                            <div className="imagePreview" style={{ backgroundImage: 'url(' + `${imagePath ? imagePath : profile}` + ')' }}></div>
                              <label className="upload-icon"><TiCamera className="uploadfont"/><input id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" style={{width: '0px',height: '0px',overflow: 'hidden'}} onChange={uploadImage}/></label>
                             <label className="delete-icon"><AiOutlineClose className="closefont" onClick={()=>setDeletePopupShow(true)} /></label>
                            </div>
                            </Row>
                            <Row className="justify-content-center"> 
                                <div className="text-center fz-22 alert-link">{firstName} {lastName}</div>
                                {/* <div className="text-center fz-12 font-weight-normal">followers:&nbsp;{}</div> */}
                            </Row>
                          </Col>
                          <Col lg="8" md="8" sm="12">
                          <Form>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="6">
                                <Form.Group className="form-group"> 
                                <Form.Label className="mb-1 profile_label">First Name</Form.Label>
                                  <Form.Control type="text" className={!firstName ? "form-control input_ctrl error" : "form-control input_ctrl"}  
                                  onChange={(e) => setFirstName(e.target.value)}  value={firstName} placeholder="first name"/>
                                  {!firstName && <p className="fz-13">First Name is required</p>}
                                </Form.Group >
                              </Col>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                <Form.Label className="mb-1 profile_label">Last Name</Form.Label>
                                  <Form.Control  type="text" className={!lastName ? "form-control input_ctrl error" : "form-control input_ctrl"}
                                  onChange={(e) => setLastName(e.target.value)}  value={lastName} placeholder="last name"/>
                                  {!lastName && <p className="fz-13">Last Name is required</p>}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                              {phone ?<PhoneControl phoneNo={phone} isProfile={true} mandatory={true} mobileVerify={true} PopupShow={mobileverifyset} isverified={mobileVerified} isPhoneChanged={isPhoneChanged} onPhoneChange={phoneChange}  phoneValidation={phoneValidation}/>:''} 
                              </Col>
                              <Col lg="6">
                              <Form.Group class="form-group required-field-block">
                                <Form.Label className="mb-1 profile_label">Email Address</Form.Label>
                                    <Form.Control type="email" className="form-control input_ctrl" value={email} disabled placeholder="email" name="email" maxlength="80"/>
                                    {email != '' && <span class="required-icon">
                                      {!emailVerified?<a href="#" onClick={()=>setEmailPopupShow(true)}>
                                        <BsExclamationCircle className={email != '' && emailVerified? "" : "text-danger"} style={{fontSize:'20px'}}/>
                                      </a>:<a href="#">
                                        <HiCheckCircle style={{fontSize:'20px',color:'green'}}/>
                                      </a>}</span>}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                            <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label className="mb-1 profile_label">City</Form.Label>
                                <Form.Control type="text" className={!city? "form-control input_ctrl error" : "form-control input_ctrl"} 
                                onChange={(e) => setCity(e.target.value)}  value={city} placeholder="city"/>
                                {!city && <p className="fz-13">City is required</p>}
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group className="form-group">
                              <Form.Label className="mb-1 profile_label">State</Form.Label>
                                <Form.Control type="text" className={!state? "form-control input_ctrl error" : "form-control input_ctrl"} 
                                onChange={(e) => setState(e.target.value)}  value={state} placeholder="state"/>
                                {!state && <p className="fz-13">State is required</p>}
                              </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                            <Col lg="6">
                              <Form.Group className="form-group" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}>
                              <Form.Label className="mb-1 profile_label">Birth Year</Form.Label>
                                <Form.Control type="text" className={!year? "form-control input_ctrl error" : "form-control input_ctrl"} 
                                onChange={(e) => setYear(e.target.value)}  value={year} placeholder="birth year" maxLength={4}/>
                                {!year && <p className="fz-13">Birth Year is required</p>}
                              </Form.Group>
                            </Col>
                            </Row>
                            <Row>
                               <div className="d-flex mt-2 toggle_gap">
                                  <label className="toggle-switch">
                                      <input type="checkbox" checked={isPublic} onChange={onToggle} />
                                      <span className="switch" />
                                  </label>
                                  <label className="font-weight-500 fz_14">{isPublic?'Public Account':'Private Account'}</label>
                                </div>
                            </Row>
                          </div>
                          {false && <div className="pl-lg-4 text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{paddingBottom: '15px'}}>
                                  <input
                                    type="radio"
                                    name="accountType" 
                                    value="Business"
                                    id="business" style={{marginBottom:'5px'}}
                                    checked={accountType === "Business"}
                                    onChange={onOptionChange}
                                  />
                                  <label style={{padding: '0px 1rem 0px 0.5rem'}} htmlFor="business">Business</label><input
                                    type="radio"
                                    name="accountType"
                                    value="Professional"
                                    id="professional" style={{marginBottom:'5px'}}
                                    checked={accountType === "Professional"}
                                    onChange={onOptionChange}
                                  />
                                  <label style={{padding: '0px 1rem 0px 0.5rem'}} htmlFor="professional">Professional</label></div>  
                                  </div>}
                                  
                                  {accountType =='Business' && <div>
                              <Row>
                                <Col>
                                  <div className="m-2">
                                      <Row>
                                        <Col lg={3} md={3} sm={3} className="pl-2 pr-2">
                                          <Form.Group className="form-group"> 
                                            <Form.Control type="text" className={validation.cmpyName? "form-control input_ctrl error" : "form-control input_ctrl"}
                                              onChange={(e) => setcmpyName(e.target.value)}  value={cmpyName}  placeholder="company name"/>
                                          </Form.Group >
                                        </Col>
                                        <Col lg={3} md={3} sm={3} className="pl-2 pr-2">
                                          <Form.Group className="form-group" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}> 
                                            <Form.Control type="text" className="form-control input_ctrl"   
                                            onChange={(e) => setcmpyPhone(e.target.value)} value={cmpyPhone} placeholder="phone" maxLength={10}/>
                                          </Form.Group >
                                        </Col>
                                        <Col lg={3} md={3} sm={3} className="pl-2 pr-2">
                                          <Form.Group className="form-group"> 
                                            <Form.Control type="text" className="form-control input_ctrl" onChange={(e) => setcmpyEmail(e.target.value)} value={cmpyEmail} placeholder="email ID"/>
                                          </Form.Group >
                                        </Col>
                                        <Col lg={3} md={3} sm={3} className="pl-2 pr-2">
                                          <Form.Group className="form-group"> 
                                            <Form.Control type="text" className="form-control input_ctrl"  
                                            onChange={(e) => setcmpyCity(e.target.value)} value={cmpyCity} placeholder="city"/>
                                          </Form.Group >
                                        </Col>
                                        <Col lg={3} md={3} sm={3} className="pl-2 pr-2">
                                          <Form.Group className="form-group"> 
                                            <Form.Control type="text" className="form-control input_ctrl"  
                                            onChange={(e) => setcmpyState(e.target.value)} value={cmpyState} placeholder="state"/>
                                          </Form.Group >
                                        </Col>
                                        <Col lg={4} md={4} sm={4} className="pl-2 pr-2">
                                          <Form.Group className="form-group"> 
                                            <textarea type="text" className="form-control text-area input_ctrl"  
                                            onChange={(e) => setcmpyDescription(e.target.value)} value={cmpyDescription} placeholder="description"/>
                                          </Form.Group >
                                        </Col>
                                        {hideEdit?<Col lg={1} md={1} sm={1} className="pl-2 pr-2">
                                        <a href="#" onClick={addUserBusinessInfo}><FaPlusCircle className="circle_plus"/></a>
                                        </Col>:
                                        <Col lg={2} md={2} sm={2} className="pl-2 pr-2">
                                        <a style={{float:'right'}}><MdCancel onClick={editCancel} className="circle_cancel"/></a> <a style={{float:'right'}}><FaCircleCheck  onClick={updateBusinessInfo} className="circle_check"/></a> 
                                        </Col>}
                                      </Row>
                                    </div>
                                </Col>
                              </Row>          
                              {businessInfo && businessInfo.map((item, i) => (
                                <Card className="m-2" style={{backgroundColor:'##f7f7f700'}} key={i}>
                                  <Row className="p-2">
                                    <Col lg={8}>
                                      <h5>{item.companyName}</h5>
                                      <p className="mb-0">{item.city} , {item.state}</p>
                                    </Col>
                                    <Col lg={4}>
                                      <a href="#" style={{float:'right'}} onClick={e => editBusiness(item,i)}>
                                        <FiEdit style={{color: '#144689',fontSize: '18px'}}/></a>
                                    </Col>
                                  </Row>
                                </Card>
                              ))}
                            
                            </div>
                          }
                          {accountType =='Professional' && <div>
                          <Row>
                                <Col lg="12">
                                  <div>
                                      <Row>
                                        <Col lg={3} md={3} sm={3}>
                                          <Form.Group className="form-group"> 
                                            <Form.Control type="text"  className={validation.professionType? "form-control input_ctrl error" : "form-control input_ctrl"}
                                            onChange={(e) => setprofessionType(e.target.value)} value={professionType} placeholder="profession type"/>
                                          </Form.Group >
                                        </Col>
                                        <Col lg={4} md={4} sm={4}>
                                          <Form.Group className="form-group"> 
                                            <Form.Control type="text" className="form-control input_ctrl"  
                                            onChange={(e) => setofficeAddress(e.target.value)} value={officeAddress} placeholder="Office Address"/>
                                          </Form.Group >
                                        </Col>
                                        <Col lg={4} md={4} sm={4}>
                                          <Form.Group className="form-group"> 
                                            <Form.Control type="text" className="form-control input_ctrl"
                                            onChange={(e) => setofficePhone(e.target.value)} value={officePhone} placeholder="phone"/>
                                          </Form.Group >
                                        </Col>
                                        <Col lg={1} md={1} sm={1}>
                                          <a href="#" onClick={addUserProfessionInfo}><FaPlusCircle className="circle_plus"/></a>
                                        </Col>
                                      </Row>
                                    </div>
                                </Col>
                              </Row>          
                              {professionInfo && professionInfo.map((item, i) => ( 
                              <Card className="m-2" style={{backgroundColor:'##f7f7f700'}} key={i}>
                              <Row className="p-2">
                                <Col lg={8}>
                                  <h5>{item.professionType}</h5>
                                  <p className="mb-0">{item.officeAddress}</p>
                                </Col>
                                <Col lg={4}>
                                  <a style={{float:'right'}}><FiEdit style={{color: '#144689',fontSize: '18px'}}/></a>
                                </Col>
                              </Row>
                            </Card>
                            ))}
                            </div>}
                          <div className="pl-lg-4 pt-3 text-center">
                            <Button onClick={onSubmit} className="btn btn-default update_btn btn-primary float-left">Update</Button>
                          </div>
                      </Form>
                          </Col>
                        </Row>
                      
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
              {/* ----------- Mobile verification Popup ---------- */}
              {!isMobileCodeSend || isPhoneChanged?<Modal size="sm" show={mobilePopupShow} onHide={() => setMobilePopupShow(false)} >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                    Verify Mobile Number
                    </Modal.Title>
                    <span onClick={()=>{setMobilePopupShow(false)}} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body >
                    <Button onClick={verifyMobile}>Send Verification Code</Button>
                </Modal.Body>
              </Modal>: <Modal size="sm" show={mobilePopupShow} onHide={() => setMobilePopupShow(false)} >
              <Modal.Header>
                  <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                    Verify Mobile Number
                  </Modal.Title>
                  <span onClick={()=>{setMobilePopupShow(false)}} title="Close">
                      <AiOutlineClose />
                  </span>
              </Modal.Header>
              <Modal.Body >
              <div className="fz_11">Enter the OTP send to Mobile No {phone}</div>
                    <div className="input-group code_Input">
                        <input className="form-control py-2 border border-top-0 fz_13" placeholder="Enter OTP" onChange={(e) => setVerifcationCode(e.target.value)}  value={verificationCode}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                    </div>
                    {emptyErr?<span className="fz-13">Enter OTP</span>:''}
                    <div className="d-flex justify-content-between mb-2">
                    <div><span className="fz-12">Didn't receive an OTP?&nbsp;<a href="#" classsName="a_clr fz-12" onClick={verifyMobile}>Resend Code</a></span></div>
                    {timerMinutes === '00' && timerSeconds ==='00' ?'':<div className="float-right fz_13">{timerMinutes === '00' ?
                      <span className="">{timerMinutes}:{timerSeconds}</span>:<span>{timerMinutes}:{timerSeconds}</span>}
                      </div>}
                      {timerMinutes === '00' && timerSeconds ==='00'?
                      <div className="float-right text-warning fz-12">Code Expired</div>:''}
                    </div>
                    <Button onClick={verifyMobileCode} className="fz-12">Verify</Button>
              </Modal.Body>
            </Modal>}
            <Modal size="sm" show={deletePopupShow} onHide={() => setDeletePopupShow(false)} >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                      Delete Confirmation
                    </Modal.Title>
                    <span onClick={() => setDeletePopupShow(false)} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body className='bordr'>
                    <span>Are you sure to delete this profile image?</span>
                </Modal.Body>
                <Modal.Footer className='bordr'>
                    <Button variant="primary" className="del_bttn"  onClick={deleteImage}>Yes</Button>
                    <Button variant="danger" className="del_bttn" onClick={() => setDeletePopupShow(false)}>No</Button>
                </Modal.Footer>
              </Modal>
              {/* -----------Email verification Popup ---------- */}
              {!isEmailCodeSend?<Modal size="sm" show={emailPopupShow} onHide={() => setEmailPopupShow(false)} >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                      Verify Email Id
                    </Modal.Title>
                    <span onClick={()=>{setEmailPopupShow(false)}} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body >
                    <Button onClick={verifyEmail}>Send Verification Code</Button>
                </Modal.Body>
              </Modal>:
              <Modal size="sm" show={emailPopupShow} onHide={() => setEmailPopupShow(false)} >
              <Modal.Header className="p-3">
                <Modal.Title id="example-custom-modal-styling-title" className="mb-4" style={{fontSize: "15px", fontWeight: 600}}>
                      Verify Email Id
                  </Modal.Title>
                  <span onClick={()=>{setEmailPopupShow(false)}} title="Close">
                      <AiOutlineClose />
                  </span>
              </Modal.Header>
              <Modal.Body className="p-3">
                    <div className="fz_11">Enter the OTP send to Email id {email}</div>
                    <div className="input-group code_Input">
                        <input className="form-control py-2 border border-top-0 fz_13" placeholder="Enter OTP" onChange={(e) => setVerifcationCode(e.target.value)}  value={verificationCode}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                    </div>
                    {emptyErr?<span className="fz-13">Enter OTP</span>:''}
                    <div className="d-flex justify-content-between mb-2">
                    <div><span className="fz-12">Didn't receive an OTP?&nbsp;<a href="#" classsName="a_clr fz-12" onClick={verifyEmail}>Resend Code</a></span></div>
                    {timerMinutes === '00' && timerSeconds ==='00' ?'':<div className="float-right fz_13">{timerMinutes === '00' ?
                      <span className="">{timerMinutes}:{timerSeconds}</span>:<span>{timerMinutes}:{timerSeconds}</span>}
                      </div>}
                      {timerMinutes === '00' && timerSeconds ==='00'?
                      <div className="float-right text-warning fz-12">Code Expired</div>:''}
                    </div>
                  <Button onClick={verifyEmailCode} className="fz-12">Verify</Button>
              </Modal.Body>
            </Modal>}
          </div>:
              <div className="pro-loader-center"><img className="loader-image" src={loader} alt="Loading..." /></div>}
        </div>
      <ToastContainer/>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId
  };
};
export default connect(mapStateToProps)(Profile);
