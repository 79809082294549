import HeaderMenu from '../headerMenu';
import SideBar from "../SideBar";
import { Card,Col,Row} from "react-bootstrap";
import BreadCrumb from '../../plugins/Breadcrumb';
const HelpTab = () => {
    return ( 
        <>
        <HeaderMenu/>
        <div className="">
        <SideBar/>
        <div className="main-content ml_200">
        <BreadCrumb/>
        <Card className="shade h-500 card text-center d-flex align-items-center" style={{color: 'blue'}}>Help</Card>
         </div></div>
        </>
     );
}
 
export default HelpTab;