import { useForm} from "react-hook-form";
import {BsExclamationCircle} from 'react-icons/bs';
import { useState,useEffect,forwardRef,useImperativeHandle,useRef} from 'react';
import {HiCheckCircle} from 'react-icons/hi';
import "../ProfileUpdate/Profile.css";
import {Form} from 'react-bootstrap';
const PhoneControl = forwardRef((props, ref)=> {
  const [phoneNo, setPhoneNo] = useState("");
  const [countryCode, setCountryCode] = useState('+1')
  const [validation, setValidation] = useState({phone:""});
  useEffect(() => {
    assignValue();
    },[]);
    const assignValue=()=>{
      if(props.phoneNo){
        if (props.phoneNo != null && props.phoneNo.trim().startsWith("+")) {
             let phoneData = props.phoneNo.trim().split(" ");
             setCountryCode(phoneData[0]);
             setPhoneNo(phoneData[1]);
         }else{
           setPhoneNo(props.phoneNo);
           setCountryCode("");
         } 
         setValidation({phone:''}); 
     }
     else{
      if(props.isProfile && (props.phoneNo=='' || props.phoneNo == undefined)){
        setValidation({phone:'Phone No is required'});
       setPhoneNo("");
      }
      else{
        setPhoneNo("");
      }
     }
    }
    const  handlePhoneFormet=(value)=>{
      if(value.startsWith("+")){
        setCountryCode(value);
        const phoneNumber = value +' '+ phoneNo;
        props.onPhoneChange(phoneNumber)
      }
      else{
      const formattedPhoneNumber = formatPhoneNumber(value);
      setPhoneNo(formattedPhoneNumber);
      const phoneNumber = countryCode +' '+ formattedPhoneNumber;
      props.onPhoneChange(phoneNumber)
      }
    }
    const formatPhoneNumber=(value)=>{
      if (value==''||value== undefined||value==0){
        setValidation({phone:'Phone No is required'});
      }
      else{
        setValidation({phone:''});
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
      }
    }
    const verification=()=>{
      props.PopupShow(true);
    }
    useImperativeHandle(ref, () => ({
    clrField (){
      setPhoneNo('');
      setCountryCode('+1');
    }
  }))
  return (
    <div>
       <Form.Group className={props.isProfile || props.unmounted?"form-group required-field-block":"pro_form form-group inputBox"}>
       {props.isProfile || props.unmounted?<Form.Label className="mb-1 profile_label">Phone Number</Form.Label>:''}
            <div className={(props.mandatory && (validation.phone || props.phoneValidation))?`${!props.isProfile && !props.unmounted?'rounded':''} d-flex error `:"d-flex"}>
                <Form.Select type="select" className={props.isProfile || props.unmounted?'form-control input_ctrl country-code':'formControl fz_13 country-code'} defaultValue={countryCode} value={countryCode} onChange={(e) => handlePhoneFormet(e.target.value)}>
                <option value="+1">+1</option>
                <option value="+91">+91</option>
            </Form.Select>
            <Form.Control type="text" maxLength={14} className={props.isProfile || props.unmounted?'form-control input_ctrl phone-input':'formControl fz_13 phone-input'} onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} onChange={(e) => handlePhoneFormet(e.target.value)} value={phoneNo}/>
            {(!validation.phone && props.isProfile && props.unmounted) && <span className="required-icon">{props.mobileVerify && !props.isverified?<a href="#" onClick={verification}>
              <BsExclamationCircle className={(props.isverified && props.isPhoneChanged)? "" : "text-danger"} style={{fontSize:'20px'}}/></a>:
              <HiCheckCircle className="check_circle"/>}</span>}
            </div>
            {props.mandatory && validation.phone && props.isProfile? <p className="fz-13">{validation.phone}</p>:''}
        </Form.Group>
    </div>
  )
})
export default PhoneControl;
