import './inbox.css';
import chatImg from "../../../assets/images/chat.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import {followId} from '../../../Redux/Actions/userAction';
import { useState,useEffect,forwardRef,useImperativeHandle,useRef} from 'react';
import axios from '../../../axios';
import { useSelector } from 'react-redux';
import profile from '../../../assets/images/profile.jpg'
import { Modal,Button} from "react-bootstrap";
import { HiLocationMarker } from "react-icons/hi";
import chatInputStyle from '../../../assets/css/chatInputStyle';
import { MentionsInput, Mention } from 'react-mentions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export const Chat=forwardRef((props, ref)=> {
    const lastMessageRef = useRef(null);
    const userId = useSelector(state => state.user.userId);
    const [yesterData, setYesterData] = useState([]);
    const [toData, setToData] = useState([]);
    const [dateData, setDateData] = useState([]);
    const [message, setMessage] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [users,setUsers] = useState([]); 
    const handleCloseErrorModal = () => setShowErrorModal(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          sendMsg();
        }
    }
    function textWithLinks(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const mentionRegex = /@([^\s]+)/g;
        return text.split(' ').map((word, index) => {
          if (urlRegex.test(word)) {
            return (
              <a key={index} href={word} target="_blank" rel="noopener noreferrer">
                {word}
              </a>
            );
          } else if (mentionRegex.test(word)) {
            const mentions = word.match(mentionRegex);
            if (mentions) {
              return mentions.map((mention, mentionIndex) => {
                const match = mention.match(/@\[([^\]]+)\]\((\d+)\)/);
                const id = match ? match[2] : null;
                    return (
                  <span
                  key={index + mentionIndex}
                  className={id == props.userId ? "" : "cmt_icon c_pointer"}
                  onClick={() => handleMention(mention)}
                    >
                      {rmvMenFormat(mention)}
                  </span>
                );
              });
            }
          }
      
          return word +' ';
        });
      }
      
      const rmvMenFormat=(value)=>{
        var mention = value.split('](');
        var men_name = mention[0].replace('[', '').slice(1);
        console.log('men',men_name)
        return men_name+' ';
      }
      const handleMention = (mentionedUser) => {
        const word = mentionedUser;
        const match = word.match(/\[@([^\]]+)\]\((\d+)\)/);
        const name = match ? match[1] : null;
        const id = match ? match[2] : null;
        if (name && id) {  
            if(id != props.userId) {        
        dispatch(followId(id));
        navigate('/dashboard/viewuser', { state: { userId: id } });
            }
        } 
      };
    const handleChange = (event) => {
        setMessage(event.target.value);
      };
      useEffect(() => {
        loadMsgs(props.id);
        // const intervalId = setInterval(() => loadMsgs(props.id), 5000); 
        // return () => {
        // clearInterval(intervalId); 
        // };
        userLists();
      },[props.id]);
      const userLists = () =>{
        axios.get(process.env.REACT_APP_BASEURL +'/lookup/users')
        .then(response => {
            const usersArr = response.data.recordInfo.filter((row)=>{
                return row.id != userId;
              })
              const users = usersArr.map(function(row){
                return {display:row.name,id:row.id}
              })
            setUsers(users)
        })
        .catch(error => {
            console.log(error);
        });  
      }
      useImperativeHandle(ref, () => ({
        reloadMsg(){
            loadMsgs();
        }
      }))
      const handleReloadChats = () => {
        window.dispatchEvent(new Event('reloadLoadChats'));
      };
      const loadMsgs=(id)=>{console.log("dm",id)
        if(id){
        const senderParams = {'receiverId':userId,'senderId':id}
          axios.post(process.env.REACT_APP_BASEURL +'/chatbox/list/bysender',senderParams)
              .then(response => {
                mergeMsgs(response.data.recordInfo);
                setTimeout(() => {
                if (lastMessageRef.current) {
                    lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
              })
              .catch(error => {
                  toast.error({
                      title: 'Error Occured',
                      position: 'topRight'
                  });
              });
            }
    }
    function timesort(a, b) {
        let dateA = new Date(a.date).getTime();
        let dateB = new Date(b.date).getTime();
        return dateA > dateB ? 1 : -1;
    };
    const mergeMsgs=(msg)=>{
        const ff = msg.filter(obj =>{
            if(obj.senderId==userId || obj.senderId==props.id){
                return obj;
            }
        })
        const gg = ff.map(obj => {
            return {...obj, createdAt: new Date(obj.createdAt)};
          });
        const sortedAsc = gg.sort(
            (objA, objB) => Number(objA.createdAt) - Number(objB.createdAt),
          );
          sortedAsc.sort(timesort);
          const yesterday = new Date().getDate() - 1;
          const Yester_data = sortedAsc.filter(st => yesterday ==  new Date(st.createdAt).getDate())
          Yester_data.reverse();
          setYesterData(Yester_data)
          const Today_data = sortedAsc.filter(st => new Date().getDate() ==  new Date(st.createdAt).getDate())
          Today_data.reverse();
          setToData(Today_data)
          const date_data = sortedAsc.filter(st => (new Date().getDate() !=  new Date(st.createdAt).getDate() && yesterday !=  new Date(st.createdAt).getDate()))
          date_data.reverse();
          setDateData(date_data)
    }
    const getTimeStamp=(value)=>{
        const now = new Date();
        if(moment(now).format("HH:mm") == moment(value).format("HH:mm")){
            return 'Just Now'}
        else{
            return moment(value).format("HH:mm");
        }
    }
    const nameClick=(id)=>{
        dispatch(followId(id));
        navigate('/dashboard/viewuser', { state: { userId: id } });
    }
     const changeCmtText = (value)=>{
        var words = value.split(/\s/);
        var contents = words.map(function(word, i) {
            var separator = i < (words.length - 1) ? ' ' : '';
            return word + separator;
        });
        let cmtText = contents.reduce((accumulator, item) => accumulator += item, '');
        if(cmtText.length>288){
            var limit_char = cmtText.substring(0,288);
            setMessage(limit_char);}
          else
            setMessage(cmtText);
      }
    const sendMsg = () => {
        if(message.trim() && message.length <= 288){
            const msgContent = {'senderId':userId,'receiverId':props.id,'content':message}
            axios.post(process.env.REACT_APP_BASEURL +'/chatbox/add',msgContent)
            .then(response => {
                if(response){
                    setMessage('');
                    loadMsgs(response.data.receiverId);
                    handleReloadChats();
                }
            })
            .catch(error => {
                toast.error({
                    title: 'Error Occured',
                    position: 'topRight'
                });
            });
        }    
        else if(!(message.length <= 288)){
            setShowErrorModal(true);
        }
    }
    
    return (
        <>
        <div className='chat container'>
        {props.id && !props.notShow? 
            <div className="chatInfo">
                <div className="ban_messageInfo m-2">
                    <img src={props.img?props.img:profile}/>
                </div>
                <div className={`d-flex flex-column ml-2 ${props.city != undefined && props.state != undefined ? 'mt-4': ''}`}>
                    <a href="#" onClick={()=>nameClick(props.id)}>{props.name}</a>
                    {props.city != undefined && props.state != undefined ?<span><p className="fz-12 font-weight-normal">
                    <HiLocationMarker/>&nbsp;{props.city+','+props.state}</p>
                </span>:''}
                </div>
                {/* <div className="chatIcons">
                    <IoMdVideocam className='Bs_cam'/>
                    <FaUserPlus className='Bs_cam'/>
                    <IoIosMore className='Bs_cam'/>
                </div> */}
            </div>:''}
            {props.id && !props.notShow?
            <div>
            <div className="messages">
            {dateData.map((chatInfo, i) => (
                chatInfo.senderId==props.id ? (<div className='left_msgdiv'>
                    <div className="left_message" key={i}>
                        <div className="messageInfo">
                            <img src={chatInfo.senderImagePath?chatInfo.senderImagePath:profile}/>
                        </div>
                       
                    </div>
                    <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent"><p>{textWithLinks(chatInfo.content)}</p></div>
                    </div>
                    <div className="d-flex">
                        <div className="msg_pad"></div>
                        <div className="messageContent">
                            <div className='day_div'><span>{moment(chatInfo.createdAt).format("D MMM,HH:mm")}</span></div>
                        </div>
                    </div>
                </div>):
            chatInfo.senderId==userId ? (<div className='left_msgdiv'>
                <div className="left_message" key={i}>
                    <div className="messageInfo">
                        <img src={chatInfo.senderImagePath?chatInfo.senderImagePath:profile}/>
                    </div>
                    
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="rt_messageContent"><p>{textWithLinks(chatInfo.content)}</p></div>
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="messageContent">
                        <div className='day_div'><span>{moment(chatInfo.createdAt).format("D MMM,HH:mm")}</span></div>
                    </div>
                </div>
            </div>):''))}
            {yesterData.length>0?<div className='d-flex justify-content-center'><div className='day_label'>Yesterday</div></div>:''}
            {yesterData.map((chatInfo, i) => (
            chatInfo.senderId==props.id ?(<div className='left_msgdiv'>
                <div className="left_message" key={i}>
                    <div className="messageInfo">
                        <img src={chatInfo.senderImagePath?chatInfo.senderImagePath:profile}/>
                    </div>
                    
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="messageContent"><p>{textWithLinks(chatInfo.content)}</p></div>
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="messageContent">
                        <div className='day_div'><span>{'Yesterday '+ getTimeStamp(chatInfo.createdAt)}</span></div>
                    </div>
                </div>
            </div>):
            chatInfo.senderId==userId ? (<div className='left_msgdiv'>
                <div className="left_message" key={i}>
                    <div className="messageInfo">
                        <img src={chatInfo.senderImagePath?chatInfo.senderImagePath:profile}/>
                    </div>
                   
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="rt_messageContent"><p>{textWithLinks(chatInfo.content)}</p></div>
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="messageContent">
                        <div className='day_div'><span>{'Yesterday '+ getTimeStamp(chatInfo.createdAt)}</span></div>
                    </div>
                </div>
            </div>):''))}
            {toData.length>0?<div className='d-flex justify-content-center'><div className='day_label'>Today</div></div>:''}
            {toData.map((chatInfo, i) => (
            chatInfo.senderId==props.id ? (<div className='left_msgdiv'>
                <div className="left_message" key={i}>
                    <div className="messageInfo">
                        <img src={chatInfo.senderImagePath?chatInfo.senderImagePath:profile}/>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="messageContent"><p>{textWithLinks(chatInfo.content)}</p></div>
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="messageContent">
                        <div className='day_div'><span>{getTimeStamp(chatInfo.createdAt)}</span></div>
                    </div>
                </div>
            </div>):
            chatInfo.senderId==userId ? (<div className='left_msgdiv'>
                <div className="left_message" key={i}>
                    <div className="messageInfo">
                        <img src={chatInfo.senderImagePath?chatInfo.senderImagePath:profile}/>
                    </div>
                    
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="rt_messageContent"><p>{textWithLinks(chatInfo.content)}</p></div>
                </div>
                <div className="d-flex">
                    <div className="msg_pad"></div>
                    <div className="messageContent">
                        <div className='day_div'><span>{getTimeStamp(chatInfo.createdAt)}</span></div>
                    </div>
                </div>
            </div>):''))}
            <div ref={lastMessageRef}></div>
            </div>
            <div className="input inpt_msg">
            <MentionsInput className="men_inp" style={chatInputStyle} maxLength={288} 
                 onChange={(e) => {changeCmtText(e.target.value)}} value={message} onKeyPress={handleKeyPress} placeholder='Type here...' >
                    <Mention style={{backgroundColor: "#efebe3"}}
                        trigger="@"
                        data={users}
                    />
            </MentionsInput>
            <div className="send">
                {/* <input type="file" style={{display:"none"}} id="file"/>
                    <label htmlFor="file">
                        <FaPaperclip className='fa_paperclip'/>
                    </label>
                <input type="file" style={{display:"none"}} id="file"/>
                    <label htmlFor="file">
                        <BiImage className='bi_image'></BiImage>
                    </label> */}
                <button onClick={sendMsg}>Send</button>
            </div>
        </div>
        </div>:
            <div className='welcome_container'>
                <div className="d-flex justify-content-center">
                <div  className="chat_header"><h6>Let's Start Chatting!</h6></div>
                <div  className="chat_headerImg"><img src={chatImg} style={{height:'100px'}}/></div></div>
            </div>}
             {/* ----------- limit exceed msg Popup ---------- */}
            <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Your message is too long</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please edit it down to 288 characters or split over multiple messages.</Modal.Body>
                <Modal.Footer>
                    <Button className="del_bttn" variant="primary" onClick={handleCloseErrorModal}>
                         Ok
                    </Button>
                </Modal.Footer>
         </Modal>
        </div>
        <ToastContainer/>
        </>
        )
    })
    
    export default Chat;