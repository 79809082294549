import { Card,Row,Col } from "react-bootstrap";
import moment from "moment";
import "../../Register/register.css"
import StarRating from './StarRating';
import profile from '../../../assets/images/profile.jpg'
import {useEffect, useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "../../../axios";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import BreadCrumb from "../../plugins/Breadcrumb";
import loader from '../../../assets/images/loader.gif';
import { IoMdArrowDropdown,IoMdArrowDropup} from 'react-icons/io';
import { connect } from 'react-redux';
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import RatingBar from '../../plugins/RatingBar';
import { FiUsers,FiUser } from "react-icons/fi";
import Horizontalbar from '../../plugins/Horizontalbar';
import { IoIosArrowDown } from "react-icons/io";
import {Overlay, Popover,OverlayTrigger } from 'react-bootstrap';
import { BiSolidCommentDetail } from "react-icons/bi";
import { BsQuestionDiamond } from "react-icons/bs";
import { MdOutlineAttachment } from "react-icons/md";
import fileImg from '../../../assets/images/file.jpg';
import {followId} from '../../../Redux/Actions/userAction';  
import mentionInputStyle from '../../../assets/css/MentionStyle';
import { MentionsInput, Mention } from 'react-mentions'; 
import { useDispatch } from 'react-redux';

const ReviewsTab = (props) => {
    const [loading, setLoading] = useState(false);
    const [reviewInfo,setReviewInfo] = useState([]);
    const [showAdditionalReviews, setShowAdditionalReviews] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [reviewList, setReviewList] = useState([]);
    const [showReply, setShowReply] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [revCmnt, setRevCmnt] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const [expandedCommentIndex, setExpandedCommentIndex] = useState(-1);
    const [revGlobalRtng, setRevGlobalRtng] = useState([]);
    const [avgCount, setAvgCount] = useState('');
    const [replies,setRevReplies] = useState([]);
    const [showChildReply,setShowChildReply] = useState(false);
    const [childComment, setchildComment] = useState('');
    const [childcurrentIndex,setchildcurrentIndex] = useState(null);
    const [childrepplycurrentIndex,setchildreplycurrentIndex] = useState([]);
    const [currentIndex,setcurrentIndex] = useState(null);
    const [reviewSts,setReviewSts] = useState('');
    const [cmtError,setCmtError] = useState('');
    const [cmtErrId,setCmtErrId] = useState(null);
    const [userList,setUserList] = useState(''); 
    const [users,setUsers] = useState([]); 
    const [mentionuserIds, setmentionuserIds] = useState([]);
    const dispatch = useDispatch();

    const expendComment = (index) => {
        setExpandedCommentIndex(index === expandedCommentIndex ? -1 : index);
      };
      
      const togglecomment=(id)=>{
        setchildcurrentIndex(null);
        setShowChildReply(false)
        if (currentIndex == id) {
            setcurrentIndex(null)
        } else {
            setcurrentIndex(id)
        }
    }
    const changeCmtText = (value,type,id)=>{
        var words = value.split(/\s/);
        var contents = words.map(function(word, i) {
            var separator = i < (words.length - 1) ? ' ' : '';
            return word + separator;
        });
    if(contents.length!=0){
      var filtercontents = contents.filter(name => name.startsWith('@'))
      var missing = filtercontents.map(function(o1){
        if(o1 != '@' && o1.includes('](')){
            var mention = o1.split('](');
            var men_id = mention[1].replace(')', '');
            return +men_id;
        }
      })
      var uniqueItems = [...new Set(missing)];
      var men_user_ids = uniqueItems.map(x=>{
        return {'id':x}
      })
       setmentionuserIds(men_user_ids)
        }
        let cmtText = contents.reduce((accumulator, item) => accumulator += item, '');
        if(cmtText.length>288){
            var limit_char = cmtText.substring(0,288);
            setchildComment(limit_char);}
          else
            setchildComment(cmtText);
      }
    const togglechildcomment=(id)=>{
        setchildComment('');
        if (childrepplycurrentIndex == id) {
            setchildreplycurrentIndex(null)
        } else {
            setchildreplycurrentIndex(id)
        }
    }
    const  loadRevComments = (revId) => {
        axios.post(process.env.REACT_APP_BASEURL +'/review/view/comments',{'id':revId})
        .then(response => {
            console.log("res",response.data)
            setRevCmnt(response.data.recordInfo.reviewComments);
            setRevReplies(response.data.recordInfo.reviewReplies);
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
    }
    const handlerplylikechange=(id,revId)=>{
        console.log("revId",revId)
        updaterplyLikeStatus({'reviewId':revId,'commentId':id,'userId':props.userId,'liked':1})  
    }
    const handlerplydislikechange=(id,revId)=>{
        updaterplyLikeStatus({'reviewId':revId,'commentId':id,'userId':props.userId,'liked':0}) 
    }
    const rplylikecntDecrement=(id,revId)=>{
        updaterplyLikeStatus({'reviewId':revId,'commentId':id,'userId':props.userId,'liked':-1}) 
    }
    const updaterplyLikeStatus=(likeParams)=>{
        axios.post(process.env.REACT_APP_BASEURL +'/review/comment/add/liked',likeParams)
        .then(response => {
            if(response.data.status == "OK"){
                loadRevComments(likeParams.reviewId);
            }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
    }
    const postComment = (id,revId) =>{
        if(childComment.trim().length !==0){
        let params = {
                    "userId":props.userId,
                    "commentText":childComment,
                    "reviewId":revId,
                    "parentCommentId":id,
                    "mentionUserIds":mentionuserIds
                }
            console.log("params",params)
                axios.post(process.env.REACT_APP_BASEURL +'/comments/add',params)
                .then(response => {
                    console.log("response",response)
                    if(response.data.status == "OK"){
                    toast.success(response.data.message, {
                    theme: 'colored',
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,       
                    });
                    setchildComment('');
                    setmentionuserIds([]);
                    togglecomment(response.data.id);
                    loadRevComments(params.reviewId);
                }
                })
                .catch(error => {
                    console.log(error);
                    toast.error({
                        title: 'Error Occured',
                        position: 'topRight'
                    });
                });
            }
            else{
                setCmtError('Comment text is required')
                setCmtErrId(id);
            }
      }
    const navigate = useNavigate();
    useEffect(() => {
        loadReviewList();
         userLists();
      }, [props]);
      const userLists = () =>{
        axios.get(process.env.REACT_APP_BASEURL +'/lookup/users')
        .then(response => {
            const usersArr = response.data.recordInfo.filter((row)=>{
                return row.id != props.userId;
              })
              const users = usersArr.map(function(row){
                return {display:row.name,id:row.id}
              })
            setUsers(users)
        })
        .catch(error => {
            console.log(error);
        });  
      }
      const handleEvent = (review) => {
        navigate('/dashboard/reviews/view', { state: { review } });
      };

      const loadReviewList=()=>{
        setLoading(true)
        axios.post(process.env.REACT_APP_BASEURL +'/review/list/latest',{'userId':props.userId})
        .then(response => {
            console.log(response)
            setLoading(false)
          if(response.data.status == "OK"){
            let result = response.data.recordInfo;
            setReviewInfo(result)
          }
        })
        .catch(error => {
            setLoading(false)
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
      }
      const additionalReviewList=(id)=>{
        axios.post(process.env.REACT_APP_BASEURL +'/review/list/byuserid',{'userId':id})
        .then(response => {
            console.log(response)
          if(response.data.status == "OK"){
            let result = response.data.recordInfo;
            setReviewList(result)
          }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
      };
      const viewRevComments=(data,replies,sts)=>{
        loadRevComments(data[0].reviewId);
        setShowReply(!showReply);
        setReviewSts(sts);
        setShowChildReply(false);
        setcurrentIndex(null);
        setchildreplycurrentIndex(null);
    }
    const viewChildComments=(data,id,revId)=>{
        setcurrentIndex(null);
        setShowChildReply(!showChildReply);
        if (childcurrentIndex == id) {
            setchildcurrentIndex(null)
        } else {
            setchildcurrentIndex(id)
        }
        loadRevComments(revId);
    }
    const viewAttachment=(data)=>{
        setShowAttachment(true);
        setAttachment(data)
    }
      const toggleAdditionalReviews = (reviewId) => {
        if (selectedUserId === reviewId) {
            setSelectedUserId(null);
            setShowAdditionalReviews(false);
        } else {
            setSelectedUserId(reviewId);
            setShowAdditionalReviews(true);
            additionalReviewList(reviewId);
        }
    };
    const handlelikechange=(id,userId)=>{
        updateLikeStatus({'reviewId':id,'userId':userId,'liked':1})  
    }
    const handledislikechange=(id,userId)=>{
        updateLikeStatus({'reviewId':id,'userId':userId,'liked':0}) 
    }
    const updateLikeStatus=(likeParams)=>{
        axios.post(process.env.REACT_APP_BASEURL +'/review/add/liked',likeParams)
        .then(response => {
          if(response.data.status == "OK"){
            loadReviewList();
            setShowAdditionalReviews(false)
          }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
    }
    const likecntDecrement=(id,userId)=>{
        updateLikeStatus({'reviewId':id,'userId':userId,'liked':-1}) 
    }
    const handleArrowClick = (rating,avgCount) => {
        setAvgCount(avgCount);
        const starResponse = rating;
        const starData = starResponse[0];
    
        const labelValueMap = {};
        for (const label in starData) {
          labelValueMap[label] = parseFloat(starData[label]);
        }
        const sortedLabels = Object.keys(labelValueMap).sort((a, b) => {
          const numA = parseInt(a.split(' ')[0]);
          const numB = parseInt(b.split(' ')[0]);
          return numB - numA;
        });
        const result = sortedLabels.map((label) => ({
          label,
          value: labelValueMap[label],
        }));
        setRevGlobalRtng(result);
      }
      const checkExt=(file)=>{
        let allowedfiletypes=['jpg','svg','jpeg','png','bmp','gif','jfif']
        if(allowedfiletypes.some(ext => ext == file.fileName.substring(file.fileName.lastIndexOf('.') + 1))){
          return true;
        }
        return false;
      }

      const handleMention = (mentionedUser) => {
        const word = mentionedUser;
        const match = word.match(/@\[([^\]]+)\]\((\d+)\)/);
        const name = match ? match[1] : null;
        const id = match ? match[2] : null;
        if (name && id) {  
            if(id != props.userId) {        
        dispatch(followId(id));
        navigate('/dashboard/viewuser', { state: { userId: id } });
            }
        } 
      };
      const rmvMenFormat=(value)=>{
        var mention = value.split('](');
        var men_name = mention[0].replace('[', '').slice(1);
        console.log('men',men_name)
        return men_name;
      }
      function textWithLinks(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const mentionRegex = /@([^\s]+)/g;
        return text.split(' ').map((word, index) => {
          if (urlRegex.test(word)) {
            return (
              <a key={index} href={word} target="_blank" rel="noopener noreferrer">
                {word}
              </a>
            );
          } else if (mentionRegex.test(word)) {
            const mentions = word.match(mentionRegex);
            if (mentions) {
              return mentions.map((mention, mentionIndex) => {
                const match = mention.match(/@\[([^\]]+)\]\((\d+)\)/);
                const id = match ? match[2] : null;
                    return (
                  <span
                  key={index + mentionIndex}
                  className={id == props.userId ? "" : "cmt_icon c_pointer"}
                  onClick={() => handleMention(mention)}
                    >
                      {rmvMenFormat(mention)}
                  </span>
                );
              });
            }
          }
      
          return word;
        });
      }
      
    return ( 
        <>
        <HeaderMenu/>  
        <div className="">
        <SideBar/>
            <div className="main-content ml_200">
            <BreadCrumb/>
            <Card className='shade h-500'>
                <div className="postedReviews">
                    <h5>Reviews I've Posted</h5>
                </div>
                {reviewInfo.length>0 ? reviewInfo.map((review, i) => (<div key={i}>
                    <Card className="shadow reviewCard">
                    <Card.Body className={`${review.status=="blocked"? 'blockedCrd':''}`} style={{padding: '15px'}}>
                        {/* <div className="text-end dot_3">
                        {review.count > 1 && (
                            <span title="More" onClick={() => toggleAdditionalReviews(review.userId)}>
                                {showAdditionalReviews && selectedUserId === review.userId ? <IoIosArrowDropupCircle className="fnt25"/> : <IoIosArrowDropdownCircle className="fnt25"/>}
                            </span>
                        )} 
                        </div> */}
                        <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={11}>
                                    <Row>
                                        <Col sm={4} md={7} lg={4} xl={4} className="text-center">
                                            <img className="brd_rad" style={{width: '70px',height: '70px'}} src={review.userImagePath ? review.userImagePath : profile}/>
                                            {review.status !="blocked" ?<div style={{marginBottom: '0px',fontWeight:'500',color:'#5e72e4',cursor:"pointer"}} onClick={() => {navigate('/dashboard/reviews/view', { state: { userIdFollow: review.userId } });}}>{review.userName}</div>:
                                            <div style={{marginBottom: '0px',fontWeight:'500',color:'gray'}} >{review.userName}</div>
                                            }
                                            <div className="fs_12 d-flex align-items-center justify-content-center" style={{marginBottom: '0px'}}>
                                            <StarRating rating={review.averageReviewCount} disabled={true} customClass="ques-star"/>
                                                   <span>({review.totalReviewCount})</span>
                                        <OverlayTrigger
                                            trigger="click"
                                            key={review.id}
                                            placement="bottom"
                                            overlay={
                                                <Popover id={`popover-${review.id}`} className='pop_rating'>
                                                <div className="drp_rating"><StarRating rating={avgCount} disabled={true} /><span>{avgCount} out of 5</span> </div>
                                                                        {revGlobalRtng.map((item, index) => ( <Row key={index}>
                                                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0"><span className="prog_span">{item.label}</span> </Col>
                                                                            <Col sm={12} md={8} lg={8} xl={7} className="p-0"><Horizontalbar dataSet={item.value}/></Col>
                                                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0 prog_span" style={{marginLeft: '10px'}}><span>{item.value}{item.value > 1 ? <FiUsers style={{marginLeft: '6px'}}/>: <FiUser style={{marginLeft: '6px'}}/>}</span></Col>
                                                                            </Row>))}
                                                </Popover>
                                            }
                                            rootClose
                                            >
                                            <span className="d-flex align-items-center" onClick={() => handleArrowClick(review.starRating,review.averageReviewCount)} >
                                                <IoIosArrowDown className='clr_gray'/>
                                            </span>
                                        </OverlayTrigger>                                                </div>
                                        </Col>
                                        <Col sm={8} md={5} lg={8} xl={8} className="bdr_lt">
                                            <Card.Text className="fts_15" style={{ marginBottom: '0px' }}>
                                                <div
                                                    className={` ${
                                                    expandedCommentIndex === i ? 'show-full' : ''
                                                    }`}
                                                    title={review.comment}
                                                >
                                                    {review.comment.length >= 75 && expandedCommentIndex !== i
                                                    ? textWithLinks(review.comment.substring(0, 75)).map((word, index) => (
                                                        <span key={index}>{word} </span>
                                                        )).concat('...')
                                                    : textWithLinks(review.comment).map((word, index) => (
                                                        <span key={index}>{word} </span>
                                                        ))
                                                    }
                                                </div>
                                                

                                                {review.comment.length >= 75 && (
                                                    <button
                                                    onClick={() => expendComment(i)}
                                                    className="read-more-button"
                                                    >
                                                    {expandedCommentIndex === i ? 'Read less' : 'Read more'}
                                                    </button>
                                                )}
                                            </Card.Text>
                                            {review.reviewComments.length!=0 && <Card.Text className='rply c_pointer' title='view review comment' style={{marginBottom: '0px'}} onClick={() => viewRevComments(review.reviewComments,review.reviewReplies,review.status)}>
                                            {review.reviewComments.length === 1 ? `${review.reviewComments.length} Reply `: `${review.reviewComments.length} Replies `}
                                                </Card.Text>}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                        </Col>
                        <Col sm={12} md={6} lg={3} xl={3}>
                            <Row>
                                <Card.Text style={{marginBottom: '2px'}}><span className="txt_rev">Role:</span> <span className="fts_15">{review.userRole}</span></Card.Text>
                                <Card.Text style={{marginBottom: '0px'}}><span className="txt_rev">Date of Review:</span><span className="fts_15"> {moment(review.createdAt).format('DD-MMM-YYYY')}</span></Card.Text>
                                <Card.Text style={{marginBottom: '0px'}}>
                                <div className='d-flex'><StarRating rating={review.rating} disabled={true} customClass="special-star"/>
                                            { review.reviewQuestion.length != 0 && <OverlayTrigger
                                            trigger="click"
                                            key={review.id}
                                            placement="bottom"
                                            overlay={
                                                <Popover id={`popover-${review.id}`} className='pop_rating'>
                                                <div className="drp_rating"></div>
                                                {review.reviewQuestion.length === 0 ? (
                                                  <div>No questions found</div>
                                                ) : (
                                                  review.reviewQuestion.map((item, index) => (
                                                    <Row className='d-flex' key={index}>
                                                      <Col sm={1} md={1} lg={1} xl={1} className="p-0 prog_span d-flex align-items-center">{index + 1}.</Col>
                                                      <Col sm={5} md={5} lg={5} xl={5} className="p-0 d-flex align-items-center"><span className="prog_span">{item.question}</span></Col>
                                                      <Col sm={6} md={6} lg={6} xl={6} className="p-0"><StarRating rating={item.rating} disabled={true} customClass="ques-star"/></Col>
                                                    </Row>
                                                  ))
                                                )}
                                              </Popover>
                                            }
                                            rootClose
                                            >
                                            <span style={{marginTop: '5px',marginLeft: '5px'}} className="d-flex align-items-center c_pointer" onClick={() => handleArrowClick(review.starRating,review.averageReviewCount)} >
                                                <BsQuestionDiamond className='clr_gray'/>
                                            </span>
                                        </OverlayTrigger>}
                                       {review.reviewAttachment.length !=0 && <span title="Attachment" className="attachmt" onClick={() => viewAttachment(review.reviewAttachment)}><MdOutlineAttachment/></span>}
                                </div>                                    
                            </Card.Text>
                           {review.status =='blocked' && <Card.Text style={{marginBottom: '0px',color: 'red'}}>Blocked</Card.Text>}
                            </Row> 
                        </Col>
                        <Col sm={12} md={12} lg={3} xl={3}>
                        <RatingBar disabled={true} likecount={review.likes} dislikecount={review.dislikes} liked={review.liked} handlelike={()=>handlelikechange(review.id,review.userId)} handledislike={()=>handledislikechange(review.id,review.userId)} handleRmvlike={()=>likecntDecrement(review.id,review.userId)} labelVAlue={review.score}/>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12} className='more_rev c_pointer fts_15'>
                                    {review.count > 1 && (
                                    <span onClick={() => {navigate('/dashboard/reviews/more', { state: { userIdRev: review.userId } });}}>More... </span>
                                    )} 
                                    </Col>
                        </Row>
                        
                    </Card.Body>
                </Card>
                </div>)):(loading ? (
                    <div className="text-center"><img className="loader-image" src={loader} alt="Loading..." /></div>
                ) :  (
            <div  className="row col-md-12 d-align mx-0">
                <div className="no-data w-100 text-center font-weight-600 fz_14">Sorry, there is no data to be displayed</div>
            </div>))}
            </Card> 
            </div>
            <Modal size="md" className="report_modal" show={showReply} onHide={() => setShowReply(false)} >
                <Modal.Header className='border_rst__btm' style={{padding: "15px 0px!important"}}>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                     <h6 className="font-weight-bold">Review Comments</h6>
                    </Modal.Title>
                    <span onClick={()=>{setShowReply(false)}} title="Close" style={{cursor: "pointer"}}>
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body style={{fontSize: "14px"}}>
                {revCmnt && revCmnt.map((data) => ( <Row>
                   <Col className={`revCmtPop pb-0 ${showChildReply && replies.length!=0?'revcmt_bg':''}`}>
                   <div className='d-flex'>
                        <div>
                        <img className='brd_rad h_w_25' src={data.userImagePath ? data.userImagePath : profile}/>
                        </div>
                        <div style={{marginLeft: '8px'}}>{data.userName}</div>
                        <span className='dat_icn'><GoDotFill/></span> <div className="fz_11 dat_icn" style={{color: '#959595'}}> {moment.utc(data.createdAt).local().startOf('seconds').fromNow()}</div></div>
                        <div className='ctTxt'>{textWithLinks(data.commentText).map((word, index) => (
                            <span key={index}>{word} </span>))
                        }</div>
                        <div class="send d-flex gap_20 mb-2 mt-2">
                        <RatingBar disabled={(reviewSts =='reported' || (data.userId === props.userId))?true:false} msgreply={true} liked={data.liked} likecount={data.likes} dislikecount={data.dislikes} handlelike={()=>handlerplylikechange(data.id,data.reviewId)} handledislike={()=>handlerplydislikechange(data.id,data.reviewId)} handleRmvlike={()=>rplylikecntDecrement(data.id,data.reviewId)} labelVAlue={data.score}/>
                            { reviewSts == 'reported' || (data.userId === props.userId)? <BiSolidCommentDetail className="cmt_icn clr_gray"/>:<BiSolidCommentDetail className="cmt_icon c_pointer" title="Add comment" onClick={()=>togglecomment(data.id)}/>}
                            <Row>
                            {replies.filter(obj => (obj.id==data.id) || (obj.parentCommentId==data.id)).length!=0 && 
                            <span className='rply c_pointer'  onClick={() => viewChildComments(replies,data.id,data.reviewId)}>
                                {replies.filter(obj =>(obj.id==data.id) || (obj.parentCommentId==data.id)).length === 1 ? `${replies.filter(obj => (obj.id==data.id) || (obj.parentCommentId==data.id)).length} Reply `: `${replies.filter(obj => (obj.id==data.id) || (obj.parentCommentId==data.id)).length} Replies `}
                                <span>{(childcurrentIndex == data.id)?<IoMdArrowDropup/>:<IoMdArrowDropdown/>}</span>
                                </span>}
                           </Row>
                        </div>
                        {(currentIndex == data.id)?<Row>
                            <div className="position-relative p-0">
                                {cmtError && cmtErrId==data.id? <div className="text-danger ml-2 fz-12">{cmtError}</div>:''}
                                <MentionsInput style={mentionInputStyle} className="form-control Cmt_box" maxLength={288}
                                 onChange={(e) => {changeCmtText(e.target.value,data.userId)}} value={childComment} placeholder='type someting...' >
                                    <Mention style={{backgroundColor: "#efebe3"}}
                                        trigger="@"
                                        data={users}
                                    />
                                </MentionsInput>
                                {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                <button className="btn btn-primary cmt_btn" onClick={() => postComment(data.id,data.reviewId)}>Post</button>
                            </div>
                        </Row>:''}
                    {(childcurrentIndex == data.id) && replies.length!=0? replies.map((item,index) => ( (item.id == data.id) || (item.parentCommentId == data.id)?<Row>
                   <Col className='revchdcmt pb-0 p-2'>
                   <div className='d-flex'>
                        <div>
                        <img className="brd_rad h_w_25" src={item.replyUserImagePath ? item.replyUserImagePath : profile}/>
                        </div>
                        <div className="d-flex" style={{marginLeft: '8px',alignItems: 'center'}}>{item.replyUserName} 
                        <span className='dat_icn'><GoDotFill/></span> <div style={{fontSize: '11px',color: '#959595'}}> {moment.utc(item.createdAt).local().startOf('seconds').fromNow()}</div></div>
                        </div>
                        <div className='ctTxt'><span className="rep_men">{"@"+item.userName+' '}</span>
                        {textWithLinks(item.replyCommentText).map((word, index) => (
                            <span key={index}>{word} </span>))
                        }
                        </div>
                        <div class="send d-flex gap_20 mb-2 mt-2">
                        <RatingBar disabled={(reviewSts =='reported' || (item.replyUserId === props.userId))?true:false} msgreply={true} liked={item.liked} likecount={item.likes} dislikecount={item.dislikes} handlelike={()=>handlerplylikechange(item.id,data.reviewId)} handledislike={()=>handlerplydislikechange(item.id,data.reviewId)} handleRmvlike={()=>rplylikecntDecrement(item.id,data.reviewId)} labelVAlue={item.score}/>
                            { reviewSts == 'reported' || (item.replyUserId === props.userId)? <BiSolidCommentDetail className="cmt_icn clr_gray"/>:<BiSolidCommentDetail className="cmt_icon c_pointer" title="Add comment" onClick={()=>togglechildcomment(item.replyCommentId)}/>}
                        </div>
                        {(childrepplycurrentIndex == item.replyCommentId)?<Row>
                            <div className="position-relative p-0">
                                {cmtError && cmtErrId==data.id? <div className="text-danger ml-2 fz-12">{cmtError}</div>:''}
                                <MentionsInput style={mentionInputStyle} className="form-control Cmt_box" maxLength={288}
                                 onChange={(e) => {changeCmtText(e.target.value,item.replyuserId)}} value={childComment} placeholder='type someting...' >
                                    <Mention style={{backgroundColor: "#efebe3"}}
                                        trigger="@"
                                        data={users}
                                    />
                                </MentionsInput>
                                {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                <button className="btn btn-primary cmt_btn" onClick={() => postComment(item.replyCommentId,data.reviewId)}>Post</button>
                            </div>
                        </Row>:''}
                    </Col>
                    </Row>:'')):''}
                    </Col>
                    </Row>
                    ))}
                </Modal.Body>
              </Modal>

              {/* Attachment popup */}

              <Modal size="md" className="report_modal" show={showAttachment} onHide={() => setShowAttachment(false)} >
                <Modal.Header className='border_rst__btm' style={{padding: "15px 0px!important"}}>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                     <h6 className="font-weight-bold">Attachments</h6>
                    </Modal.Title>
                    <span onClick={()=>{setShowAttachment(false)}} title="Close" style={{cursor: "pointer"}}>
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-around overflow-auto">
                {attachment && attachment.map((file) =>(<div className="col-2 col-md-2 col-lg-2 mrt_5">
                  {checkExt(file)?
                  <img width="70" height="70" src={file.filePath?file.filePath:''} className="br_5px border_att" />:<img width="70" height="70" src={fileImg} className="br_5px border_att" />}
                    <div className="attch_imgview" title={file.fileName}>
                      <a  title={file.fileName} href={file.filePath} className="color-black" style={{fontSize:'13px'}} target="_blank"> {file.fileName.substring(0, 9)+ '...'}</a>
                    </div>
                    </div>
                 ))}
                </Modal.Body>
              </Modal>
        </div>
        <ToastContainer/>
        </>
     );
}
const mapStateToProps = (state) => {
    return {
      userId: state.user.userId
    };
  };

export default connect(mapStateToProps)(ReviewsTab);