import React from 'react';
import loader from '../../assets/images/loader.gif';

const LoaderOverlay = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="loader-container">
          <img className="loader-image" src={loader} alt="Loading..." />
        </div>
      )}
    </>
  );
};

export default LoaderOverlay;
