import {Button,Card,FormGroup,Form,Container,Row,Col,Nav} from "react-bootstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import {Navbar,Collapse,Table} from "reactstrap";
import PhoneControl from "../CustomPhone/PhoneControl";
import profile from "../../assets/images/profile.jpg"
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import "./Profile.css"
import "../Register/register.css"
import { useState,useEffect,useRef} from 'react';
import {FaCirclePlus} from 'react-icons/fa6';
import { BsBuildingsFill } from "react-icons/bs";
import {HiLocationMarker} from "react-icons/hi"
import { FaGear } from "react-icons/fa6";
import { FiEdit } from 'react-icons/fi';
import {HiCheckCircle} from 'react-icons/hi';
import { AiOutlineClose } from "react-icons/ai";
// import Qrcode from '../../assets/images/QR.png';
import { IoPersonCircleSharp } from "react-icons/io5";
import { FaBusinessTime,FaUserTie} from "react-icons/fa";
import {TiCamera} from 'react-icons/ti';
import {BsExclamationCircle} from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../../axios';
import HeaderMenu from "../Dashboard/headerMenu";
import SideBar from "../Dashboard/SideBar";
import "../../assets/css/ToggleSwitch.css";
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../assets/images/loader.gif';
import {phoneNumber} from '../../Redux/Actions/userAction';
import { BsCardText } from "react-icons/bs";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
const STATUS = {
  start: 1,
  default: 2
}
const Profile = (props) => {
  const [status, setStatus] = useState(STATUS.default);
  const intervalRef = useRef();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [year, setYear] = useState("");
  const [city, setCity] = useState("");
  const [tiipstrSince, setTiipstrSince] = useState("");
  const [profTypeList, setProfTypeList] = useState([]);
  const [professionInfo, setprofessionInfo] = useState([]);
  const [businessInfo, setbusinessInfo] = useState([]);
  const [cmpyName, setcmpyName] = useState("");
  const [cmpyPhone, setcmpyPhone] = useState("");
  const [cmpyEmail, setcmpyEmail] = useState("");
  const [cmpyState, setcmpyState] = useState("");
  const [cmpyCity, setcmpyCity] = useState("");
  const [cmpyDescription, setcmpyDescription] = useState("");
  const [officePhone, setofficePhone] = useState("");
  const [officeAddress, setofficeAddress] = useState("");
  const [professionType, setprofessionType] = useState("");
  const [professionTypeId, setprofessionTypeId] = useState("");
  const [newProfessionType, setNewProfessionType] = useState("");
  const [emptyErr, setemptyErr] = useState(false);
  const [mobileVerified, setmobileVerified] = useState(false);
  const [isEmailCodeSend, setIsEmailCodeSend] = useState(false);
  const [isMobileCodeSend, setIsMobileCodeSend] = useState(false);
  const [hideEdit,setHideEdit] = useState(true);
  const [hideProEdit,setHideProEdit] = useState(true);
  const [mobilePopupShow, setMobilePopupShow] = useState(false);
  const [emailPopupShow, setEmailPopupShow] = useState(false);
  const [editProfessionType, setEditProfessionType] = useState(false);
  const [editBusinessInfo, setEditBusinessInfo] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [editedIdx, setEditedIdx] = useState('');
  const [editedProIdx, setEditedProIdx] = useState('');
  const [verificationCode, setVerifcationCode] = useState('');
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [config, setConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPhoneChanged, setisPhoneChanged] = useState(false);
  const [emailVerified,setemailVerified] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const onToggle = () => setIsPublic(!isPublic);
  const dispatch = useDispatch();
  const [displayContent,setdisplayContent] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState();
  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const [validation, setValidation] = useState({cmpyName:"",professionType:"",newProfType:""});
  const [isValid, setIsValid] = useState({facebookUrl:'',twitterUrl:'',linkedInUrl:'',instagramUrl:''});
  const [phoneValidation,setPhoneValidation] = useState("");
  const [officephoneValidation,setofficePhoneValidation] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [logoimagePath, setLogoimagePath] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const childCompRef = useRef();
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  useEffect(() => {
    loadsprofileInfo();
    fetch('/config.json')
          .then((res) => res.json())
          .then((res) => {setConfig(res);
          });
    loadProfessionalList();
  }, [props]);
  useEffect(()=>{
      if(status === STATUS.start){
        intervalRef.current = setInterval(() => {
          countDown()
        }, 1000);
      } 
      return () => {
        clearInterval(intervalRef.current)
      };
  },[minutes, seconds, status])
  const formatDate=(dateString)=>{
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }
  const start = () => {
    setMinutes(5);
    setSeconds(0);
    setStatus(STATUS.start);}
  const userId = useSelector(state => state.user.userId);
  const authToken = useSelector((state) => state.user.token); 
  const oldPhoneNo = useSelector((state) => state.user.phNumber); 
  const newReviewCount = useSelector((state) => state.user.newReviewCount); 
  const newInboxCount = useSelector((state) => state.user.newInboxCount); 
  const newMentionCount = useSelector((state) => state.user.newMentionCount); 
  const loadsprofileInfo=()=>{
    setdisplayContent(false);
    axios.get(process.env.REACT_APP_BASEURL +'/user/view/'+ props.userId)
    .then(response => {
      if(response.data.status == "OK"){
        let result = response.data.data;
        setFirstName(result.firstName)
        setPhone(result.phone)
        dispatch(phoneNumber(result.phone));
        setLastName(result.lastName)
        setEmail(result.email)
        setState(result.state)
        setYear(result.birthYear)
        setCity(result.city)
        setImagePath(result.imagePath)
        setbusinessInfo(result.businessList)
        setprofessionInfo(result.professionalList)
        if(result.professionalList.length>0){
          setprofessionType(result.professionalList[0].professionType)
          setofficePhone(result.professionalList[0].officePhone)
          setofficeAddress(result.professionalList[0].officeAddress)
        }
        // setTwitterUrl(result.twitterUrl)
        // setFacebookUrl(result.facebookUrl)
        // setLinkedInUrl(result.linkedinUrl)
        // setInstagramUrl(result.instagramUrl)
        setemailVerified(result.emailVerified)
        setmobileVerified(result.phoneVerified)
        setIsPublic(result.isPublic)
        setTiipstrSince(new Date(result.createdAt).toISOString().split('T')[0]);
        dispatch({
          type: 'SAVE_USER_DATA',
          payload: {
            token: authToken ,
            userId: result.id,
            userType: result.userType,
            userName: result.name,
            imagePath: (result.imagePath != null ? result.imagePath: ''),
            newReviewCount: newReviewCount,
            newInboxCount: newInboxCount,
            newMentionCount: newMentionCount
          },
        }); 
        setdisplayContent(true);
      }
    })
    .catch(error => {
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  function countDown(){
    if (seconds === 0) {
        if (minutes !== 0) {
          setSeconds(59);
          setMinutes(min => min - 1);
        } else {
          let mins = 0;
          let sec = 0;
          setSeconds(sec);
          setMinutes(mins);
        }
      } else {
        setSeconds(sec => sec - 1);
      }
  }
  const loadProfessionalList=()=>{
    axios.get(process.env.REACT_APP_BASEURL +'/professional/list')
    .then(response => {
      if(response.data.status == "OK"){
        let result = response.data.recordInfo;
        setProfTypeList(result)
      }
    })
    .catch(error => {
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  const addNewProfessionType=()=>{
    if(!newProfessionType.trim()){
      setValidation({newProfType:'Profession type is required'});
    }
    else{
    let Info = {'type':newProfessionType}
      axios.post(process.env.REACT_APP_BASEURL +'/professional/add',Info)
      .then(response => {
        if(response.data.status == "OK" && response.data.message=="Professional Type created successfully."){
          let result = response.data.recordInfo;
          toast.success(response.data.message, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500
          });
          setProfTypeList(result)
          setValidation({newProfType:''});
          setEditProfessionType(false);
          loadProfessionalList();
        }
        else{
          toast.error(response.data.message, {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast.error({
            title: 'Error Occured',
            position: 'topRight'
        });
    });
    }
  }
  const phoneValidate=()=>{
    console.log("ss",officePhone)
    const result = officePhone.split(' ');
    const ph=result[1];
    if(phone != '' && ph != undefined && ph.length === 14){
      setofficePhoneValidation('');
    }
  else{
    setofficePhoneValidation("Phone No is required");
  }
  }
  const officephoneChange=(value)=>{
    const result = value.split(' ');
    const ph=result[1];
    if(value != '' && ph.length === 14){
      setofficePhoneValidation('');
    }
    else{
      setofficePhoneValidation("Phone No is required");
    }
    setofficePhone(value)
  }
  const onchangeprofessionType=(value)=>{
    const arr = profTypeList.filter(st => st.type === value);
    console.log("sa",arr)
    if (!value.trim()) {
      setValidation({professionType:"profession type is required"});
    } else {
      setValidation({professionType:""});
    }
    setprofessionType(value);
    setprofessionTypeId(arr[0].id)
  }
  const phoneChange=(value)=> {
    const result = value.split(' ');
    const ph=result[1];
    if(value != '' && ph.length === 14){
      setPhoneValidation('');
    }
    else{
      setPhoneValidation("Phone No is required");
    }
    setPhone(value)
    if(value == oldPhoneNo){
      setisPhoneChanged(false);
      setmobileVerified(true);
    }
    else{
      setisPhoneChanged(true);
      setmobileVerified(false);
    }
    }
    const checkfbUrlValidity = (value) => {
      if(value.trim()){
        try {
          new URL(value);
          setIsValid({facebookUrl:''});
        } catch (error) {
          setIsValid({facebookUrl:'Invalid Url'});
        }
    }
    else
      setIsValid({facebookUrl:''});
    setFacebookUrl(value);
  }
  const checktwUrlValidity = (value) => {
    if(value.trim()){
      try {
        new URL(value);
        setIsValid({twitterUrl:''});
      } catch (error) {
        setIsValid({twitterUrl:'Invalid Url'});
      }
    }
    else
      setIsValid({twitterUrl:''});
    setTwitterUrl(value)
  }
    const checklInUrlValidity = (value) => {
    if(value.trim()){
      try {
        new URL(value);
        setIsValid({linkedInUrl:''});
      } catch (error) {
        setIsValid({linkedInUrl:'Invalid Url'});
      }
    }
    else
      setIsValid({linkedInUrl:''});
    setLinkedInUrl(value)
  }
  const checkIgUrlValidity = (value) => {
    if(value.trim()){
      try {
        new URL(value);
        setIsValid({instagramUrl:''});
      } catch (error) {
        setIsValid({instagramUrl:'Invalid Url'});
      }
    }
    else
      setIsValid({instagramUrl:''});
    setInstagramUrl(value)
    };
  const mobileverifyset=(value)=> {
    setMobilePopupShow(value)
  }
  const handleImageChange = (event) => {
    let allowedfiletypes=['jpg','svg','jpeg','png','bmp','gif','jfif']
    if(event.target.files.length>0){
      let ext = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1);
      const isnotFound = allowedfiletypes.some(element => {
      if (element === ext) { return true;}return false;
      });
      if(isnotFound){
        setImagePreview(URL.createObjectURL(event.target.files[0]))
        setLogoimagePath(event.target.files[0])
      }
      else{
        toast.error(config.FileFormatErr, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        });
      }
    }
  }
  const updateProfInfo=(profInfo)=>{
    axios.post(process.env.REACT_APP_BASEURL +'/user/professional/add', profInfo)
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        loadsprofileInfo();
        setLoading(false);
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false)
      }
    })
    .catch(error => {
      console.log(error);
      setLoading(false)
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  const updateInfo=(userInfo)=>{
    axios.post(process.env.REACT_APP_BASEURL +'/user/edit/'+ props.userId, userInfo)
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        loadsprofileInfo();
        setLoading(false)
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false)
      }
    })
    .catch(error => {
      console.log(error);
      setLoading(false)
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  const onSubmit = (type) => {
    let userInfo = {'firstName':firstName,'lastName':lastName,'email':email,'phone':phone,'state':state,
    'city':city,'birthYear':year,'imagePath':imagePath,'business':businessInfo,'professional':professionInfo,
    'isPublic':isPublic,'isPhoneModified':isPhoneChanged};
    if(type =='personal'){
    phoneValidate();
    if(year < new Date().getFullYear() && year >=1900){
    if(firstName && city && state && year && lastName && (phone!='' && phone!='+1 undefined' 
    && phone!='+91 undefined') && !phoneValidation){
      setLoading(true)
      updateInfo(userInfo);
    }
    else{
    toast.error('Please fill out all required fields.', {
      theme: 'colored',
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    }
    }
    else{
      toast.error('Invalid Birth Year', {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
    else if(type =='professional'){
      let userProfInfo = {'id':props.userId,'firstName':firstName,'lastName':lastName,'email':email,'professional':professionInfo};
      if(professionType.trim() && officePhone){
        setLoading(true)
        updateProfInfo(userProfInfo);
      }
    }
    else{
      setLoading(true)
      updateInfo(userInfo);
    }
  }
  const editCancel=()=>{
    removeFill();
    setHideEdit(true);
  }
  const editProCancel=()=>{
    removeproFill();
    setHideProEdit(true);
  }
  // const updateProfessionInfo=()=>{
  //   phoneValidate();
  //   if (!professionType.trim()) {
  //     setValidation({professionType:"profession type is required"});
  //   } else {
  //     setValidation({professionType:""});
  //   }
  //   const profData = professionInfo.map((obj,idx) => {
  //     if(idx == editedProIdx){
  //       if(professionType!='' && !officephoneValidation){
  //       obj.professionType = professionType;
  //       obj.officePhone = officePhone;
  //       obj.officeAddress = officeAddress;
  //       setValidation({professionType:''});
  //       removeproFill();
  //       setHideProEdit(true);
  //     }
  //   else{
  //     toast.error('Please fill out required fields.', {
  //       theme: 'colored',
  //       position: 'top-right',
  //       autoClose: 1500,
  //       hideProgressBar: false,
  //       progress: undefined,
  //     });
  //     }
  //     }
  //     return obj;
  //    })
  //    setprofessionInfo(profData)
  // }
  const UpdateProfessionInfo=()=>{
    phoneValidate();
    if (!professionType.trim()) {
      setValidation({professionType:"profession type is required"});
    } else {
      setValidation({professionType:""});
    }
    const profData = professionInfo.map((obj,idx) => {
      if(idx == editedProIdx){
        if(professionType!='' && !officephoneValidation){
        obj.professionType = professionType;
        obj.professionTypeId = professionTypeId;
        obj.officePhone = officePhone;
        obj.officeAddress = officeAddress;
        setValidation({professionType:''});
        onSubmit('professional');
      }
    else{
      toast.error('Please fill out required fields.', {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        progress: undefined,
      });
      }
      }
      return obj;
     })
     setprofessionInfo(profData)
  }
  const updateBusinessInfo=()=>{
    const bussData = businessInfo.map((obj,idx) => {
      if(idx == editedIdx){
        if(cmpyName!=''){
          obj.companyName = cmpyName;
          obj.phone = cmpyPhone;
          obj.email = cmpyEmail;
          obj.city = cmpyCity;
          obj.state = cmpyState;
          setValidation({cmpyName:''});
          removeFill();
          setHideEdit(true)
        }
        else{
          toast.error('Please fill out required fields.', {
            theme: 'colored',
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            progress: undefined,
          });
          setValidation({cmpyName:'Company name is required'});
        }
      }
      return obj;
     })
     setbusinessInfo(bussData)
  }
  const removeFill=()=>{
    setcmpyName("");
    setcmpyPhone("");
    setcmpyEmail("");
    setcmpyState("");
    setcmpyCity("");
    setcmpyDescription("");
  }
  const removeproFill=()=>{
    childCompRef.current.clrField();
    setofficePhone("");
    setofficeAddress("");
    setprofessionType("");
  }
  const editBusiness=(value,idx)=>{
    setEditedIdx(idx)
    setHideEdit(false);
    setcmpyName(value.companyName);
    setcmpyPhone(value.phone);
    setcmpyEmail(value.email);
    setcmpyState(value.state);
    setcmpyCity(value.city);
  }
  const editProfession=(value,idx)=>{
    setEditedProIdx(idx)
    setHideProEdit(false);
    console.log("aD",value.officePhone)
    setofficePhone(value.officePhone);
    setofficeAddress(value.officeAddress);
    setprofessionType(value.professionType);
  }
  const verifyMobileCode = ()=>{
    if(verificationCode){
    axios.post(process.env.REACT_APP_BASEURL +'/user/verify/verificationcode',{'id':props.userId,'type':'phone','verificationCode':verificationCode})
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        loadsprofileInfo();
        setVerifcationCode('');
        setMobilePopupShow(false)
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch(error => {
      toast.error('Invalid OTP', {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setVerifcationCode('');
  });
  }
  else{
    setemptyErr(true);
  }
  }
  const verifyEmailCode = ()=>{
    if(verificationCode){
      axios.post(process.env.REACT_APP_BASEURL +'/user/verify/verificationcode',{'id':props.userId,'type':'email','verificationCode':verificationCode})
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        loadsprofileInfo();
        setVerifcationCode('');
        setEmailPopupShow(false)
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch(error => {
      toast.error('Invalid OTP', {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setVerifcationCode('');
      console.log(error);
  });
  }else{
    setemptyErr(true);
  }
  }
  const addUserBusinessInfo = () => {
    if (!cmpyName.trim()) {
      setValidation({cmpyName:'Company name is required'});
    } else {
      setValidation({cmpyName:''});
    }
      if(validation.cmpyName==''){
      const isFound = businessInfo.some(element => {
        if (element.companyName == cmpyName) {
          return true;
        }
        return false;
      });
      if(!isFound){
        let businessInfo = [];
        setbusinessInfo(businessInfo => [...businessInfo, {'companyName':cmpyName,'phone':cmpyPhone,'email':cmpyEmail,'city':cmpyCity,'state':cmpyState,'description':cmpyDescription,'logo':logoimagePath}]);
        removeFill();
        console.log("SAdsa",hideEdit)
      }
      else{
        toast.error('Company Name already exists', {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      }
  else{
    toast.error('Please fill out required fields.', {
      theme: 'colored',
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  }
  const AddprofessionalInfo = ()=>{
    phoneValidate();
    if (!professionType.trim()) {
      setValidation({professionType:"profession type is required"});
    } else {
      setValidation({professionType:""});
    }
    if( professionType.trim() && validation.professionType=='' && !officephoneValidation){
      var result = [{'professionType':professionType,'professionTypeId':professionTypeId,'officePhone':officePhone,'officeAddress':officeAddress}, ...professionInfo]
      setprofessionInfo(result);
      onSubmit('professional');
    }
    else{
      toast.error('Please fill out required fields.', {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  // const addUserProfessionInfo = () => {
  //   phoneValidate();
  //   if (!professionType.trim()) {
  //     setValidation({professionType:"profession type is required"});
  //   } else {
  //     setValidation({professionType:""});
  //   }
  //   if( professionType.trim() && validation.professionType=='' && !officephoneValidation){
  //     var result = [{'professionType':professionType,'officePhone':officePhone,'officeAddress':officeAddress}, ...professionInfo]
  //     setprofessionInfo(result);
  //     removeproFill();
  //   }
  //   else{
  //     toast.error('Please fill out required fields.', {
  //       theme: 'colored',
  //       position: 'top-right',
  //       autoClose: 1500,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // }
  const verifyMobile = () =>{
    axios.post(process.env.REACT_APP_BASEURL +'/user/send/verificationcode',{'id':props.userId,'type':'phone'})
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        start();
        setIsMobileCodeSend(true)
        setisPhoneChanged(false);
        setVerifcationCode('');
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch(error => {
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  }
  const verifyEmail = () =>{
    // setEmailPopupShow(false)
    axios.post(process.env.REACT_APP_BASEURL +'/user/send/verificationcode',{'id':props.userId,'type':'email'})
    .then(response => {
      if(response.data.status == "OK"){
        toast.success(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        start();
        setIsEmailCodeSend(true)
        setVerifcationCode('')
      }
      else{
        toast.error(response.data.message, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch(error => {
      if(error){
      toast.error('Invalid otp', {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  });
  }
  const createImage = (image)=>{
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePath(e.target.result);
    };
    reader.readAsDataURL(image);
  }
  const uploadImage = (event) => {
    if(event.target.files[0]){
    if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(event.target.files[0].name)) {
        toast.error(config.FileFormatErr, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
    }  
    setLoading(true)
    console.log("event.target.files[0].name",event.target.files[0].name)
    const myNewFile = new File([event.target.files[0]], event.target.files[0].name.replace(/\s/g, ''), {type: event.target.files[0].type});
    console.log("event.target.files[0].name55",myNewFile.name)
    createImage(myNewFile);
    let formData = new FormData();
    formData.append('file', myNewFile);
    formData.append('id', userId);
    axios.post(process.env.REACT_APP_BASEURL +'/user/upload/photo',
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(response =>{
          if(response.data.status=="OK"){
            loadsprofileInfo();
            setLoading(false)
            toast.success(config.CmpyLogoUpSuc, {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
          else if(response.data.status=="EXPECTATION_FAILED"){
            setLoading(false)
            toast.error(response.data.message, {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(function(error) {
            console.log(error)
            setLoading(false)
        });
      }
  };
  const deleteImage = () =>{
    if(imagePath){
    setLoading(true)
    axios.delete(process.env.REACT_APP_BASEURL +'/user/image/delete/'+userId
        ).then(response =>{
          if(response.data.status=="OK"){
            loadsprofileInfo();
            setLoading(false)
            toast.success(response.data.message, {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            setDeletePopupShow(false);
          }
          else if(response.data.status=="EXPECTATION_FAILED"){
            setLoading(false)
            toast.error(response.data.message, {
              theme: 'colored',
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDeletePopupShow(false);
          }
        })
        .catch(function(error) {
          setLoading(false)
          setDeletePopupShow(false);
          console.log(error)
        });
      }
      else{
        toast.warning(config.DelImageErr, {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  }
  return (
    <>
    <HeaderMenu/>
        <div className="">
            <SideBar/>
            {displayContent?<div className="main-content ml_200">
              <div className="pro_tab_div">
              <Tabs
                  defaultActiveKey="Profile"
                  id="profile_tab"
                  className="mb-2"
                >
                  <Tab eventKey="Profile" title={<span className='pro_tab_span'><IoPersonCircleSharp className="dashboardTabIcon"/>Profile</span>}>
                  <div className="pro_container mt-2">
                    <div className="profile_ml">
                    {loading ?<div className="pro-loader-center">
                    <img className="loader-image" src={loader} alt="Loading..." /></div>:''}
                      <div className="profile_div">
                      <Container className="pb-0" fluid>
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary profile_card">
                              <Card.Header className="bg-white border-0">
                                <Row>
                                  <Col lg={12} md={12} sm={12}>
                                   <h3 className="mb-0 fz-22 text-center alert-link">Profile Information</h3>
                                  </Col>
                                  <Col lg={12} md={12} sm={12}>
                                  {tiipstrSince!=null? <div className="float-right fz-15 font-weight-500">tiipstr since -&nbsp;
                                    <span className="f13_clr">{formatDate(tiipstrSince)}</span></div>:''}
                                  </Col>
                                </Row>
                              </Card.Header>
                              <Card.Body>
                              <Row>
                                <Col lg="4" md="4" sm="12">
                                <Row className="justify-content-center">
                                <div className="card-profile-image">
                                  <img className='imagePreview' src={imagePath ? imagePath : profile}/>
                                    <label className="upload-icon"><TiCamera className="uploadfont"/><input id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" style={{width: '0px',height: '0px',overflow: 'hidden'}} onChange={uploadImage}/></label>
                                  <label className="delete-icon"><AiOutlineClose className="closefont" onClick={()=>setDeletePopupShow(true)} /></label>
                                  </div>
                                  </Row>
                                  {/* <Row className="justify-content-center">
                                    <div className="qr_image">
                                    <img src={Qrcode} height={70} width={70}/>
                                    </div>
                                  </Row> */}
                                  <Row className="justify-content-center"> 
                                      <div className="text-center fz-22 alert-link">{firstName} {lastName}</div>
                                  </Row>
                                </Col>
                                <Col lg="8" md="8" sm="12">
                                <Form>
                                <div className="">
                                  <Row>
                                    <Col lg="6">
                                      <Form.Group className="form-group"> 
                                      <Form.Label className="mb-1 profile_label">First Name</Form.Label>
                                        <Form.Control type="text" className={!firstName ? "form-control input_ctrl error" : "form-control input_ctrl"}  
                                        onChange={(e) => setFirstName(e.target.value)}  value={firstName} placeholder="first name"/>
                                        {!firstName && <p className="fz-13">First Name is required</p>}
                                      </Form.Group >
                                    </Col>
                                    <Col lg="6">
                                      <Form.Group className="form-group">
                                      <Form.Label className="mb-1 profile_label">Last Name</Form.Label>
                                        <Form.Control  type="text" className={!lastName ? "form-control input_ctrl error" : "form-control input_ctrl"}
                                        onChange={(e) => setLastName(e.target.value)}  value={lastName} placeholder="last name"/>
                                        {!lastName && <p className="fz-13">Last Name is required</p>}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <PhoneControl phoneNo={phone} unmounted={true} isProfile={true} mandatory={true} mobileVerify={true} PopupShow={mobileverifyset} isverified={mobileVerified} isPhoneChanged={isPhoneChanged} onPhoneChange={phoneChange}  phoneValidation={phoneValidation}/>
                                    </Col>
                                    <Col lg="6">
                                    <Form.Group class="form-group required-field-block">
                                      <Form.Label className="mb-1 profile_label">Email Address</Form.Label>
                                          <Form.Control type="email" className="form-control input_ctrl" title={email.length > 30?email:''} value={email.length > 30?email.substring(0, 30).concat('...'):email} disabled placeholder="email" name="email" maxlength="80"/>
                                          {email != '' && <span class="required-icon">
                                            {!emailVerified?<a href="#" onClick={()=>setEmailPopupShow(true)}>
                                              <BsExclamationCircle className={email != '' && emailVerified? "" : "text-danger"} style={{fontSize:'20px'}}/>
                                            </a>:<a href="#">
                                              <HiCheckCircle style={{fontSize:'20px',color:'green'}}/>
                                            </a>}</span>}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                  <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label className="mb-1 profile_label">City</Form.Label>
                                      <Form.Control type="text" className={!city? "form-control input_ctrl error" : "form-control input_ctrl"} 
                                      onChange={(e) => setCity(e.target.value)}  value={city} placeholder="city"/>
                                      {!city && <p className="fz-13">City is required</p>}
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group className="form-group">
                                    <Form.Label className="mb-1 profile_label">State</Form.Label>
                                      <Form.Control type="text" className={!state? "form-control input_ctrl error" : "form-control input_ctrl"} 
                                      onChange={(e) => setState(e.target.value)}  value={state} placeholder="state"/>
                                      {!state && <p className="fz-13">State is required</p>}
                                    </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                  <Col lg="6">
                                    <Form.Group className="form-group" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}>
                                    <Form.Label className="mb-1 profile_label">Birth Year</Form.Label>
                                      <Form.Control type="text" className={!year? "form-control input_ctrl error" : "form-control input_ctrl"} 
                                      onChange={(e) => setYear(e.target.value)}  value={year} placeholder="birth year" maxLength={4}/>
                                      {!year && <p className="fz-13">Birth Year is required</p>}
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                  <div className="d-flex justify-content-center">
                                    <div className="d-flex mt-2 toggle_gap">
                                      <label className="font-weight-500 fz_14">{isPublic?'Public Account':'Private Account'}</label>
                                           <label className="toggle-switch">
                                            <input type="checkbox" checked={isPublic} onChange={onToggle} />
                                            <span className="switch" />
                                        </label>
                                      </div>
                                  </div>
                                  </Col>
                                  </Row>
                                </div>
                              </Form>
                                </Col>
                              </Row>
                              {/* {isPublic && <Row>
                              <Col lg="4" md="4" sm="12">
                              </Col>
                              <Col lg="8" md="8" sm="12">
                                <div className="div_br">
                                  <Col lg={12} md={12} sm={12}>
                                   <h3 className="mb-0 fz-22 text-center alert-link">
                                  <div className="social_hr">
                                      <h5>Social</h5>
                                </div></h3>
                              <Form>
                                <div className="">
                                  <Row>
                                  <Col lg="6">
                                      <Form.Group className="form-group"> 
                                      <Form.Label className="mb-1 profile_label">Facebook Url</Form.Label>
                                        <Form.Control type="text" className="form-control input_ctrl" 
                                        onChange={(e) => checkfbUrlValidity(e.target.value)}  value={facebookUrl}/>
                                      {isValid.facebookUrl !=='' && <p className="fz-13">{isValid.facebookUrl}</p>}
                                      </Form.Group >
                                    </Col>
                                    <Col lg="6">
                                      <Form.Group className="form-group">
                                      <Form.Label className="mb-1 profile_label">Twitter Url</Form.Label>
                                        <Form.Control  type="text" className="form-control input_ctrl"
                                        onChange={(e) => checktwUrlValidity(e.target.value)}  value={twitterUrl}/>
                                      {isValid.twitterUrl !=='' && <p className="fz-13">{isValid.twitterUrl}</p>}                                    
                                      </Form.Group>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col lg="6">
                                      <Form.Group className="form-group"> 
                                      <Form.Label className="mb-1 profile_label">LinkedIn Url</Form.Label>
                                        <Form.Control type="text" className="form-control input_ctrl"
                                        onChange={(e) => checklInUrlValidity(e.target.value)}  value={linkedInUrl}/>
                                      {isValid.linkedInUrl !=='' && <p className="fz-13">{isValid.linkedInUrl}</p>}
                                      </Form.Group >
                                    </Col>
                                    <Col lg="6">
                                      <Form.Group className="form-group">
                                      <Form.Label className="mb-1 profile_label">Instagram Url</Form.Label>
                                        <Form.Control  type="text" className="form-control input_ctrl"
                                        onChange={(e) => checkIgUrlValidity(e.target.value)}  value={instagramUrl}/>
                                      {isValid.instagramUrl !=='' && <p className="fz-13">{isValid.instagramUrl}</p>}
                                      </Form.Group>
                                    </Col>
                                    </Row>
                                    </div>
                                    </Form>
                                  </Col>
                                  </div>
                                  </Col>
                                  </Row>} */}
                                <div className="pl-lg-4 pt-3 text-center">
                                  <Button onClick={()=>onSubmit('personal')} className="btn btn-default update_btn btn-primary">Update</Button>
                                </div>
                                  </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </div>
                  </Tab>
                  {false && <Tab eventKey="Business" title={<span className='pro_tab_span'><FaBusinessTime  className="dashboardTabIcon"/>Business</span>}>
                  <div className="pro_container">
                    <div className="profile_ml">
                    {loading ?<div className="pro-loader-center"><img className="loader-image" src={loader} alt="Loading..." /></div>:''}
                      <div className="profile_div">
                      <Container className="pb-0" fluid>
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary profile_card">
                              <Card.Header className="bg-white border-0">
                                <Row className="align-items-center">
                                  <Col xs="12">
                                    <h3 className="mb-0 text-center fz-22 alert-link">Business Information</h3>
                                  </Col>
                                  <Col className="text-right" xs="4">
                                  </Col>
                                </Row>
                              </Card.Header>
                              <Card.Body>
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <Form>
                              <div className="">
                                <Row>
                                  <Col lg="3">
                                    <Form.Group className="form-group"> 
                                    <Form.Label className="mb-1 profile_label">Company Name</Form.Label>
                                    <Form.Control type="text" className={validation.cmpyName? "form-control input_ctrl error" : "form-control input_ctrl"}
                                                  onChange={(e) => setcmpyName(e.target.value)}  value={cmpyName}/>
                                    </Form.Group >
                                  </Col>
                                  <Col lg="3">
                                    <Form.Group className="form-group">
                                    <Form.Label className="mb-1 profile_label">Phone No</Form.Label>
                                    <Form.Control type="text" className="form-control input_ctrl"   
                                                onChange={(e) => setcmpyPhone(e.target.value)} value={cmpyPhone} maxLength={10}/>
                                    </Form.Group>
                                  </Col>
                                <Col lg="3">
                                  <Form.Group class="form-group required-field-block">
                                    <Form.Label className="mb-1 profile_label">Email Address</Form.Label>
                                    <Form.Control type="text" className="form-control input_ctrl" onChange={(e) => setcmpyEmail(e.target.value)} value={cmpyEmail}/>
                                    </Form.Group>
                                  </Col>
                                <Col lg="3">
                                <Form.Group className="form-group">
                                  <Form.Label className="mb-1 profile_label">City</Form.Label>
                                  <Form.Control type="text" className="form-control input_ctrl"  
                                                onChange={(e) => setcmpyCity(e.target.value)} value={cmpyCity}/>
                                  </Form.Group>
                                </Col>
                                </Row>
                                <Row>
                                <Col lg="3">
                                  <Form.Group className="form-group">
                                  <Form.Label className="mb-1 profile_label">State</Form.Label>
                                  <Form.Control type="text" className="form-control input_ctrl"  
                                                onChange={(e) => setcmpyState(e.target.value)} value={cmpyState}/>
                                  </Form.Group>
                                  </Col>
                                  
                                <Col lg="3">
                                  <Form.Group className="form-group">
                                  <Form.Label className="mb-1 profile_label">Description</Form.Label>
                                  <textarea type="text" className="form-control text-area input_ctrl"  
                                                onChange={(e) => setcmpyDescription(e.target.value)} value={cmpyDescription}/>
                                  </Form.Group>
                                </Col>
                                {false && <Col lg="3">
                                    <input className="form-control mt_30 input_ctrl"/></Col>}
                                  {false && <Col lg="3">
                                <button className="logo_upload w_300" >Click to upload logo</button>
                                <input type="file" accept=".rtf,.doc,.docx,.pdf,.DOCX,video/mp4,video/x-m4v,video/*" className="file_click_up" onClick={handleImageChange}/>
                                </Col>}
                                </Row>
                              </div>
                              {hideEdit?<div className="pt-3 pb-3 text-center d-flex justify-content-center">
                                <Button onClick={addUserBusinessInfo} className="btn btn-default update_btn btn-primary float-left">Add</Button>
                              </div>:
                              <div className="pt-3 pb-3 text-center d-flex justify-content-center">
                                <Button onClick={editCancel} className="btn btn-default update_btn btn-primary float-left">Cancel</Button>
                              <Button onClick={updateBusinessInfo} className="btn btn-default update_btn btn-primary float-left">Update</Button>
                            </div>}
                              
                              </Form>
                              </Col>
                                </Row>
                                {businessInfo.length>0 && 
                                <Table className="align-items-center table-flush brd_solid" responsive>
                                  <thead className="thead-clr">
                                  <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Email Id</th>
                                    <th scope="col">Phone No</th>
                                    <th scope="col">City</th>
                                    <th scope="col">State</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                  <tbody>
                                  {businessInfo.length>0 && businessInfo.map((item, i) => (<tr>
                                    <th scope="col">{i+1}</th>
                                    <th scope="col">{item.companyName}</th>
                                    <th scope="col" title={item.email}>{item.email.substring(0,13).concat('...')}</th>
                                    <th scope="col">{item.phone}</th>
                                    <th scope="col">{item.city}</th>
                                    <th scope="col">{item.state}</th>
                                    <th scope="col"> <a href="#" onClick={e => editBusiness(item,i)}>
                                            <FiEdit style={{color: '#144689',fontSize: '18px'}}/></a></th>
                                  </tr>))}
                                  </tbody>
                              </Table>}
                              {businessInfo.length>0 && <Row>
                                <Col lg="12" md="12" sm="12" className="d-flex justify-content-center pt-4">
                                  <Button className="btn btn-default update_btn btn-primary" onClick={()=>onSubmit('business')}>Save</Button>
                                </Col>
                              </Row>}
                            </Card.Body>
                            </Card>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </div>
                  </Tab>}
                  {false && <Tab eventKey="Professional" title={<span className='pro_tab_span'><FaUserTie   className="dashboardTabIcon"/>Professional</span>}>
                  <div className="pro_container">
                    <div className="profile_ml">
                    {loading ?<div className="pro-loader-center">
                      <img className="loader-image" src={loader} alt="Loading..." /></div>:''}
                      <div className="profile_div">
                      <Container className="pb-0" fluid>
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary profile_card">
                              <Card.Header className="bg-white border-0">
                                <Row className="align-items-center">
                                  <Col xs="12">
                                     <h3 className="mb-0 text-center fz-22 alert-link">Professional Details</h3>
                                  </Col>
                                  <Col className="text-right" xs="4">
                                  </Col>
                                </Row>
                              </Card.Header>
                        <Card.Body>
                            {/* <Row>
                          <Col lg="12" md="12" sm="12">
                          <Form>
                          <div className="">
                            <Row>
                              <Col lg="4">
                                <Form.Group className="form-group"> 
                                <Form.Label className="mb-1 profile_label">Profession Type</Form.Label>
                                <div className="d-flex">
                                <select className={validation.professionType? "form-control input_ctrl error" : "form-control input_ctrl"} value={professionType} onChange={(e) => onchangeprofessionType(e.target.value)}>
                                <option value="">Select</option>
                                  {profTypeList && profTypeList.map((profType) =>(<option value={profType.type}>{profType.type}</option>))}
                                </select>
                                &nbsp;<FaGear className="m-2 c_pointer" onClick={()=>setEditProfessionType(true)}/></div>
                                </Form.Group >
                              </Col>
                              <Col lg="4">
                                <Form.Group className="form-group">
                                <Form.Label className="mb-1 profile_label">Office Address</Form.Label>
                                <textarea type="text" className="form-control text-area input_ctrl"  
                                            onChange={(e) => setofficeAddress(e.target.value)} value={officeAddress}/>
                                </Form.Group>
                              </Col>
                            <Col lg="4">
                            <PhoneControl phoneNo={officePhone} unmounted={true} mandatory={true} onPhoneChange={officephoneChange} phoneValidation={officephoneValidation} ref={childCompRef}/>
                            </Col>
                            </Row>
                            </div>
                          {hideProEdit?<div className="pt-3 pb-3 text-center d-flex justify-content-center">
                            <Button onClick={addUserProfessionInfo} className="btn btn-default update_btn btn-primary float-left">Add</Button>
                          </div>:
                          <div className="pt-3 pb-3 text-center d-flex justify-content-center">
                             <Button onClick={editProCancel} className="btn btn-default update_btn btn-primary float-left">Cancel</Button>
                          <Button onClick={updateProfessionInfo} className="btn btn-default update_btn btn-primary float-left">Update</Button>
                        </div>}
                          
                      </Form>
                          </Col>
                          <Col lg="12" md="12" sm="12">
                            {professionInfo.length>0 && 
                                <Table className="align-items-center table-flush brd_solid" responsive>
                                  <thead className="thead-clr">
                                  <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">Profession Type</th>
                                    <th scope="col">Phone No</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                  <tbody>
                                  {professionInfo.length>0 && professionInfo.map((item, i) => (<tr>
                                    <th scope="col">{i+1}</th>
                                    <th scope="col">{item.professionType}</th>
                                    <th scope="col">{item.officePhone}</th>
                                    <th scope="col">{item.officeAddress}</th>
                                    <th scope="col"> <a href="#" onClick={e => editProfession(item,i)}>
                                            <FiEdit style={{color: '#144689',fontSize: '18px'}}/></a></th>
                                  </tr>))}
                                  </tbody>
                              </Table>}
                              {professionInfo.length>0 && <Row>
                                <Col lg="12" md="12" sm="12" className="d-flex justify-content-center pt-4">
                                  <Button className="btn btn-default update_btn btn-primary" onClick={onSubmit}>Save</Button>
                                </Col>
                              </Row>}
                              </Col>
                                </Row> */}
                                <Row>
                                <Col lg="4" md="4" sm="12">
                                <Row className="justify-content-center">
                                <div className="card-profile-image">
                                  <img className='imagePreview' src={imagePath ? imagePath : profile}/>
                                    {/* <label className="upload-icon"><TiCamera className="uploadfont"/><input id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" style={{width: '0px',height: '0px',overflow: 'hidden'}} onChange={uploadImage}/></label> */}
                                  {/* <label className="delete-icon"><AiOutlineClose className="closefont" onClick={()=>setDeletePopupShow(true)} /></label> */}
                                  </div>
                                  </Row>
                                  <Row className="justify-content-center"> 
                                      <div className="text-center fz-22 alert-link">{firstName} {lastName}</div>
                                  </Row>
                                </Col>
                                <Col lg="8" md="8" sm="12">
                                <Form>
                                <div className="pl-lg-4">
                                  <Row>
                                    <Col lg="7">
                                    <Form.Group className="form-group"> 
                                    <Form.Label className="mb-1 profile_label">Profession Type</Form.Label>
                                    <div className="d-flex">
                                    <select className={!professionType? "form-control input_ctrl error" : "form-control input_ctrl"} value={professionType} onChange={(e) => onchangeprofessionType(e.target.value)}>
                                    <option value="">Select</option>
                                      {profTypeList && profTypeList.map((profType) =>(<option value={profType.type}>{profType.type}</option>))}
                                    </select>
                                    &nbsp;<FaGear className="m-2 c_pointer" onClick={()=>setEditProfessionType(true)}/></div>
                                  </Form.Group >
                                    <Form.Group className="form-group">
                                      <Form.Label className="mb-1 profile_label">Office Address</Form.Label>
                                      <textarea type="text" className="form-control text-area input_ctrl"  
                                                  onChange={(e) => setofficeAddress(e.target.value)} value={officeAddress}/>
                                      </Form.Group>
                                    <PhoneControl phoneNo={officePhone} unmounted={true} mandatory={true} onPhoneChange={officephoneChange} phoneValidation={officephoneValidation} ref={childCompRef}/>
                                    </Col>
                                  </Row>
                                </div>
                              </Form>
                                </Col>
                              </Row>
                                {professionInfo.length>0?<div className="pl-lg-4 pt-3 text-center">
                                  <Button onClick={UpdateProfessionInfo} className="btn btn-default update_btn btn-primary">Update</Button>
                                </div>:<div className="pl-lg-4 pt-3 text-center">
                                  <Button onClick={AddprofessionalInfo} className="btn btn-default update_btn btn-primary">Add</Button>
                                </div>}
                              </Card.Body>
                              </Card>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </div>
                  </Tab>}
                </Tabs>
                </div>
              {/* ----------- Mobile verification Popup ---------- */}
              {!isMobileCodeSend || isPhoneChanged?<Modal size="sm" show={mobilePopupShow} onHide={() => setMobilePopupShow(false)} >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                    Verify Mobile Number
                    </Modal.Title>
                    <span onClick={()=>{setMobilePopupShow(false)}} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body >
                    <Button onClick={verifyMobile}>Send Verification Code</Button>
                </Modal.Body>
              </Modal>: <Modal size="sm" show={mobilePopupShow} onHide={() => setMobilePopupShow(false)} >
              <Modal.Header>
                  <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                    Verify Mobile Number
                  </Modal.Title>
                  <span onClick={()=>{setMobilePopupShow(false)}} title="Close">
                      <AiOutlineClose />
                  </span>
              </Modal.Header>
              <Modal.Body >
              <div className="fz_11">Enter the OTP send to Mobile No {phone}</div>
                    <div className="input-group code_Input">
                        <input className="form-control py-2 border border-top-0 fz_13" placeholder="Enter OTP" onChange={(e) => setVerifcationCode(e.target.value)}  value={verificationCode}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                    </div>
                    {emptyErr?<span className="fz-13">Enter OTP</span>:''}
                    <div className="d-flex justify-content-between mb-2">
                    <div><span className="fz-12">Didn't receive an OTP?&nbsp;<a href="#" classsName="a_clr fz-12" onClick={verifyMobile}>Resend Code</a></span></div>
                    {timerMinutes === '00' && timerSeconds ==='00' ?'':<div className="float-right fz_13">{timerMinutes === '00' ?
                      <span className="">{timerMinutes}:{timerSeconds}</span>:<span>{timerMinutes}:{timerSeconds}</span>}
                      </div>}
                      {timerMinutes === '00' && timerSeconds ==='00'?
                      <div className="float-right text-warning fz-12">Code Expired</div>:''}
                    </div>
                    <Button onClick={verifyMobileCode} className="fz-12">Verify</Button>
              </Modal.Body>
            </Modal>}
            <Modal size="sm" show={deletePopupShow} onHide={() => setDeletePopupShow(false)} >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                      Delete Confirmation
                    </Modal.Title>
                    <span onClick={() => setDeletePopupShow(false)} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body className='bordr'>
                    <span>Are you sure to delete this profile image?</span>
                </Modal.Body>
                <Modal.Footer className='bordr'>
                    <Button variant="primary" className="del_bttn"  onClick={deleteImage}>Yes</Button>
                    <Button variant="danger" className="del_bttn" onClick={() => setDeletePopupShow(false)}>No</Button>
                </Modal.Footer>
              </Modal>
              {/* -----------Email verification Popup ---------- */}
              {!isEmailCodeSend?<Modal size="sm" show={emailPopupShow} onHide={() => setEmailPopupShow(false)} >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                      Verify Email Id
                    </Modal.Title>
                    <span onClick={()=>{setEmailPopupShow(false)}} title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body >
                    <Button onClick={verifyEmail}>Send Verification Code</Button>
                </Modal.Body>
              </Modal>:
              <Modal size="sm" show={emailPopupShow} onHide={() => setEmailPopupShow(false)} >
              <Modal.Header className="p-3">
                <Modal.Title id="example-custom-modal-styling-title" className="mb-4" style={{fontSize: "15px", fontWeight: 600}}>
                      Verify Email Id
                  </Modal.Title>
                  <span onClick={()=>{setEmailPopupShow(false)}} title="Close">
                      <AiOutlineClose />
                  </span>
              </Modal.Header>
              <Modal.Body className="p-3">
                    <div className="fz_11">Enter the OTP send to Email id {email}</div>
                    <div className="input-group code_Input">
                        <input className="form-control py-2 border border-top-0 fz_13" placeholder="Enter OTP" onChange={(e) => setVerifcationCode(e.target.value)}  value={verificationCode}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}/>
                    </div>
                    {emptyErr?<span className="fz-13">Enter OTP</span>:''}
                    <div className="d-flex justify-content-between mb-2">
                    <div><span className="fz-12">Didn't receive an OTP?&nbsp;<a href="#" classsName="a_clr fz-12" onClick={verifyEmail}>Resend Code</a></span></div>
                    {timerMinutes === '00' && timerSeconds ==='00' ?'':<div className="float-right fz_13">{timerMinutes === '00' ?
                      <span className="">{timerMinutes}:{timerSeconds}</span>:<span>{timerMinutes}:{timerSeconds}</span>}
                      </div>}
                      {timerMinutes === '00' && timerSeconds ==='00'?
                      <div className="float-right text-warning fz-12">Code Expired</div>:''}
                    </div>
                  <Button onClick={verifyEmailCode} className="fz-12">Verify</Button>
              </Modal.Body>
            </Modal>}
            <Modal size="sm" show={editProfessionType} onHide={() => setEditProfessionType(false)}>
            <Modal.Header className="p-3">
              <Modal.Title id="example-custom-modal-styling-title" className="mb-4" style={{fontSize: "15px", fontWeight: 600}}>
                Add
              </Modal.Title>
              <span onClick={()=>{setEditProfessionType(false)}} title="Close">
                  <AiOutlineClose className="c_pointer"/>
              </span></Modal.Header>
            <Modal.Body className="p-3">
                <div className="d-flex">
                <Col lg={11} md={11} sm={11} className="pl-2 pr-2">
                  {/* <input className="form-control py-2 border border-top-0 fz_13"/> */}
                    <Form.Group className="form-group"> 
                        <input type="text" className={validation.newProfType ? "form-control input_ctrl error": "form-control input_ctrl"}
                        onChange={(e) => setNewProfessionType(e.target.value)} value={newProfessionType}/>
                    </Form.Group >
                </Col>
                <Col lg={1} md={1} sm={1} className="pl-2 pr-2 mr-2">
                  <a href="#" ><FaCirclePlus className="circle_plus" onClick={addNewProfessionType}/></a>
                </Col>
                </div>
            </Modal.Body>
            </Modal>
          </div>:
              <div className="pro-loader-center"><img className="loader-image" src={loader} alt="Loading..." /></div>}
        </div>
      <ToastContainer/>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId
  };
};
export default connect(mapStateToProps)(Profile);
