

export const userAction = (data) => ({
    type: 'SAVE_USER_DATA',
    payload: {
      token: data.token ,
      userId: data.userId,
      userType: data.userType,
      userName: data.name,
      imagePath: (data.imagePath != null ? data.imagePath: ''),
      newReviewCount: data.newReviewCount,
      newInboxCount: data.newInboxCount,
      newMentionCount: data.newMentionCount
    }
});

export const logoutAction = () => ({
  type: 'LOGOUT',
});

// Admin

export const userTypeId = (selectedTypeId) => {
  return {
    type: 'USER_TYPE_ID',
    payload: {
      selectedTypeId,
    },
  };
};

export const followId = (followid) => {
  return {
    type: 'FOLLOW_ID',
    payload: {
      followid,
    },
  };
};
export const notifyRevInfo = (revInfo) => ({
    type: 'NOT_REV_INFO',
    payload: {
      id: revInfo.id ,
    }
});
export const revId = (revId) => {
  return {
    type: 'REV_ID',
    payload: {
      revId,
    },
  };
};
export const phoneNumber = (phNumber) => {
  return {
    type: 'PH_NUMBER',
    payload: {
      phNumber,
    },
  };
};
