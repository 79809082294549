import {Button,Card,Form,Col,Row,Container,InputGroup} from "react-bootstrap";
import "../Register/register.css";
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../layout/header';
import Footer from '../../layout/footer';

const About = () => {
  return (
    <>
      <div className="body-content">
        <Header/>
        <div className="header bg-gradient-info py-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>  
        <div className="mt--9 pb-6" style={{backgroundColor:"#172b4d"}}>
        <Row className="justify-content-center">
        <Col lg="10" md="8">
          <Card className="bg-secondary shadow border-0">
            <Card.Body className="px-lg-5" style={{paddingTop: '0px!important'}}>
                <div className="profileForm">
                    <div className="container about">
                        <div className="mt-4 mb-4 b-btm">
                            <h4>About Us</h4>
                        </div>
                        <h5>tiipstr is about providing transparency and truth to social media.</h5>
                        <p className="m-0"><span>The only app that allows you to review your customers, friends, neighbors,  partners, teachers, coaches or any one else  that you deal with! </span></p>
                    </div>
                </div>
            </Card.Body>
          </Card>
        </Col>
        </Row>
        </div>
        <Footer/>
      </div>
      <ToastContainer/>
    </>
  );
};

export default About;
