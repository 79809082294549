import { useRoutes, useLocation, Navigate, useParams } from 'react-router-dom';
import './App.css';
import Header from './layout/header';
import { routes } from './router';
import React from 'react';
import { Provider } from 'react-redux';
import store from './Redux/store';
import Refresh from './components/Refresh';
import ResetPassword from './components/ResetPassword/ResetPassword';

function App() {
  const { token } = useParams(); 
  const pages = useRoutes(routes);
  const location = useLocation();
  const isAuthenticated = store.getState().user.token;
  let tokenNew = null;

const resetPasswordMatch = location.pathname.match(/^\/resetpassword\/([^/]+)/);
if (resetPasswordMatch) {
  tokenNew = resetPasswordMatch[1]; 
}
  const publicPages = [
    '/',
    '/login',
    '/registration',
    '/aboutus',
    '/profile',
    '/privacy-policy',
    '/terms-of-service',
    '/forgotpassword',
    '/shareview',
    `/resetpassword/${tokenNew}`
  ];
  
  React.useEffect(() => {
    if (
      location.pathname === '/profile' || location.pathname === '/forgotpassword' || location.pathname === '/aboutus' || location.pathname === '/terms-of-service' || location.pathname === '/privacy-policy'
    ) {
      document.body.classList.add('bg-default');
    } else {
      document.body.classList.remove('bg-default');
    }
  }, [location]);

  const authRequired = !publicPages.includes(location.pathname);

  if (!isAuthenticated && authRequired) {
    return <Navigate to="/login" />;
  }
 
  if (token) {
    return (
      <Provider store={store}>
        <Refresh>
          <div className="App d-flex flex-column h-100">
            <main className="page-group flex-shrink-0">
              <ResetPassword token={token} />
            </main>
          </div>
        </Refresh>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <Refresh>
        <div className="App d-flex flex-column h-100">
          <main className="page-group flex-shrink-0">{pages}</main>
        </div>
      </Refresh>
    </Provider>
  );
}

export default App;
