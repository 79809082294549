import React from 'react';
import axios from 'axios';
import "../Register/register.css";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {Form,InputGroup} from "react-bootstrap";
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import 'react-toastify/dist/ReactToastify.css';
import { useState,useRef} from 'react';
import LoaderOverlay from '../plugins/LoaderOverlay';
import {GrMail} from 'react-icons/gr'
import {BiSolidLockOpen} from 'react-icons/bi'
import {Button,Card,CardHeader,CardBody, Row, Col,} from "reactstrap";
import { useDispatch } from 'react-redux';
import {userAction} from '../../Redux/Actions/userAction';
// import GoogleLoginButton from '../Login/sociallogin/Googlelogin';
import { IoEye,IoEyeOff } from "react-icons/io5";
const Login = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, register, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [ischecked,setIschecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = (data) => {
    setIsLoading(true);
    axios.post(process.env.REACT_APP_BASEURL +'/user/authenticate', data)
    .then(response => {
      setIsLoading(false);
      if(response.data.result){
        dispatch(userAction(response.data.data));  
        navigate('/dashboard');
  }
  else {
    if(response.data.message == "Invalid Password."){
      toast.error(response.data.message , {
        theme: 'colored',
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }else {
      if(response.data.message == "User not Exists."){
        toast.info(response.data.message , {
          theme: 'colored',
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }
    })
    .catch(error => {
      setIsLoading(false);
      console.log(error);
      toast.error({
          title: 'Error Occured',
          position: 'topRight'
      });
  });
  };
  return (
    <>
     <LoaderOverlay isLoading={isLoading} />
      <div>
        <Row>
        <Col lg="12" md="12" sm="12" xl="12">
        <Row>
          <Col lg="5" md="5" sm="12" xl="5" className='log_bg log_text'>
          <div className="mt-4 mb-3">
            <h5 className='fz_18'>See what they’re saying…</h5>
           </div>
            <div className='brand_logo'></div>
            <div className='tiipstr_logo'></div>
          </Col>
          <Col  lg="7" md="7" sm="12" xl="7" style={{backgroundColor:'white'}}>
          <Header/>
          <div className="d-flex justify-content-center align-items-center copyright bg-content">
          <Col lg="7" md="7" sm="12" xl="5">
                <div className="mt-2">
                     <h4>Login</h4>
                </div>
                <div  style={{paddingTop: '0px!important'}}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="email" className="log_form form-group">
                  <div className="inputContainer">
                  <label className="fz_14">Email</label>
                  <InputGroup className={` ${errors.email ? 'is-invalid' : 'inputBox'}`}>
                    <InputGroup.Text className='border-0'>
                      <GrMail />
                    </InputGroup.Text>
                      <Form.Control
                        className="formControl fz_13"
                          type="text"
                          placeholder="Email"
                          name="email"
                          id="log_mail"
                          {...register('email', {
                            required: 'Email is required',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'Invalid email address',
                            },
                          })}
                      />
                    </InputGroup>
                    {errors.email && (
                      <Form.Text className="text-danger ">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
                <Form.Group controlId="password" className="log_form form-group">
                  <div className="inputContainer">
                  <label className="fz_14">Password</label>
                  <InputGroup className={` ${errors.password ? 'is-invalid' : 'inputBox'}`}>
                    <InputGroup.Text className='border-0'>
                      <BiSolidLockOpen />
                    </InputGroup.Text>
                    <Form.Control
                      className="formControl fz_13"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      name="password" id="log_pass"
                      {...register('password', { required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters!"
                        }
                    })}
                    />
                    <InputGroup.Text className='border-0'>
                    {!showPassword ?<IoEye className='c_pointer' onClick={togglePasswordVisibility}/>:
                    <IoEyeOff className='c_pointer' onClick={togglePasswordVisibility}/>}
                    </InputGroup.Text>
                    </InputGroup>
                    {errors.password && (
                      <Form.Text className="text-danger">
                        {errors.password.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
                <div className='d-flex justify-content-between'>
                  <div><input
                  type="checkbox"
                  checked={ischecked}
                  className='lsRememberMe'
                  onChange={()=>setIschecked(!ischecked)}
                />
                <small className="text-light">&nbsp;Remember me</small></div>
                <div> <a
                    href="#"
                    onClick={() => navigate('/forgotpassword')}
                  >
                    <div className="Acc_sign" style={{color:'#000!important'}}>Forgot password?</div>
                  </a></div>
                </div>
                <div className="text-center">
                <Button variant="primary" type="submit" className="login">
                  Login
                </Button></div>
                <div className="text-center">
                <hr class="hr-text gradient" data-content="OR"/>
                {/* <GoogleLoginButton /> */}
                </div>
                <div className="Acc_sign">Don't have account?  <a
                    href="#"
                    onClick={() => navigate('/registration')}
                  >Sign Up</a></div>
                <div className="text-center pdb_8">
                  {/* <Button onClick={() => navigate('/registration')} variant="secondary" type="button" className="signupButton">
                    Sign Up
                  </Button> */}
                </div>
              </Form>
                </div>
                </Col>
              </div>
          </Col>
        </Row>
        </Col>
        </Row>
      </div>
      <ToastContainer/>
    </>
  );
};

export default Login;
