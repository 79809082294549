
import { Card, Col, Row } from "react-bootstrap";
import axios from '../../axios'
import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { BsPlusLg } from 'react-icons/bs';
import { FaTimes,FaAngleDown   } from 'react-icons/fa';
import { TiTick  } from 'react-icons/ti';
import SideBar from './AdminSideBar';
import HeaderMenu from './HeaderMenu';
import BreadCrumb from '../plugins/Breadcrumb';
import { Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux'; 
import {userTypeId} from '../../Redux/Actions/userAction';
import editRole from '../../assets/images/usr_edit.png';
import roleQues from '../../assets/images/usr_ques.png';
import quesActive from '../../assets/images/ques_active.png';
import ReviewQuestions from './ReviewQuestions';

const UserRoles = () => { 
    const dispatch = useDispatch();
    const typeId = useSelector((state) => state.user.selectedTypeId); 
    const [roleList, setRoleList] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editedRole, setEditedRole] = useState('');
    const [userType, setUserType] = useState('');
    const [selectedTypeId, setSelectedTypeId] = useState('');
    const [newRoleName, setNewRoleName] = useState('');
    const [roleError, setRoleError] = useState('');
    const [roleName, setRoleName] = useState('');
    const [activeRoleId, setActiveRoleId] = useState(null);


    const navigate = useNavigate();
    useEffect(() => {
        loadUserType();
        if(typeId){
            loadRoleList(typeId);
        }
    }, []);
    
    const loadRoleList = (id) =>{
        axios.post(process.env.REACT_APP_BASEURL +'/lookup/userrole/byusertype', {'userTypeId':(id?id:typeId)})
            .then(response => {
                setRoleList(response.data.recordInfo);
                setNewRoleName('');
            })
            .catch(error => {
                console.log(error);
            });  
    }
    const loadUserType = () =>{
        axios.get(process.env.REACT_APP_BASEURL +'/lookup/usertype')
        .then(response => {
            setUserType(response.data.recordInfo);
        })
        .catch(error => {
            console.log(error);
        });  
}
const addUserRole = () =>{

    setRoleError('');

    if (!newRoleName) {
        setRoleError('Role is Required.');
        return;
      }

    const params = {
        userRole: newRoleName,
        userTypeId: (selectedTypeId?selectedTypeId:typeId),
      };
        axios.post(process.env.REACT_APP_BASEURL +'/userrole/add' ,params)
        .then(response => {
            if(response.data.status == "OK"){
                toast.success(response.data.message, {
                  theme: 'colored',
                  position: 'top-right',
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setNewRoleName('');
                loadRoleList(selectedTypeId);
              }
            
        })
        .catch(error => {
            console.log(error);
        });  
}
const updateUserRole = () =>{
    const params = {
        userRole: editedRole.name,
        userTypeId: (selectedTypeId?selectedTypeId:typeId),
      };
        axios.post(process.env.REACT_APP_BASEURL +'/userrole/edit/'+ editedRole.id , params)
        .then(response => {
            if(response.data.status == "OK"){
                toast.success(response.data.message, {
                  theme: 'colored',
                  position: 'top-right',
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setEditMode(false);
                setEditedRole('');
                loadRoleList(selectedTypeId);
              }
            
        })
        .catch(error => {
            console.log(error);
        });  
}
const handleUserType = (event) => {
    const selectedTypeId = event.target.value;
    setSelectedTypeId(selectedTypeId);
    loadRoleList(selectedTypeId);
    setActiveRoleId(null);
    dispatch(userTypeId(selectedTypeId));
  };
    return ( 
        <>
         <HeaderMenu/>
        <SideBar/>
        <div className="main-content ml_200">
        <BreadCrumb/>
        <Card className="shade h-500">
            <Row>
                <Col sm={12} md={12} lg={5} xl={5}>
                <div className='role_container'>    
                <Row className='m_b18 title_txt'>
                    <Col sm={12} md={12} lg={12} xl={12}>
                        Manage User Roles
                    </Col>
                </Row>
                <Row className='m_b18'>
                    <Col sm={6} md={9} lg={9} xl={9} className="custom-col">
                        <select
                            className="rle_place form-control roleClr inptRole"
                            value={(selectedTypeId?selectedTypeId:typeId)}
                            onChange={handleUserType}
                            >
                            <option value="">Select User Type</option>
                            {userType&& userType.map((usrtype) =>(<option value={usrtype.id}>{usrtype.name}</option>))}
                        </select>
                        <div className="arrow-icon">
                        <FaAngleDown /> 
                        </div>
                    </Col>
                </Row>
                {roleList&&  ( <Row className='m_b8'>
                    <Col sm={6} md={9} lg={9} xl={9} className="custom-col"> 
                        <Input
                            className="inptRole rle_place"
                            placeholder={editMode ? "Edit Role" : "Enter a new Role"}
                            type="text"
                            value={editMode ? editedRole.name : newRoleName}
                            onChange={(e) => {
                                if (editMode) {
                                  setEditedRole({ ...editedRole, name: e.target.value });
                                } else {
                                  setNewRoleName(e.target.value);
                                  setRoleError('');
                                }
                              }}
                        />
                        {roleError && <div className="text-danger">{roleError}</div>}
                    </Col>
                    <Col sm={6} md={3} lg={3} xl={3} className="align-items-center custom-col-icn d-flex p-0"> 
                    {editMode ? (
                        <>
                            <TiTick
                                className="c_pointer plus_icn m_r15"
                                onClick={updateUserRole}
                            />
                            <FaTimes
                                className="c_pointer close_icn"
                                onClick={() => {
                                    setEditMode(false);
                                    setEditedRole('');
                                }}
                            />
                        </>
                    ) : (
                        <BsPlusLg onClick={addUserRole} className="c_pointer " />
                    )}
                </Col>
            </Row>)}
                {roleList&& roleList.map((role) =>( <Row>
                    <Col sm={6} md={9} lg={9} xl={9} className="custom-col"> 
                    <Input placeholder="Enter a new Role" className='rle_place m_b8 inptRole' type="text" value={role.name}></Input>
                    </Col>
                    <Col sm={6} md={3} lg={3} xl={3} className="align-items-center custom-col-icn p-0"> 
                    <div className='d-flex' >
                        <div className='m_r15'>
                            <span onClick={() => {
                                setEditMode(true);
                                setEditedRole(role);
                                setRoleError('');
                            }} title="Edit" className="c_pointer"><img src={editRole}/></span>
                        </div>
                        {/* <div className='m_r15'><FaTrashCan title="Delete" style={{ color: '#f16969' }}  className="c_pointer icon_usrRole"/></div>                     */}
                        <div>
                            <span
                            title="Questions"
                            className="c_pointer"
                            onClick={() => {
                                if (activeRoleId === role.id) {
                                setActiveRoleId(null);
                                } else {
                                setActiveRoleId(role.id);
                                }
                                setRoleName(role.name);
                            }}
                            >
                            {activeRoleId === role.id ? <img src={quesActive} /> : <img src={roleQues} />}
                            </span>
                        </div>                    
                    </div>
                    </Col>
                </Row>))}
                </div>
                </Col>
                <Col sm={12} md={12} lg={7} xl={7} className="mt_53 p-0">
                {activeRoleId && <ReviewQuestions id={activeRoleId} name={roleName} />}
                </Col>
                </Row>                
            </Card>
        </div>
        <ToastContainer/>
        </>
    );
}

export default UserRoles;