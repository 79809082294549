
const local_storage_name = process.env.REACT_APP_LOCALSTORAGE_NAME;

const initialState = {
    userId: '',
    token: localStorage.getItem(local_storage_name) || '',
    userName:'',
    imagePath: '',
    userType: '',
    selectedTypeId: '',
    followid:'',
    revId:'',
    phNumber:'',
    newReviewCount:'',
    newInboxCount:'',
    newMentionCount:'',
    id:'',
  };
  
  const userReducer = (state = initialState, action) =>{
    switch(action.type) {
        case 'SAVE_USER_DATA':
          if (action.payload.token) {
            localStorage.setItem(local_storage_name, action.payload.token);
          }
          return { ...state, 
            token: action.payload.token,
            userId: action.payload.userId,
            userName: action.payload.userName,
            imagePath: action.payload.imagePath,
            userType: action.payload.userType,
            newReviewCount: action.payload.newReviewCount,
            newInboxCount: action.payload.newInboxCount,
            newMentionCount: action.payload.newMentionCount
          };
        case 'LOGOUT':
          localStorage.removeItem(local_storage_name);
          return { ...initialState };
        case 'USER_TYPE_ID':
        return {
          ...state,
          selectedTypeId: action.payload.selectedTypeId,
        };
        case 'FOLLOW_ID':
        return {
          ...state,
          followid: action.payload.followid,
        };
        case 'REV_ID':
        return {
          ...state,
          revId: action.payload.revId,
        };
        case 'PH_NUMBER':
          return {
            ...state,
            phNumber: action.payload.phNumber,
          };
        case 'NOT_REV_INFO':
          return { ...state, 
            id: action.payload.id
          };
        default:
          return state
      }
  }
 
  export default userReducer