import { createRoot } from "react-dom/client";
import ViewShare from "../../Home/ViewShare";
import StarRating from './StarRating';
import profile from '../../../assets/images/profile.jpg';
import Insta from '../../../assets/images/insta.jpg';
import { Card, Col, Row,Button} from "react-bootstrap";
import html2canvas from "html2canvas";
import {NavLink} from "react-router-dom";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { BsShareFill} from 'react-icons/bs';
import axios from '../../../axios';
import logoImg from "../../../assets/images/tiipstrlogo.jpg";
import {Helmet} from "react-helmet";
import React, { useState,useEffect,useRef} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { BsQuestionDiamond } from "react-icons/bs";
import { FaSquareXTwitter  } from "react-icons/fa6";
import { FaInstagramSquare  } from "react-icons/fa";
import { IoMdArrowDropdown,IoMdArrowDropup} from 'react-icons/io';
import SideBar from '../SideBar';
import HeaderMenu from '../headerMenu';
import BreadCrumb from '../../plugins/Breadcrumb';
import loader from '../../../assets/images/loader.gif';
import Horizontalbar from '../../plugins/Horizontalbar';
import RatingBar from '../../plugins/RatingBar';
import {FacebookShareButton,TwitterShareButton,TwitterIcon,FacebookIcon,LinkedinShareButton,LinkedinIcon,InstagramShareButton,InstagramIcon} from "react-share";
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {followId} from '../../../Redux/Actions/userAction';
import {listType} from '../../../Redux/Actions/userAction';
import {Overlay, Popover,OverlayTrigger } from 'react-bootstrap';
import MetaData from "../../Home/metaData";
import { BiSolidCommentDetail } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { FiUsers,FiUser } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import mentionInputStyle from '../../../assets/css/MentionStyle';
import { MentionsInput, Mention } from 'react-mentions';
const MeTab = (props) => { 
    const [currentIndex,setcurrentIndex] = useState(null);
    const [globalRating, setGlobalRating] = useState([]); 
    const [revGlobalRtng, setRevGlobalRtng] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [reviewList, setReviewList] = useState([]);
    const [userreviewList, setUserReviewList] = useState([]);
    const [userData, setUserData] = useState({});
    const [showAdditionalReviews, setShowAdditionalReviews] = useState(false);
    const [selectedReviewId, setSelectedReviewId] = useState(null);
    const [isShare, setIsShare] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showReply, setShowReply] = useState(false);
    const [reviewId,setreviewId] = useState('');
    const [reportMsg,setReportMsg] = useState('');
    const [reviewSts,setReviewSts] = useState('');
    const [reportCommentErr,setReportCommentErr] = useState('');
    const [showPopover, setShowPopover] = useState(false);
    const [childComment, setchildComment] = useState('');
    const [childcurrentIndex,setchildcurrentIndex] = useState(null);
    const [childrepplycurrentIndex,setchildreplycurrentIndex] = useState([]);
    const [showChildReply,setShowChildReply] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [shareData,setShareData] = useState({});
    const [comment, setComment] = useState('');
    const [replies,setRevReplies] = useState([]);
    const [revCmnt, setRevCmnt] = useState([]);
    const [avgCount, setAvgCount] = useState('');
    const [expandedCommentIndex, setExpandedCommentIndex] = useState(-1);
    const [cmtError,setCmtError] = useState('');
    const [cmtErrId,setCmtErrId] = useState(null);
    const [userList,setUserList] = useState(''); 
    const targetRef = useRef(null);
    const drpRef = useRef(null);
    const [users,setUsers] = useState([]); 
    const [mentionuserIds, setmentionuserIds] = useState([]);
    const canvasRef = useRef(null);
    const [formData, setFormData] = useState(null);
    const [rating, setRating] = useState(4);
    const [isFbSdkInitialized, setIsFbSdkInitialized] = useState(false);
    const [appAccessToken, setAppAccessToken] = useState(null);
    const [urlLink, setUrlLink] = useState('');

    const readComment = (index) => {
    setExpandedCommentIndex(index === expandedCommentIndex ? -1 : index);
  };
  const handleMention = (mentionedUser) => {
    const word = mentionedUser;
    const match = word.match(/@\[([^\]]+)\]\((\d+)\)/);
    const name = match ? match[1] : null;
    const id = match ? match[2] : null;
    if (name && id) {  
        if(id != props.userId) {        
      dispatch(followId(id));
      navigate('/dashboard/viewuser', { state: { userId: id } });
        }
    } 
  };
  const changeCmtText = (value,type,id)=>{
    var words = value.split(/\s/);
    var contents = words.map(function(word, i) {
        var separator = i < (words.length - 1) ? ' ' : '';
        return word + separator;
    });
if(contents.length!=0){
  var filtercontents = contents.filter(name => name.startsWith('@'))
  var missing = filtercontents.map(function(o1){
    if(o1 != '@' && o1.includes('](')){
        var mention = o1.split('](');
        var men_id = mention[1].replace(')', '');
        return +men_id;
    }
  })
  var uniqueItems = [...new Set(missing)];
  var men_user_ids = uniqueItems.map(x=>{
    return {'id':x}
  })
   setmentionuserIds(men_user_ids)
    }
    let cmtText = contents.reduce((accumulator, item) => accumulator += item, '');
    if(type == 'parent'){
        if(cmtText.length>288){
            var limit_char = cmtText.substring(0,288);
            setComment(limit_char);}
          else
            setComment(cmtText);
    }
    else{
        if(cmtText.length>288){
            var limit_char = cmtText.substring(0,288);
            setchildComment(limit_char);}
          else
            setchildComment(cmtText);
    }
  }
  const rmvMenFormat=(value)=>{
    var mention = value.split('](');
    var men_name = mention[0].replace('[', '').slice(1);
    return men_name;
  }
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@([^\s]+)/g;
    return text.split(' ').map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+)\]\((\d+)\)/);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == props.userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
                  >
                    {rmvMenFormat(mention)}
                </span>
            );
          });
        }
      }
  
      return word;
    });
  }
  const getAppAccessToken = async () => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v14.0/oauth/access_token?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&client_secret=${process.env.REACT_APP_FACEBOOK_APP_SECRET}&grant_type=client_credentials`
      );
      setAppAccessToken(response.data.access_token);
    } catch (error) {
      console.error("Error getting App Access Token:", error);
    }
  };
  const shareImageOnInstagram = async () => {
    try {
      const response = await axios.post(
        `https://graph.instagram.com/v12.0/me/media?access_token=${appAccessToken}`,
        {
          media_url: Insta,
          caption: 'caption',
          media_type: 'IMAGE',
        }
      );
  
      console.log('Image shared successfully:', response.data);
    } catch (error) {
      console.error('Error sharing image on Instagram:', error);
    }
  };
    const OpenInsta = () => {
      if(urlLink){
        const link = document.createElement('a');
        link.href = urlLink;
        link.download = 'review.jpg';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        const instagramUrl = `https://www.instagram.com/?url=${encodeURIComponent(urlLink)}`;
        window.open(instagramUrl, '_blank');
        }
    };
  
  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  }
  const handleArrowClick = (rating,avgCount) => {
    setAvgCount(avgCount);
    const starResponse = rating;
    const starData = starResponse[0];

    const labelValueMap = {};
    for (const label in starData) {
      labelValueMap[label] = parseFloat(starData[label]);
    }
    const sortedLabels = Object.keys(labelValueMap).sort((a, b) => {
      const numA = parseInt(a.split(' ')[0]);
      const numB = parseInt(b.split(' ')[0]);
      return numB - numA;
    });
    const result = sortedLabels.map((label) => ({
      label,
      value: labelValueMap[label],
    }));
    setRevGlobalRtng(result);
    setShowPop(!showPop);
  }
  const handleOverlayClose = () => {
    setShowPopover(false);
    setShowPop(false);
  }
   useEffect(() => {
    if (isShare && shareData) {
      convertToImageAndUpload();
    }
  }, [isShare, shareData]);
    useEffect(() => {
        userDetails();
        userLists();
        getAppAccessToken();
    }, [props]);

    const userLists = () =>{
        axios.get(process.env.REACT_APP_BASEURL +'/lookup/users')
        .then(response => {
            const usersArr = response.data.recordInfo.filter((row)=>{
                return row.id != props.userId;
              })
              const users = usersArr.map(function(row){
                return {display:row.name,id:row.id}
              })
            setUsers(users)
        })
        .catch(error => {
            console.log(error);
        });  
      }
    const Refresh = () =>{
      if(props.authToken){
        axios.post(process.env.REACT_APP_BASEURL +'/user/refresh' , {'authToken':props.authToken})
        .then((response) => {
          if(response.data){
            dispatch({
              type: 'SAVE_USER_DATA',
              payload: {
                token: response.data.data.token ,
                userId: response.data.data.userId,
                userType: response.data.data.userType,
                userName: response.data.data.name,
                imagePath: (response.data.data.imagePath != null ? response.data.data.imagePath: ''),
                newReviewCount: response.data.data.newReviewCount,
                newInboxCount: response.data.data.newInboxCount,
                newMentionCount: response.data.newMentionCount
              },
            }); 
          }
          
        }).catch((error) => {
          console.log("refresh Error")
        });
      }
    }
    const userDetails = () =>{
        if(props.userId){
            setLoading(true)
            axios.get(process.env.REACT_APP_BASEURL +'/user/profile/view/'+ props.userId)
            .then(response => {
                setUserData(response.data.data);
                setUserReviewList(response.data.data.reviewList);
                const starResponse = response.data.data.starRating;
                const starData = starResponse[0];

                const labelValueMap = {};
                for (const label in starData) {
                  labelValueMap[label] = parseFloat(starData[label]);
                }
                const sortedLabels = Object.keys(labelValueMap).sort((a, b) => {
                  const numA = parseInt(a.split(' ')[0]);
                  const numB = parseInt(b.split(' ')[0]);
                  return numB - numA;
                });
                const result = sortedLabels.map((label) => ({
                  label,
                  value: labelValueMap[label],
                }));
                setGlobalRating(result);
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
                setLoading(false)
                toast.error({
                    title: 'Error Occured',
                    position: 'topRight'
                });
            });
        }    
    };
    const handleShare=(data)=>{
        setIsShare(true);
        setShareData(data);
        convertToImageAndUpload();
        handleImageDownload();
    }
    const togglechildcomment=(id)=>{
        setchildComment('');
        if (childrepplycurrentIndex == id) {
            setchildreplycurrentIndex(null)
        } else {
            setchildreplycurrentIndex(id)
        }
    }
    const viewRevComments=(data,replies,sts)=>{
        setShowReply(!showReply);
        setReviewSts(sts);
        setShowChildReply(false);
        loadRevComments(data[0].reviewId)
        setcurrentIndex(null);
        setchildreplycurrentIndex(null);
    }
    const viewChildComments=(data,id,revId)=>{
        setcurrentIndex(null);
        setShowChildReply(!showChildReply);
        if (childcurrentIndex == id) {
            setchildcurrentIndex(null)
        } else {
            setchildcurrentIndex(id)
        }
        loadRevComments(revId);
    }
    const handlerplylikechange=(id,revId)=>{
        console.log("revId",revId)
        updaterplyLikeStatus({'reviewId':revId,'commentId':id,'userId':props.userId,'liked':1})  
    }
    const handlerplydislikechange=(id,revId)=>{
        updaterplyLikeStatus({'reviewId':revId,'commentId':id,'userId':props.userId,'liked':0}) 
    }
    const rplylikecntDecrement=(id,revId)=>{
        updaterplyLikeStatus({'reviewId':revId,'commentId':id,'userId':props.userId,'liked':-1}) 
    }
    const updaterplyLikeStatus=(likeParams)=>{
        axios.post(process.env.REACT_APP_BASEURL +'/review/comment/add/liked',likeParams)
        .then(response => {
            if(response.data.status == "OK"){
                loadRevComments(likeParams.reviewId);
            }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
    }
    const handlelikechange=(id,userId)=>{
        updateLikeStatus({'reviewId':id,'userId':userId,'liked':1})  
    }
    const handledislikechange=(id,userId)=>{
        updateLikeStatus({'reviewId':id,'userId':userId,'liked':0}) 
    }
    const updateLikeStatus=(likeParams)=>{
        axios.post(process.env.REACT_APP_BASEURL +'/review/add/liked',likeParams)
        .then(response => {
          if(response.data.status == "OK"){
            userDetails();
            setShowAdditionalReviews(false)
          }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
    }
    const handleReport=(event)=>{
        setReportMsg(event.target.value)
        if(event.target.value){
            setReportCommentErr('');
        }
    }
    const likecntDecrement=(id,userId)=>{
        updateLikeStatus({'reviewId':id,'userId':userId,'liked':-1}) 
    }
    const loadReviewList=(id)=>{
        axios.post(process.env.REACT_APP_BASEURL +'/review/list/reviewerid',{'userId':id})
        .then(response => {
            console.log(response)
          if(response.data.status == "OK"){
            let result = response.data.recordInfo;
            setReviewList(result)
          }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
      };
      const toggleAdditionalReviews = (reviewId) => {
        if (selectedReviewId === reviewId) {
            setSelectedReviewId(null);
            setShowAdditionalReviews(false);
        } else {
            setSelectedReviewId(reviewId);
            setShowAdditionalReviews(true);
            loadReviewList(reviewId);
        }
    };
    const sendReport=(id)=>{
        setreviewId(id);
        setShowReport(true);
    }
    const closeReportModal=()=>{
        setReportCommentErr('');
        setShowReport(false);
    }
    const CloseShare = () =>{
      setIsShare(false);
      setUrlLink('');
    }
    const handleNameClick = (userId) =>{
        dispatch(followId(userId));
        navigate('/dashboard/viewuser', { state: { userId: userId } });
      }
    const submitReport=()=>{
        console.log('reportMsg',reportMsg)
        if (reportMsg.trim().length!=0) {
        if(reviewId){
        axios.post(process.env.REACT_APP_BASEURL +'/reports/add',{"reviewId":reviewId,"reporterId":props.userId,"additionalInfo":reportMsg})
        .then(response => {
            console.log(response)
          if(response.data.status == "OK"){
                toast.success('Report added successfully', {
                  theme: 'colored',
                  position: 'top-right',
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setShowReport(false);
                userDetails();
                setShowAdditionalReviews(false);
          }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
        });
        }
    }
    else{
        setReportCommentErr('Report Comment is required.');
    }
    }
    const handleFacebookShare = () => {
    
            document.querySelector('meta[property="og:title"]').setAttribute("content", "newGivenTitle");
            document.querySelector('meta[property="og:description"]').setAttribute("content", "changedDescription");
    
            const urlToScrape = 'http://18.223.205.99/';
    
            const fbDebuggerUrl = `https://graph.facebook.com/v12.0/?id=${encodeURIComponent(urlToScrape)}&scrape=true`;
            
            fetch(fbDebuggerUrl)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        console.log('Facebook scrape successful');
                    } else {
                        console.error('Facebook scrape failed:', data);
                    }
                })
                .catch(error => {
                    console.error('Error calling Facebook Sharing Debugger:', error);
                });
        
    };
    
    const getSocialMediaShareInfo=()=>{
        // return "Recruitment is going on for Click the link to Find Jobs and Get Hired";
    }
    const getSocialMediaHashTag=(title, code)=>{
        // return "hzzshhdz";
    }
    const updateStatus=(id,status)=>{
        const params = {
            'id':id,
            'status':status
        }
        if(status == 'new'){
        axios.post(process.env.REACT_APP_BASEURL +'/review/update/status',params)
        .then(response => {
            console.log(response)
          if(response.data.status == "OK"){
            userDetails();
            Refresh();
          }
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
        });
        }
      };
      const togglecomment=(id)=>{
        setComment('');
        setShowChildReply(false);
        setchildcurrentIndex(null);
        if (currentIndex == id) {
            setcurrentIndex(null)
        } else {
            setcurrentIndex(id)
        }
    }
    const postComment = (id,revId,type) =>{
        if(comment.trim().length !==0 || childComment.trim().length !==0){
            let params = '';
            if(type=="child"){
                 params = {
                    "userId":props.userId,
                    "commentText":childComment,
                    "reviewId":revId,
                    "parentCommentId":id,
                    "mentionUserIds":mentionuserIds
                }
            }
            else{
                 params = {
                    "userId":props.userId,
                    "commentText":comment,
                    "reviewId":id,
                    "parentCommentId":null,
                    "mentionUserIds":mentionuserIds
                }  
            }
                axios.post(process.env.REACT_APP_BASEURL +'/comments/add',params)
                .then(response => {
                    console.log("response",response)
                    if(response.data.status == "OK"){
                    toast.success(response.data.message, {
                    theme: 'colored',
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,       
                    });
                    setComment('');
                    setchildComment('');
                    setShowReply(false);
                    setShowChildReply(false);
                    userDetails();
                    togglecomment(response.data.id);
                    loadRevComments(params.reviewId);
                    setmentionuserIds([]);
                }
                })
                .catch(error => {
                    console.log(error);
                    toast.error({
                        title: 'Error Occured',
                        position: 'topRight'
                    });
                });
            } else{
                setCmtError('Comment text is required')
                setCmtErrId(id);
            }
      }
      const  loadRevComments = (revId) => {
        axios.post(process.env.REACT_APP_BASEURL +'/review/view/comments',{'id':revId})
        .then(response => {
            console.log("res",response.data)
            setRevCmnt(response.data.recordInfo.reviewComments);
            setRevReplies(response.data.recordInfo.reviewReplies);
        })
        .catch(error => {
          console.log(error);
          toast.error({
              title: 'Error Occured',
              position: 'topRight'
          });
      });
    }

    const dataURLtoBlob = (dataURL) => {
      const arr = dataURL.split(",");
      const mimeMatch = arr[0].match(/:(.*?);/);
    
      if (!mimeMatch || mimeMatch.length < 2) {
        console.error("Invalid dataURL format");
        return null;
      }
    
      const mime = mimeMatch[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
    
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
    
      return new Blob([u8arr], { type: mime });
    };

    const downloadImage = (url, filename) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = filename;
  
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    
    const convertToImageAndUpload = async () => {
      const container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.left = '-9999px';
      container.style.top = '-9999px';
      document.body.appendChild(container);
      const viewShareComponent = <ViewShare shareData={shareData} userData={userData}/>;
      createRoot(container).render(viewShareComponent);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      html2canvas(container).then(async (capturedCanvas) => {

          const imageDataUrl = capturedCanvas.toDataURL("image/png", 0.8);
          // downloadImage(imageDataUrl, "image.png");
    
          const blob = dataURLtoBlob(imageDataUrl);
          const file = new File([blob], "image.png", { type: "image/png" });
    
          console.log("File object:", file);
    
          const newFormData = new FormData();
          newFormData.append("id", shareData.id);
          newFormData.append("file", file);
          newFormData.append("type", "share");
    
          setFormData(newFormData);
    
          try {
            if (!newFormData) {
              console.error("No file to upload");
              return;
            }
    
            const response = await axios.post(process.env.REACT_APP_BASEURL + '/review/share', newFormData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log("API Response:", response.data);
    
            const filepath = response.data.filePath+ '?timestamp=' + new Date().getTime();
            setUrlLink(filepath);
          } catch (error) {
            console.error("API Error:", error);
          } finally {
            document.body.removeChild(container);
          }
        });
      };
      const handleImageDownload = async () => {
        const imgSrc = "https://tiipstr.s3.amazonaws.com/documents/development/profile/USR_10/tomjerry.jpeg";
        try {
          const response = await fetch(imgSrc);
          const blob = await response.blob();
          const link = document.createElement('a');
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.download = 'downloaded_image.jpg';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Error downloading image:', error);
        }
      };

    return ( 
        <>
         <HeaderMenu/>
         <Helmet>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="http://18.223.205.99/"/>
            <meta property="og:description" content={shareData.reviewerName}/>
            <meta property="og:image" content={logoImg}/>
        </Helmet>
        <div className="">
        <SideBar/>
        <div className="main-content dash_hgt ml_200">
        <BreadCrumb/>
        <Card className='shade'>
            <Card className="shadow">
                <Card.Body style={{padding: '13px 0px'}} className="me_card">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <Row className='justify-content-center'>
                                <Row className='justify-content-center'>
                                <Col sm={12} md={12} lg={12} xl={12} className="text-center">
                                <div className='d-flex justify-content-center'>
                                            <div className='disply_foll'>
                                                <div className='circle-background  text-center  '>{userData.followersCount}</div>
                                                <div>Followers</div>
                                            </div>
                                            <div className=' d-flex align-items-center' style={{margin:' 0px 18px'}}>

                                        <img className='meImg_profile' src={userData.imagePath ? userData.imagePath : profile}/>
                                        </div>
                                        <div className='disply_foll'>
                                                <div className='circle-background  text-center  '>{userData.followingCount}</div>
                                                <div>Following</div>
                                            </div>
                                        </div>
                                        <Card.Text className='mb-0 fz-22 alert-link'>{userData.name}</Card.Text>
                                        <div className=' d-flex justify-content-center align-items-center' style={{height: '20px'}}>
                                                <div className=''>
                                                   <StarRating rating={userData.averageReviewCount} disabled={true} />
                                                </div>
                                                <span>({userData.totalReviewCount})</span>
                                                <span onClick={handleButtonClick}  ref={targetRef} ><IoIosArrowDown/></span>
                                            </div>
                                            {/* <div className="row d-flex">
                                                <div className="col-6 col-md-6 col-lg-6 reviewPage"><div className="col-12 col-md-12 col-lg-12"><span className='circle-background  text-center  '>{userData.followersCount}</span></div><div className='col-12 col-md-12 col-lg-12'>Followers</div></div>
                                                <div className="col-6 col-md-6 col-lg-6 reviewPage"><div className="col-12 col-md-12 col-lg-12 "><span className='text-center circle-background'>{userData.followingCount}</span></div><div className='col-12 col-md-12 col-lg-12'>Following</div></div>
                                            </div> */}
                                        
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col sm={12} md={4} lg={4} xl={4}>
                                    </Col>
                                    <Col sm={12} md={3} lg={3} xl={3} className="max_fit">
                                        <Card.Text className="card_txt text-center mar-10">{(userData.city || userData.state)?<HiLocationMarker/>:''}&nbsp;{userData.city ? userData.city : ''} {(userData.city && userData.state)? ',': ''} {userData.state ? userData.state : ''}</Card.Text>
                                    </Col>
                                        <Col sm={12} md={4} lg={4} xl={4}><Card.Text className="card_txt">
                                        </Card.Text>
                                        {/* <Card.Text >Total Reviews: {userData.totalReviewCount}</Card.Text> */}
                                        <Overlay
                                        show={showPopover}
                                        target={targetRef.current}
                                        placement="bottom"
                                        container={document.body}
                                        onHide={handleOverlayClose}
                                        rootClose
                                        >
                                        <Popover id="popover" className='pop_rating'>
                                        <div className="drp_rating"><StarRating rating={userData.averageReviewCount} disabled={true} /><span>{userData.averageReviewCount} out of 5</span> </div>
                                        {globalRating.map((item, index) => ( <Row>
                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0"><span className="prog_span">{item.label}</span> </Col>
                                            <Col sm={12} md={8} lg={8} xl={7} className="p-0"><Horizontalbar dataSet={item.value}/></Col>
                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0 prog_span" style={{marginLeft: '10px'}}><span>{item.value}{item.value > 1 ? <FiUsers style={{marginLeft: '6px'}}/>: <FiUser style={{marginLeft: '6px'}}/>}</span></Col>
                                            </Row>))}
                                        </Popover>
                                        </Overlay>
                                    </Col>
                                </Row>
                        </Row>
                    </Col>
                </Card.Body>
            </Card>
            <div className='me_revList'>
                    {userreviewList.length>0 ? userreviewList.map((data,i) => (
                        <Card onClick={() => updateStatus(data.id,data.status)} className="meCard" key={data.id}>
                            <Card.Body className={`${data.status === 'new' ? "rev_padg blurry-background c_pointer" :  "rev_padg" } ${showAdditionalReviews && selectedReviewId === data.reviewedBy ? 'card_drp' : ''} ${data.status==='reported' || data.status == 'blocked' ?'bg_gray rev_padg':''}`}>
                            <div className="text-end dot_3">
                                    {data.status == 'reported' && (
                                        <span className="uder_Rev">
                                           Under Review
                                        </span>
                                    )} 
                                    {data.status == 'blocked' && (
                                        <span className="uder_Rev">
                                           Blocked
                                        </span>
                                    )}
                                </div>
                                <Row>
                                    <Col sm={12} md={2} lg={2} xl={2} className="text-center">
                                        <div className='border_btm h-72'>
                                            <img className='meImg_review' src={data.reviewerImagePath ? data.reviewerImagePath : profile}/>
                                        </div>
                                        {data.status !="blocked" && data.status !="reported" ? <Card.Text className='mb2 card_txt name_blue c_pointer' onClick={() => handleNameClick(data.reviewedBy)}>{data.reviewerName}</Card.Text>:
                                        <Card.Text className='mb2 card_txt'>{data.reviewerName}</Card.Text>
                                        }
                                        <div className=' d-flex justify-content-center align-items-center fs_12' style={{height: '20px'}}>
                                                <div className='d-flex align-items-center'>
                                                   <StarRating rating={data.averageReviewCount} disabled={true} customClass="special-star"/>
                                                   <span>({data.totalReviewCount})</span>
                                        <OverlayTrigger
                                            trigger="click"
                                            key={data.id}
                                            placement="bottom"
                                            overlay={
                                                <Popover id={`popover-${data.id}`} className='pop_rating'>
                                                <div className="drp_rating"><StarRating rating={avgCount} disabled={true} /><span>{avgCount} out of 5</span> </div>
                                                                        {revGlobalRtng.map((item, index) => ( <Row key={index}>
                                                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0"><span className="prog_span">{item.label}</span> </Col>
                                                                            <Col sm={12} md={8} lg={8} xl={7} className="p-0"><Horizontalbar dataSet={item.value}/></Col>
                                                                            <Col sm={12} md={3} lg={3} xl={2} className="p-0 prog_span" style={{marginLeft: '10px'}}><span>{item.value}{item.value > 1 ? <FiUsers style={{marginLeft: '6px'}}/>: <FiUser style={{marginLeft: '6px'}}/>}</span></Col>
                                                                            </Row>))}
                                                </Popover>
                                            }
                                            rootClose
                                            >
                                            {data.status !="blocked" ? <span className="d-flex align-items-center" onClick={() => handleArrowClick(data.starRating,data.averageReviewCount)} >
                                                <IoIosArrowDown className='clr_gray'/>
                                            </span>:
                                            <span className="d-flex align-items-center">
                                            </span>
                                            }
                                        </OverlayTrigger>
                                        {data.status == 'blocked' && <IoIosArrowDown className='clr_gray'/>}
                                                </div>
                                            </div>                                    
                                    </Col>
                                    <Col className="card_bdr_left m-0" sm={12} md={8} lg={5} xl={3}>
                                        <Card.Text className='mb-0 ' title={data.comment}>
                                                <div
                                                    className={` ${
                                                    expandedCommentIndex === i ? 'show-full' : ''
                                                    }`}
                                                    title={data.comment}
                                                >
                                                    {data.comment.length >= 40 && expandedCommentIndex !== i
                                                    ? textWithLinks(data.comment.substring(0, 40)).map((word, index) => (
                                                        <span key={index}>{word} </span>
                                                        )).concat('...')
                                                    : textWithLinks(data.comment).map((word, index) => (
                                                        <span key={index}>{word} </span>
                                                        ))
                                                    }
                                                </div>

                                                {data.comment.length >= 40 && (
                                                    <button
                                                    disabled={data.status=="blocked" || data.status=="reported"? true:false}
                                                    onClick={() => readComment(i)}
                                                    className={data.status=="blocked" || data.status=="reported" ? 'read-more-dis' : 'read-more-button'}
                                                    >
                                                    {expandedCommentIndex === i ? 'Read less' : 'Read more'}
                                                    </button>
                                                )}
                                        </Card.Text>
                                        {data.status == 'blocked' || data.status == 'reported' ? (
                                        data.reviewComments.length !== 0 && (
                                            <span className='rply_dis' title='view review comment'>
                                            {data.reviewComments.length === 1 ? `${data.reviewComments.length} Reply` : `${data.reviewComments.length} Replies`}
                                            </span>
                                        )
                                        ) : (
                                        data.reviewComments.length !== 0 && (
                                            <span className='rply c_pointer' title='view review comment' onClick={() => viewRevComments(data.reviewComments, data.reviewReplies, data.status)}>
                                            {data.reviewComments.length === 1 ? `${data.reviewComments.length} Reply` : `${data.reviewComments.length} Replies`}
                                            </span>
                                        )
                                        )}
                                   <span>
                               { data.status == 'reported' || data.status == 'blocked' || (data.reviewedBy === props.userId) ? <BiSolidCommentDetail className="cmt_icn clr_gray" title="Add comment"/>:
                              <BiSolidCommentDetail className="cmt_icn c_pointer" title="Add comment" onClick={()=>togglecomment(data.id)}/>}
                             {(currentIndex == data.id)?<Row>
                            <div className="m-2 position-relative">
                                {cmtError && cmtErrId==data.id? <div className="text-danger fz-12">{cmtError}</div>:''}
                                <MentionsInput style={mentionInputStyle} className="form-control me_cmt_box" maxLength={288}
                                 onChange={(e) => {changeCmtText(e.target.value,'parent',data.reviewedBy)}} value={comment} placeholder='type someting...' >
                                    <Mention style={{backgroundColor: "#efebe3"}}
                                        trigger="@"
                                        data={users}
                                    />
                                </MentionsInput>
                                {/* <textarea style={{marginLeft: '-10px'}} className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setComment(e.target.value)} value={comment}/> */}
                                <button className="btn btn-primary cmt_btnn" onClick={() => postComment(data.id,0,'parent')}>Post</button>
                            </div>
                           </Row>:''}
                            </span>
                                    </Col>
                                    <Col sm={12} md={4} lg={3} xl={3}>
                                        <div className="d-flex" style={{gap:'16px'}}>
                                        {data.status==='reported' || data.status == 'blocked'?<p className="dis_rep_icon" title="Report">Report</p>:
                                        <p className="rep_icon" title="Report" onClick={() => {sendReport(data.id)}}>Report</p>}
                                        <div className='div_line'></div>
                                        {data.status==='reported' || data.status == 'blocked'?<BsShareFill className="dis_share_icon" title="Share" />:
                                        <BsShareFill className="share_icon" title="Share"  onClick={() => handleShare(data)}/>}
                                        </div>
                                        <Card.Text className='mb-0'><span className="txt_rev">Role:</span> <span className="fts_15">{data.userRole}</span></Card.Text>
                                        <Card.Text className='mb-0'><span className="txt_rev">Date of Review:</span><span className="fts_15"> {moment(data.createdAt).format('DD-MMM-YYYY')}</span></Card.Text>
                                        <Card.Text className='mb2'><div className='d-flex'><StarRating rating={data.rating} disabled={true} customClass="special-star"/>
                                            { data.reviewQuestion.length != 0 && <OverlayTrigger
                                            trigger="click"
                                            key={data.id}
                                            placement="bottom"
                                            overlay={
                                                <Popover id={`popover-${data.id}`} className='pop_rating'>
                                                <div className="drp_rating"></div>
                                                {data.reviewQuestion.length === 0 ? (
                                                  <div>No questions found</div>
                                                ) : (
                                                  data.reviewQuestion.map((item, index) => (
                                                    <Row className='d-flex' key={index}>
                                                      <Col sm={1} md={1} lg={1} xl={1} className="cus-col p-0 prog_span d-flex align-items-center">{index + 1}.</Col>
                                                      <Col sm={5} md={4} lg={4} xl={4} className="cus-col-ques p-0 d-flex align-items-center"><span className="prog_span">{item.question}</span></Col>
                                                      <Col sm={6} md={7} lg={7} xl={7} className="cus-col-ratng p-0"><StarRating rating={item.rating} disabled={true} customClass="ques-star"/></Col>
                                                    </Row>
                                                  ))
                                                )}
                                              </Popover>
                                            }
                                            rootClose
                                            >
                                            <span style={{marginTop: '5px',marginLeft: '5px'}} className="d-flex align-items-center c_pointer" onClick={() => handleArrowClick(data.starRating,data.averageReviewCount)} >
                                                <BsQuestionDiamond className='clr_gray'/>
                                            </span>
                                        </OverlayTrigger>}
                                        </div></Card.Text>
                                    </Col>
                                    <Col sm={12} md={4} lg={4} xl={3}>
                                    <RatingBar disabled={data.status=='reported' || data.status == 'blocked'?true:false} likecount={data.likes} dislikecount={data.dislikes} liked={data.liked} handlelike={()=>handlelikechange(data.id,data.userId)} handledislike={()=>handledislikechange(data.id,data.userId)} handleRmvlike={()=>likecntDecrement(data.id,data.userId)} labelVAlue={data.score}/>
                                    </Col>
                                    <Col sm={12} md={2} lg={1} xl={1}  className='more_rev c_pointer'>
                                    {data.count > 1 && (
                                    <span onClick={() => {navigate('/dashboard/more_reviews', { state: { userIdMe: data, logId: props.userId } });}}>More... </span>
                                    )} 
                                    </Col>
                                    {/* <Col sm={12} md={1} lg={1} xl={1} className='more_rev c_pointer'>
                                    {data.count > 1 && (
                                    <button className="view_btn" onClick={() => {navigate('/dashboard/more_reviews', { state: { userIdMe: data } });}}>View </button>
                                    )} 
                                    </Col> */}
                                </Row>
                               
                                {showAdditionalReviews && selectedReviewId === data.reviewedBy && (
                                <Card>
                                    <div className='drpdwn_list'>
                                        {reviewList && reviewList.slice(0, -1).map((reviewData) => (
                                            <Card className="shadow meCard"  key={reviewData.id}>
                                                <Card.Body className={`${reviewData.status==='reported' || reviewData.status == 'blocked'?'bg_gray rev_drp':'rev_drp'}`}>
                                                    <Row>
                                                        <Col sm={12} md={2} lg={2} xl={1} className='text-center'>
                                                            <div className='border_btm'>
                                                                <img className='meImg_review' src={reviewData.reviewerImagePath ? reviewData.reviewerImagePath : profile}/>
                                                            </div>
                                                            <Card.Text className='mb2 card_txt'>{data.reviewerName}</Card.Text>
                                                        </Col>
                                                        <Col className="card_bdr_left" sm={12} md={8} lg={5} xl={4}>
                                                            <Card.Text className='mb-0 rv_cmtbox' title={reviewData.comment}>{reviewData.comment}</Card.Text>
                                                        </Col>
                                                        <Col sm={12} md={4} lg={4} xl={3}>
                                                            <div className="d-flex" style={{gap:'16px'}}>
                                                                <p className="rep_icon" title="Report" onClick={() =>sendReport(data.id)}>Report</p>
                                                                <div className='div_line'></div>
                                                                <BsShareFill className="share_icon" title="Share"  onClick={() => setIsShare(true)}/>
                                                            </div>
                                                            <Card.Text className='mb2'><StarRating rating={reviewData.rating} disabled={true} /></Card.Text> 
                                                        </Col>
                                                        <Col sm={12} md={4} lg={4} xl={3}>
                                                        <RatingBar  likecount={reviewData.likes} dislikecount={reviewData.dislikes} liked={reviewData.liked} handlelike={()=>handlelikechange(reviewData.id,reviewData.userId)} handledislike={()=>handledislikechange(reviewData.id,reviewData.userId)} handleRmvlike={()=>likecntDecrement(reviewData.id,reviewData.userId)} labelVAlue={reviewData.score}/>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                    </div>
                                </Card>
                                )}
                            </Card.Body>
                            {data.status === 'new' && <div className="new-text">
                                New
                            </div>}
                        </Card>)):(loading ? (
                    <div className="loader-center"><img className="loader-image" src={loader} alt="Loading..." /></div>
                ) :  (
                        <div  className="row col-md-12 d-align mx-0">
                            <div className="no-data w-100 font-weight-600 text-center fz_14">Sorry, there is no data to be displayed</div>
                        </div>))}
                </div>  
            </Card>
            </div>
            <Modal size="sm" show={isShare} onHide={CloseShare} >
                <Modal.Header style={{padding: "15px 0px!important"}}>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                     Share
                    </Modal.Title>
                    <span onClick={CloseShare} title="Close" style={{cursor: "pointer"}}>
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body className="text-center p-2">
                {!urlLink && <img className="loader-image" src={loader} alt="Loading..." />}
                    {urlLink && <FacebookShareButton  url={urlLink} className="m-2">
                        <FacebookIcon className="c_pointer" size={32}/>
                    </FacebookShareButton>}
                    {urlLink && <TwitterShareButton  url={urlLink} title="SEE WHAT THEY'RE SAYING . . ." className="m-2">
                        <FaSquareXTwitter size={36}/>
                    </TwitterShareButton>}
                    {urlLink && <LinkedinShareButton  url={urlLink} title="tiipstr" className="m-2">
                        <LinkedinIcon size={31}/>
                    </LinkedinShareButton>}
                    {false && 
                      <img height='40px' style={{paddingLeft: '4px'}} src={Insta} onClick={OpenInsta}/>
                   }
                </Modal.Body>
              </Modal>
              <Modal size="md" className="report_modal" show={showReport} onHide={closeReportModal} >
                <Modal.Header className='border_rst__btm' style={{padding: "15px 0px!important"}}>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                     <h6 className="font-weight-bold">Submit a report</h6>
                    </Modal.Title>
                    <span onClick={closeReportModal} title="Close" style={{cursor: "pointer"}}>
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body style={{fontSize: "14px"}}>
                    <textarea className="form-control Comment_sty" placeholder='Please add details about your report…' onChange={handleReport}></textarea>
                    {reportCommentErr && <div className="text-danger">{reportCommentErr}</div>}
                    <Col sm={12} md={12} lg={12} xl={12} className="text-center mt-2"><Button className="btn-warning font-weight-bold w-100" onClick={submitReport}>Submit</Button></Col>
                </Modal.Body>
              </Modal>
              <Modal size="md" className="report_modal" show={showReply} onHide={() => setShowReply(false)} >
                <Modal.Header className='border_rst__btm' style={{padding: "15px 0px!important"}}>
                    <Modal.Title id="example-custom-modal-styling-title" style={{fontSize: "15px", fontWeight: 600}}>
                     <h6 className="font-weight-bold">Review Comments</h6>
                    </Modal.Title>
                    <span onClick={()=>{setShowReply(false)}} title="Close" style={{cursor: "pointer"}}>
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body style={{fontSize: "14px"}}>
                {revCmnt && revCmnt.map((data) => ( <Row>
                   <Col className={`revCmtPop pb-0 ${showChildReply && replies.length!=0?'revcmt_bg':''}`}>
                   <div className='d-flex'>
                        <div>
                        <img className="brd_rad h_w_25" src={data.userImagePath ? data.userImagePath : profile}/>
                        </div>
                        <div className="d-flex" style={{marginLeft: '8px',alignItems: 'center'}}>{data.userName} 
                        <span className='dat_icn'><GoDotFill/></span> <div style={{fontSize: '11px',color: '#959595'}}> {moment.utc(data.createdAt).local().startOf('seconds').fromNow()}</div></div>
                        </div>
                        <div className='ctTxt'>{
                            textWithLinks(data.commentText).map((word, index) => (
                                <span key={index}>{word} </span>
                                ))
                        }</div>
                        <div class="send d-flex gap_20 mb-2 mt-2">
                        <RatingBar disabled={(reviewSts =='reported'|| reviewSts == 'blocked' || (data.userId === props.userId))?true:false}msgreply={true} liked={data.liked} likecount={data.likes} dislikecount={data.dislikes} handlelike={()=>handlerplylikechange(data.id,data.reviewId)} handledislike={()=>handlerplydislikechange(data.id,data.reviewId)} handleRmvlike={()=>rplylikecntDecrement(data.id,data.reviewId)} labelVAlue={data.score}/>
                            { reviewSts == 'reported'|| reviewSts == 'blocked' || (data.userId === props.userId)? <BiSolidCommentDetail className="cmt_icn clr_gray"/>:<BiSolidCommentDetail className="cmt_icon c_pointer" title="Add comment" onClick={()=>togglecomment(data.id)}/>}
                            <Row>
                            {replies.filter(obj => (obj.id==data.id) || (obj.parentCommentId==data.id)).length!=0 && 
                            <span className='rply c_pointer'  onClick={() => viewChildComments(replies,data.id,data.reviewId)}>
                                {replies.filter(obj =>(obj.id==data.id) || (obj.parentCommentId==data.id)).length === 1 ? `${replies.filter(obj => (obj.id==data.id) || (obj.parentCommentId==data.id)).length} Reply `: `${replies.filter(obj => (obj.id==data.id) || (obj.parentCommentId==data.id)).length} Replies `}
                                <span>{(childcurrentIndex == data.id)?<IoMdArrowDropup/>:<IoMdArrowDropdown/>}</span>
                                </span>}
                           </Row>
                        </div>
                        {(currentIndex == data.id)?<Row>
                            <div className="position-relative p-0">
                                {cmtError && cmtErrId==data.id?<div className="text-danger fz-12 ml-2">{cmtError}</div>:''}
                                <MentionsInput style={mentionInputStyle} className="form-control Cmt_box" maxLength={288}
                                 onChange={(e) => {changeCmtText(e.target.value,'child',data.userId)}} value={childComment} placeholder='type someting...' >
                                    <Mention style={{backgroundColor: "#efebe3"}}
                                        trigger="@"
                                        data={users}
                                    />
                                </MentionsInput>
                                {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                <button className="btn btn-primary cmt_btn" onClick={() => postComment(data.id,data.reviewId,'child')}>Post</button>
                            </div>
                        </Row>:''}
                        {(childcurrentIndex == data.id) && replies.length!=0? replies.map((item,index) => ( (item.id == data.id) || (item.parentCommentId == data.id)?<Row>
                   <Col className='revchdcmt pb-0'>
                   <div className='d-flex'>
                        <div>
                        <img className="brd_rad h_w_25" src={item.replyUserImagePath ? item.replyUserImagePath : profile}/>
                        </div>
                        <div className="d-flex" style={{marginLeft: '8px',alignItems: 'center'}}>{item.replyUserName} 
                        <span className='dat_icn'><GoDotFill/></span> <div style={{fontSize: '11px',color: '#959595'}}> {moment.utc(item.createdAt).local().startOf('seconds').fromNow()}</div></div>
                        </div>
                        <div className='ctTxt'><span className="rep_men">{"@"+item.userName+' '}</span>{
                            textWithLinks(item.replyCommentText).map((word, index) => (
                                <span key={index}>{word} </span>
                                ))
                        }</div>
                        <div class="send d-flex gap_20 mb-2 mt-2">
                            <RatingBar disabled={(reviewSts =='reported' || (item.replyUserId === props.userId))?true:false} msgreply={true} liked={item.liked} likecount={item.likes} dislikecount={item.dislikes} handlelike={()=>handlerplylikechange(item.replyCommentId,data.reviewId)} handledislike={()=>handlerplydislikechange(item.replyCommentId,data.reviewId)} handleRmvlike={()=>rplylikecntDecrement(item.replyCommentId,data.reviewId)} labelVAlue={item.score}/>
                            { reviewSts == 'reported'|| reviewSts == 'blocked' || (item.replyUserId === props.userId)? <BiSolidCommentDetail className="cmt_icn clr_gray"/>:<BiSolidCommentDetail className="cmt_icon c_pointer" title="Add comment" onClick={()=>togglechildcomment(item.replyCommentId)}/>}
                        </div>
                        {(childrepplycurrentIndex == item.replyCommentId)?<Row>
                            <div className="position-relative p-0">
                                {cmtError && cmtErrId==item.replyCommentId? <div className="text-danger ml-2 fz-12">{cmtError}</div>:''}
                                <MentionsInput style={mentionInputStyle} className="form-control Cmt_box" maxLength={288}
                                 onChange={(e) => {changeCmtText(e.target.value,'child',item.replyuserId)}} value={childComment} placeholder='type someting...' >
                                    <Mention style={{backgroundColor: "#efebe3"}}
                                        trigger="@"
                                        data={users}
                                    />
                                </MentionsInput>
                                {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                <button className="btn btn-primary cmt_btn" onClick={() => postComment(item.replyCommentId,data.reviewId,'child')}>Post</button>
                            </div>
                        </Row>:''}
                    </Col>
                    </Row>:'')):''}
                    </Col>
                    </Row>
                    ))}
                </Modal.Body>
              </Modal>
            </div>
            <ToastContainer/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      userId: state.user.userId,
      authToken: state.user.token
    };
  };
export default connect(mapStateToProps)(MeTab);