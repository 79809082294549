import './inbox.css';
import '../../Register/register.css'
import React, { useState,useRef} from 'react';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Searchbar from './searchbar';
import Chats from './chats';
import Chat from './chat'
import { Collapse,Form,InputInputGroup,NavbarBrand,Navbar,NavItem,NavLink,Container,Row,Col,} from "reactstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
export function Sidebar() {
    const childCompRef = useRef()
    const [collapseOpen, setCollapseOpen] = useState();
    const [id, setId] = useState('');
    const [userName, setUserName] = useState('');
    const [userImg, setUserImg] = useState('');
    const [city, setUserCity] = useState('');
    const [state, setUserState] = useState('');
    const [notShow, setNotShow] = useState(false);
    const toggleCollapse = () => {
      setCollapseOpen((data) => !data);
    };
    const closeCollapse = () => {
      setCollapseOpen(false);
    };
  const handleEventKey = (id,name,img,city,state) => {
    if(id){
      setId(id)
      setUserName(name)
      setUserImg(img)
      setUserCity(city);
      setUserState(state);
      childCompRef.current.reloadMsg();
    }
    else{
      setNotShow(true);
    }
  };
    return (
      <>
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white ml_200"
        expand="md"
        id="chatnav-main"
        >
      <Container className="flx_column" fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
          <Collapse navbar isOpen={collapseOpen} className="cht_mr">
          <div className="navbar-collapse-header d-md-none">
            <Row>
                <Col className="collapse-brand" xs="6">
                    <Link to='/dashboard'>
                      <h2 style={{color: "#11cdef"}}>tiipstr</h2>
                    </Link>
                </Col>
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Form className="mt-4 mb-3 d-md-none">
          <Searchbar/>
          </Form>
          <Chats handleEventKey={handleEventKey}/>
        </Collapse>
      </Container>
    </Navbar>
    <div className="chat_ml">
    <Chat id={id} name={userName} img={userImg} city={city} state={state} ref={childCompRef} notShow={notShow}/>
      </div>
      <ToastContainer/>
      </>
        )
    }
    
    export default Sidebar;