import { Suspense } from "react"
import loader from './assets/images/loader.gif';
import { useSelector } from 'react-redux';
import Home from './components/Home/index';
import Forgotpassword from './components/ResetPassword/index';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Login from './components/Login/index';
import Dashboard from './components/Dashboard/DashboardTabs/MeTab';
import FollowersTab from './components/Dashboard/DashboardTabs/FollowersTab';
import Register from './components/Register/index';
import Profile from './components/Profile/index';
import Findnew from './components/Findnew/index';
import ProfileUpdate from './components/ProfileUpdate/index';
import Inbox from './components/Dashboard/DashboardTabs/InboxTab';
import DetailReview from './components/Dashboard/DashboardTabs/DetailReview';
import ViewUser from './components/Dashboard/DashboardTabs/ViewUser';
import Followers from './components/Dashboard/DashboardTabs/FollowersTab';
import Following from './components/Dashboard/DashboardTabs/FollowingTab';
import Notification from './components/Dashboard/DashboardTabs/NotificationTab';
import ViewNotification from './components/Dashboard/DashboardTabs/ViewNotification';
import Reviews from './components/Dashboard/DashboardTabs/ReviewsTab';
import Help from './components/Dashboard/DashboardTabs/HelpTab';
import PostReviews from './components/Dashboard/DashboardTabs/Review';
import FollowUser from './components/Dashboard/DashboardTabs/FollowUser';
import MoreReviews from './components/Dashboard/DashboardTabs/MoreReviews';
import AboutUs from './components/Home/aboutUs';
import TermsOfService from './components/Home/termsOfService';
import PrivacyPolicy from './components/Home/privacyPolicy';
import ShareView from "./components/Home/shareView";
// Admin Imports
import AdminDashboard from './components/Admin/Dashboard';
import UserRoles from "./components/Admin/UserRoles";
import ReviewQuestions from './components/Admin/ReviewQuestions';
import UserTypes from './components/Admin/UserTypes';
import AdminProfile from './components/Admin/ProfileUpdate/index';
import Settings from './components/Admin/Settings';
import ProfessionalTypes from './components/Admin/Settings/ProfessionalTypes';

// Support Imports
import SupportDashboard from './components/Support/Dashboard';
import Report from './components/Support/Report';
import SupportProfile from './components/Support/ProfileUpdate/index';

const Loader = (Component) => (props) => {
    return (
        <Suspense fallback={<div className="loader-container"><img className="loader-image" src={loader} alt="Loading..." /></div>}>
            <Component {...props} />
        </Suspense>
    )
};

const UserTypeSpecificDashboard = () => {
    const userType = useSelector((state) => state.user.userType);
    if (userType === 'Personal') {
        return <Dashboard />;
    } else if (userType === 'Admin') {
        return <UserRoles />;
    } else if (userType === 'Support') {
        return <Report />;
    }
};

const adminRoutes = [
    {
        path: "dashboard/user_roles",
        element: <UserRoles />,
        name: "User Roles",
    },
    {
        path: "dashboard/user_roles/review_questions",
        element: <ReviewQuestions />,
        name: "Review Questions",
    },
    {
        path: "dashboard/usertypes",
        element: <UserTypes />,
        name: "User Types",
    },
    {
        path: "dashboard/admin_profile",
        element: <AdminProfile />,
        name: "User Profile",
    },
    {
        path: "dashboard/settings",
        element: <Settings />,
        name: "Settings",
    },
        {
        path: "dashboard/settings/professional_types",
        element: <ProfessionalTypes />,
        name: "Settings",
    },
];

const supportRoutes = [
    {
        path: "dashboard/support",
        element: <SupportDashboard />,
        name: "Admin Dashboard",
        icon: "ni ni-tv-2 text-primary",
    },
    {
        path: "dashboard/report",
        element: <Report />,
        name: "Report",
    },
    {
        path: "dashboard/support_profile",
        element: <SupportProfile />,
        name: "User Types",
    },
];

export const routes = [
    ...adminRoutes,
    ...supportRoutes,
    {
        path: "*",
        element: <Home />
    },
    {
        path: "login",
        element: <Login />
    },
    {
        path: "dashboard",
        element: <UserTypeSpecificDashboard />,
        name: "Dashboard",
        icon: "ni ni-tv-2 text-primary",
    },
    {
        path: "forgotpassword",
        element: <Forgotpassword />,
        name: "Forgotpassword",
        icon: "ni ni-tv-2 text-primary",
    },
    {
        path: "resetpassword/:token",
        element: <ResetPassword />,
        name: "ResetPassword",
        icon: "ni ni-tv-2 text-primary",
    },
      
    {
        path: "myfollowers",
        element: <FollowersTab />,
        name: "FollowersTab",
        icon: "ni ni-tv-2 text-primary",
    },
    {
        path: "registration",
        element: <Register />,
        name: "Register",
        icon: "ni ni-tv-2 text-primary",
    },
    {
        path: "profile",
        element: <Profile />
    },
    {
        path: "dashboard/finduser",
        name: "Finduser",
        element: <Findnew />
    },
    {
        path: "dashboard/profile",
        name: "Profile",
        element: <ProfileUpdate />
    },
    {
        path: "inbox",
        name: "Inbox",
        element: <Inbox />
    },
    {
        path: "dashboard/reviews/view",
        name: "ViewReview",
        element: <FollowUser />
    },
    {
        path: "dashboard/reviews/more/view",
        name: "ViewReview",
        element: <DetailReview />
    },
    {
        path: "dashboard/search/user",
        name: "viewUser",
        element: <ViewUser />
    },
    {
        path: "dashboard/viewuser",
        name: "viewUser",
        element: <ViewUser />
    },
    {
        path: "dashboard/notification",
        name: "notification",
        element: <Notification />
    },
    {
        path: "dashboard/notification/view",
        name: "viewnotification",
        element: <ViewNotification />
    },
     {
        path: "dashboard/finduser/view",
        name: "viewUser",
        element: <ViewUser />
    },
    {
        path: "dashboard/Followers",
        name: "Followers",
        element: <Followers />
    },
    {
        path: "dashboard/Following",
        name: "Following",
        element: <Following />
    },
    {
        path: "dashboard/reviews",
        name: "Reviews",
        element: <Reviews />
    },
    {
        path: "dashboard/help",
        name: "Help",
        element: <Help />
    },
    {
        path: "dashboard/followers/postreview",
        name: "PostReview",
        element: <PostReviews />
    },
    {
        path: "dashboard/following/PostReview",
        name: "PostReview",
        element: <PostReviews />
    },
    {
        path: "dashboard/followers/view",
        name: "ViewFollowers",
        element: <FollowUser />
    },
    {
        path: "dashboard/following/view",
        name: "ViewFollowing",
        element: <FollowUser />
    },
    {
        path: "dashboard/reviews/more",
        name: "MoreReviews",
        element: <FollowUser />
    },
    {
        path: "dashboard/more_reviews",
        name: "MoreReviews",
        element: <MoreReviews />
    },
    {
        path: "dashboard/viewuser/more_reviews",
        name: "MoreReviews",
        element: <MoreReviews />
    },
    {
        path: "dashboard/viewuser/postreview",
        name: "MoreReviews",
        element: <PostReviews />
    },
    {
        path: "/aboutus",
        name: "aboutus",
        element: <AboutUs />
    },
    {
        path: "/terms-of-service",
        name: "terms",
        element: <TermsOfService />
    },
    {
        path: "/privacy-policy",
        name: "policy",
        element: <PrivacyPolicy />
    },
    {
        path: "/shareview",
        name: "shareview",
        element: <ShareView />
    },
];

export default routes;
