
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import SideBar from './SupportSideBar';
import HeaderMenu from './HeaderMenu';
import { Card,CardHeader, CardBody, CardTitle, Container, Row, Col, Button, Progress, Table } from "reactstrap";
import { FaUsersSlash,FaUsersLine  } from 'react-icons/fa6';
import { TbChartInfographic  } from 'react-icons/tb';
import { AiOutlinePercentage  } from 'react-icons/ai';
import BreadCrumb from '../plugins/Breadcrumb';
import social from '../../assets/images/ftl-icon.jpg'; 
import { AiOutlineFileText } from 'react-icons/ai';

const Dashboard = () => { 
    
    return ( 
        <>
          <HeaderMenu/>
        <div className="">
        <SideBar/>
        <div className="main-content ml_200">
        <Container fluid>
          <div className="header-body pt-3">
            {/* Card stats */}
            {/* <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="fs_14px text-uppercase text-muted mb-0"
                        >
                          Review Report
                        </CardTitle>
                        <span className="fs_14px h2 font-weight-bold mb-0">
                          350,897
                        </span>
                      </div>
                      <Col className="col-auto pad_lf">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <TbChartInfographic/>
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="fs_14px text-uppercase text-muted mb-0"
                        >
                          users
                        </CardTitle>
                        <span className="fs_14px h2 font-weight-bold mb-0">2,356</span>
                      </div>
                      <Col className="col-auto pad_lf">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <FaUsersLine />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "}                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="fs_14px text-uppercase text-muted mb-0"
                        >
                          deactivated users
                        </CardTitle>
                        <span className="fs_14px h2 font-weight-bold mb-0">924</span>
                      </div>
                      <Col className="col-auto pad_lf">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <FaUsersSlash/>
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 1.10%
                      </span>{" "}                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="fs_14px text-uppercase text-muted mb-0"
                        >
                          User Report
                        </CardTitle>
                        <span className="fs_14px h2 font-weight-bold mb-0">49,65%</span>
                      </div>
                      <Col className="col-auto pad_lf">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <AiOutlineFileText/>
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 12%
                      </span>{" "}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
          </div>
        </Container>
        <Container className="mt_18">
            <Row>
            <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0 social_pad">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 fs_20">TO DO</h3>
                  </div>
                  <div className="col text-right">
                    {/* <img className='social' src={social} alt="logo" /> */}

                    {/* <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button> */}
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">TO DO</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2"></span>
                        <div>
                          {/* <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          /> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2"></span>
                        <div>
                          {/* <Progress
                            max="100"
                            value="70"
                            barClassName="bg-gradient-success"
                          /> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">twitter</th>
                    <td>4,807</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">80%</span>
                        <div>
                          <Progress max="100" value="80" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Linkedin</th>
                    <td>3,678</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">75%</span>
                        <div>
                          <Progress
                            max="100"
                            value="75"
                            barClassName="bg-gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">twitter</th>
                    <td>2,645</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">30%</span>
                        <div>
                          <Progress
                            max="100"
                            value="30"
                            barClassName="bg-gradient-warning"
                          />
                        </div>
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Card>
          </Col>
            </Row>
        </Container>
        </div>
        </div>
        <ToastContainer/>
        </>
    );
}

export default Dashboard;