import {Card,Col,Row,Container} from "react-bootstrap";
import "../../components/Register/register.css";
import { ToastContainer, toast } from 'react-toastify';
import { useState} from "react";
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import LoaderOverlay from '../plugins/LoaderOverlay';

const ResetMessage = () => {
    const [isLoading, setIsLoading] = useState(false);
  
  return (
    <>
    <LoaderOverlay isLoading={isLoading} />
      <div className="body-content">
        <Header/>
        <div className="d-flex justify-content-center align-items-center pro-bg-content">
        <Col lg="4" md="8">
          <Card className="bg-secondary shadow border-0">
          <div className="text-muted text-center mt-4 mb-4">
            </div>
            <Card.Body className="px-lg-4 py-lg-5 pt-0">
                <div className="text-center">
                    <div>Reset request sent to your registered email address</div>
                    <div className="mt_13">Thanks!</div>  
                </div>
            </Card.Body>
          </Card>
        </Col>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default ResetMessage;
